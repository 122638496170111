import React, { useState } from "react";
import { Button, Input, Box, Flex } from "@chakra-ui/react";

interface FileUploadProps {
  onFileRead?: (file: File | null) => void;
  onFileContent?: (fileContent: string | ArrayBuffer | null) => void;
  buttonLabel?: string;
  isProcessed?: boolean;
}

const FileUpload = ({
  onFileRead,
  onFileContent,
  buttonLabel = "Import File",
  isProcessed = false,
}: FileUploadProps): React.ReactElement => {
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      if (isProcessed) {
        onFileRead?.(null);
      }
    }
  };

  const resetFileInput = () => {
    setFile(null);
    const fileInput = document.querySelector(
      'input[type="file"]',
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUpload = async () => {
    if (file && !isProcessed) {
      setIsLoading(true);
      try {
        onFileRead?.(file);
        if (onFileContent) {
          const reader = new FileReader();
          reader.onload = (event) => {
            onFileContent(event.target?.result ?? null);
          };
          reader.onerror = () => {
            resetFileInput();
          };
          reader.readAsText(file);
        }
      } catch (error) {
        resetFileInput();
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Flex align="center" gap={2}>
      <Input
        type="file"
        onChange={handleFileChange}
        sx={{
          "::file-selector-button": {
            height: "100%",
            padding: "0 20px",
            background: "gray.100",
            border: "none",
            borderRight: "1px solid",
            borderColor: "gray.200",
            color: "gray.700",
            cursor: "pointer",
            _hover: {
              background: "gray.200",
            },
            _active: {
              background: "gray.300",
            },
          },
        }}
        p={0}
        height="40px"
        isDisabled={isLoading}
      />
      {file && (
        <Button
          colorScheme="teal"
          onClick={handleUpload}
          isLoading={isLoading}
          loadingText="Importing..."
          isDisabled={isProcessed}
        >
          {isProcessed ? "File Processed" : buttonLabel}
        </Button>
      )}
    </Flex>
  );
};

export default FileUpload;
