import { Box, Button, HStack, Td, useDisclosure } from "@chakra-ui/react";
import React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { StockInfo } from "../../types";
import AddStockTakeModal from "./AddStockTakeModal.tsx";
import { formatProductQuantity } from "../../utils/productUnitUtils.ts";

interface StockLevelViewEntryProps {
  index: number;
  stockInfo: StockInfo;
  isProducer?: boolean;
}

export default function StockLevelViewEntry({
  index,
  stockInfo,
  isProducer,
}: StockLevelViewEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && (
        <AddStockTakeModal
          isOpen={isOpen}
          onClose={onClose}
          productId={stockInfo.product_id}
        />
      )}
      <InteractiveRow itemIndex={index} onClick={(e) => e.stopPropagation()}>
        <Td fontSize="sm">{stockInfo.product}</Td>
        <Td fontSize="sm">{stockInfo.unit}</Td>
        <Td fontSize="sm">
          <HStack>
            <Box>{stockInfo.last_count_date}</Box>
            <Button size="xs" onClick={onOpen}>
              +
            </Button>
          </HStack>
        </Td>
        {/*<Td fontSize="sm">*/}
        {/*  <Tooltip label={getPrevBalanceTooltip(stockInfo.opening)}>*/}
        {/*    {formatProductQuantity(stockInfo.opening.counted).toString()}*/}
        {/*  </Tooltip>*/}
        {/*</Td>*/}
        <Td maxW={"100px"} fontSize="sm">
          {formatProductQuantity(stockInfo.sold)}
        </Td>
        <Td fontSize="sm">{formatProductQuantity(stockInfo.purchased)}</Td>
        <Td fontSize="sm">{formatProductQuantity(stockInfo.wasted)}</Td>
        {isProducer && (
          <Td maxW={"100px"} fontSize="sm">
            {formatProductQuantity(stockInfo.harvested)}
          </Td>
        )}
        <Td fontSize="sm">{formatProductQuantity(stockInfo.current)}</Td>
      </InteractiveRow>
    </>
  );
}
