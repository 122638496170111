import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import CustomerSearch from "../../components/CustomerSearch/CustomerSearch.tsx";
import DataTabs from "../../components/DataTabs/DataTabs";
import {
  useGetBuyerLocationsQuery,
  useGetOrganisationsQuery,
  useGetSalesOrderByIdQuery,
  useGetSalesOrdersQuery,
  useGetSupplierInvoiceByIdQuery,
  useGetSupplierInvoicesQuery,
} from "../../redux/apiSlice";
import { filterDatesSlice } from "../../redux/filterDatesSlice";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import { Invoice, Location, Order } from "../../types";
import debounce from "../../utils/debounce";
import { getLocationAndOrgName } from "../../utils/invoiceUtils.ts";
import InvoiceViewEntry from "./InvoiceViewEntry";
import OrderModal from "./OrderModal";
import OrderViewEntry from "./OrderViewEntry";
import OrderStatusFilter from "../../components/OrderStatusFilter/OrderStatusFilter.tsx";

const SalesOrdersView = ({
  checkedOrders,
  handleCheckOrder,
  allOrdersChecked,
  handleCheckAllOrders,
  tabIndex,
  filterOrders,
  setFilterOrders,
}: {
  checkedOrders: Map<number, boolean>;
  handleCheckOrder: (orderId: number, value: boolean) => void;
  allOrdersChecked: boolean;
  handleCheckAllOrders: (value: boolean) => void;
  tabIndex: number;
  setTabIndex: (index: number) => void;
  filterOrders: boolean;
  setFilterOrders: (value: boolean) => void;
}): React.ReactElement => {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  const { getCurrentLocationId, getCurrentOrganisationId, getCurrentLocation } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const currentLocation = useAppSelector(getCurrentLocation);

  const [searchById, setSearchById] = useState<number | null>(null);
  const [idSearchValue, setIdSearchValue] = useState<string>("");
  const searchTimeout = useRef<NodeJS.Timeout | null>(null);
  const {
    data: orders,
    error: ordersError,
    isLoading: ordersLoading,
  } = useGetSalesOrdersQuery(
    {
      organisationId,
      locationId,
      startDate,
      endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const { data: orderByID, isLoading: ordersLoadingByID } =
    useGetSalesOrderByIdQuery(
      {
        orderId: searchById ?? 0,
        organisationId,
        locationId,
      },
      { skip: tabIndex === 1 || !organisationId || !locationId || !searchById },
    );

  const { data: invoiceByID, isLoading: invoiceLoadingByID } =
    useGetSupplierInvoiceByIdQuery(
      {
        invoiceId: searchById ?? 0,
        organisationId,
        locationId,
      },
      { skip: tabIndex === 0 || !searchById || !organisationId || !locationId },
    );

  const {
    data: organisations,
    error: organisationsError,
    isLoading: organisationsLoading,
  } = useGetOrganisationsQuery("");

  const {
    data: invoices,
    error: invoicesError,
    isLoading: invoicesLoading,
  } = useGetSupplierInvoicesQuery(
    {
      organisationId,
      locationId,
      startDate,
      endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const {
    data: buyerLocations = [],
    error: buyerLocationsError,
    isLoading: isLoadingBuyerLocations,
  } = useGetBuyerLocationsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [selectedCustomerOrgId, setSelectedCustomerOrgId] = useState<
    number | null | undefined
  >(null);

  const [ordersList, setOrdersList] = useState<Order[]>([]);
  const [invoicesList, setInvoicesList] = useState<Invoice[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [locationIdToLocation, setLocationIdToLocation] = useState<
    Map<number, Location>
  >(new Map());

  const [isAllSelected, setIsAllSelected] = useState(false);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
    "PENDING",
    "PROCESSING",
  ]);

  const filterOrdersByStatus = useCallback(
    (orders: Order[]) => {
      if (selectedStatuses.length === 0) {
        return orders;
      }
      return orders.filter((order) =>
        selectedStatuses.includes(order?.status || ""),
      );
    },
    [selectedStatuses],
  );

  useEffect(() => {
    if (buyerLocations && !isLoadingBuyerLocations && !buyerLocationsError) {
      setLocationIdToLocation(
        new Map(buyerLocations.map((loc) => [loc.id, loc])),
      );
    } else {
      setLocationIdToLocation(new Map());
    }
  }, [buyerLocations]);

  const debouncedSetFilterOrders = debounce(
    () => setFilterOrders(!filterOrders),
    300,
  );

  useEffect(() => {
    if (searchById) {
      if (tabIndex === 0) {
        return setOrdersList(
          orderByID && Number(orderByID.id) === Number(searchById)
            ? [orderByID]
            : [],
        );
      } else {
        return setInvoicesList(
          invoiceByID && Number(invoiceByID.id) === Number(searchById)
            ? [invoiceByID]
            : [],
        );
      }
    }

    if (orders) {
      let ordersList = orders.slice();
      if (selectedCustomerOrgId || filterOrders) {
        ordersList = ordersList.filter((order) => {
          if (selectedCustomerOrgId) {
            return order.organisation_id === selectedCustomerOrgId;
          }
          return true;
        });
      }
      ordersList = filterOrdersByStatus(ordersList);
      setOrdersList(ordersList);
    } else {
      setOrdersList([]);
    }
    if (invoices) {
      let invoicesList = invoices.slice();
      if (selectedCustomerOrgId) {
        invoicesList = invoicesList.filter(
          (invoice) => invoice.organisation_id === selectedCustomerOrgId,
        );
      }
      setInvoicesList(invoicesList);
    } else {
      setInvoicesList([]);
    }
  }, [
    orders,
    invoices,
    filterOrders,
    selectedCustomerOrgId,
    searchById,
    orderByID,
    invoiceByID,
    filterOrdersByStatus,
  ]);

  return (
    <Box
      p={[2, 2, 2]}
      my={[4, 6, 10]}
      mx={[2, 4, 10]}
      bg={"gray.200"}
      border="1px solid"
      borderColor="gray.400"
      rounded="md"
      boxShadow="lg"
      overflow="auto"
      minH="300px"
    >
      {isOpen && <OrderModal isOpen={isOpen} onClose={onClose} isSalesView />}
      <DataTabs
        tabIndex={tabIndex}
        filterApplied={filterOrders}
        setFilterApplied={debouncedSetFilterOrders}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        hideTabs
        searchElements={
          <Stack
            spacing={2}
            direction={["column", "column", "row"]}
            width="100%"
            minW="100px"
          >
            <CustomerSearch
              includeSearchIcon
              placeholder={"Filter by customer"}
              setCustomerOrganisationId={setSelectedCustomerOrgId}
            />
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
              >
                <SearchIcon />
              </InputLeftElement>
              <Input
                placeholder={`Search for an ${
                  tabIndex === 0 ? "order" : "invoice"
                } ID`}
                type="number"
                value={idSearchValue}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setIdSearchValue(e.target.value);
                  setSearchById(isNaN(value) ? null : value);
                  if (isNaN(value)) {
                    clearTimeout(searchTimeout.current as NodeJS.Timeout);
                    setSearchById(null);
                  } else {
                    clearTimeout(searchTimeout.current as NodeJS.Timeout);
                    searchTimeout.current = setTimeout(
                      () => setSearchById(value),
                      300,
                    );
                  }
                }}
              />
            </InputGroup>
          </Stack>
        }
        data={[
          {
            label: "Orders",
            buttonLabel: "Add Order",
            customFilter: (
              <OrderStatusFilter
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
              />
            ),
            headers: [
              <Checkbox
                isChecked={allOrdersChecked}
                colorScheme="teal"
                onChange={(e) => {
                  e.stopPropagation();
                  if (handleCheckAllOrders)
                    handleCheckAllOrders(!isAllSelected);
                  setIsAllSelected(!isAllSelected);
                }}
              />,
              "Order ID",
              "Customer",
              "Ordered on",
              "Requested for",
              "Status",
              "Value",
            ],
            isLoading:
              ordersLoadingByID ||
              ordersLoading ||
              organisationsLoading ||
              invoicesLoading,
            entries:
              tabIndex === 0
                ? ordersList &&
                  organisations &&
                  !ordersLoading &&
                  !organisationsLoading
                  ? ordersList
                      .slice()
                      .sort((a, b) => b.id - a.id)
                      .map((order, index) => (
                        <OrderViewEntry
                          key={order.id}
                          itemIndex={index}
                          order={order}
                          organisations={organisations}
                          supplierOrCustomerName={getLocationAndOrgName(
                            order.location,
                          )}
                          isSupplierView={true}
                          showSelect={true}
                          isChecked={checkedOrders.get(order.id) || false}
                          setChecked={(value: boolean) =>
                            handleCheckOrder(order.id, value)
                          }
                        />
                      ))
                  : []
                : [],
          },
          {
            label: "Invoices",
            headers: [
              "Invoice ID",
              "Order ID",
              "Customer",
              "Invoice Date",
              "Delivery Date",
              "Status",
              "Total",
              "Credit",
              "Balance",
              "Paid",
              "Due",
            ],
            hideButton: true,
            isLoading:
              invoicesLoading || organisationsLoading || invoiceLoadingByID,
            entries:
              tabIndex === 1
                ? invoicesList && !invoicesLoading
                  ? invoicesList
                      .sort((a, b) => b.id - a.id)
                      .map((invoice, index) => (
                        <InvoiceViewEntry
                          key={index}
                          itemIndex={index}
                          invoice={invoice}
                          supplierOrCustomerName={getLocationAndOrgName(
                            invoice.location,
                          )}
                          isSupplierView={true}
                          buyer={locationIdToLocation.get(invoice.location_id)}
                          supplierAddress={currentLocation.address}
                        />
                      ))
                  : []
                : [],
          },
        ]}
      />
      {ordersError && <div>Failed to load orders</div>}
      {organisationsError && <div>Failed to load organisations</div>}
      {invoicesError && <div>Failed to load invoices</div>}
      {(ordersLoading || organisationsLoading || invoicesLoading) && (
        <div>Loading...</div>
      )}
    </Box>
  );
};

export default SalesOrdersView;
