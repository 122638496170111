import * as React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Skeleton,
  Spacer,
  Stack,
  Switch,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { useState } from "react";

type TabData = {
  label: string;
  headers: (string | React.ReactNode)[];
  entries: React.ReactNode[];
  showFilter?: boolean;
  filterLabel?: string;
  buttonLabel?: string;
  hideButton?: boolean;
  isLoading?: boolean;
  customFilter?: React.ReactNode;
};

interface TabProps {
  data: TabData[];
  filterApplied?: boolean;
  setFilterApplied?: (value: boolean) => void;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onChange?: (index: number) => void;
  showButton?: boolean;
  searchElements?: React.ReactNode;
  tabIndex?: number;
  hideTabs?: boolean;
}

function DataTabs({
  data,
  filterApplied,
  setFilterApplied,
  onOpen,
  onChange,
  showButton = true,
  searchElements,
  tabIndex,
  hideTabs = false,
}: TabProps) {
  const [localTabIndex, setLocalTabIndex] = useState<number>(tabIndex ?? 0);
  const [isMobile] = useMediaQuery("(max-width: 860px)");

  const getButtonTitle = (label: string) => {
    let displayLabel = label.slice(0, -1);
    // if last two letters are ie, replace with y
    if (displayLabel.slice(-2) === "ie") {
      displayLabel = displayLabel.slice(0, -2) + "y";
    }
    return displayLabel;
  };

  const getLoadingTableRow = (headers: (string | React.ReactNode)[]) => {
    return (
      <Tr>
        {headers.map((_, index) => (
          <Td key={index}>
            <Skeleton height={"30px"} minW={"60px"} />
          </Td>
        ))}
      </Tr>
    );
  };

  const getLoadingState = (headers: (string | React.ReactNode)[]) => {
    // return 10 rows of loading state
    return Array.from({ length: 10 }, () => getLoadingTableRow(headers));
  };

  return (
    <>
      <Tabs
        colorScheme="black"
        index={tabIndex}
        onChange={(index) => {
          setLocalTabIndex(index);
          if (onChange) {
            onChange(index);
          }
        }}
      >
        <TabList>
          {hideTabs ? (
            <></>
          ) : (
            data.map(({ label }) => (
              <Tab key={label} minW="fit-content">
                {label}
              </Tab>
            ))
          )}
          <Stack
            direction={["column", "column", "row"]}
            spacing={isMobile ? 2 : 0}
            w={"100%"}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            {searchElements ? searchElements : <Spacer />}
            <Spacer />
            {data[tabIndex ?? localTabIndex]?.showFilter &&
              setFilterApplied && (
                <FormControl
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  mb={2}
                >
                  <FormLabel htmlFor="email-alerts" mb="0">
                    {data[tabIndex ?? localTabIndex].filterLabel ||
                      `Filter ${data[tabIndex ?? localTabIndex].label.slice(
                        0,
                        -1,
                      )}`}
                  </FormLabel>
                  <Switch
                    colorScheme={"teal"}
                    defaultChecked={filterApplied}
                    onChange={(e) => setFilterApplied(e.target.checked)}
                  />
                </FormControl>
              )}
            {data[tabIndex ?? localTabIndex].customFilter && (
              <Box w={isMobile ? "100%" : "fit-content"} ml="auto" h="40px">
                {data[tabIndex ?? localTabIndex].customFilter}
              </Box>
            )}

            <Spacer />
            {showButton && !data[tabIndex ?? localTabIndex].hideButton && (
              <Button
                marginX={2}
                mt={isMobile ? 8 : 0}
                variant="solid"
                colorScheme="teal"
                aria-label={
                  data[tabIndex ?? localTabIndex].buttonLabel ||
                  `Add ${getButtonTitle(data[tabIndex ?? localTabIndex].label)}`
                }
                fontSize={isMobile ? "14px" : "16px"}
                onClick={onOpen}
                minWidth="fit-content"
                px={4}
              >
                <PlusSquareIcon mr={2} />
                <Text noOfLines={1} whiteSpace="wrap">
                  {data[tabIndex ?? localTabIndex].buttonLabel ||
                    `Add ${getButtonTitle(
                      data[tabIndex ?? localTabIndex].label,
                    )}`}
                </Text>
              </Button>
            )}
          </Stack>
        </TabList>
        <TabPanels>
          {data.map((tab) => (
            <TabPanel p={4} key={`${tabIndex}_${tab.label}`}>
              <TableContainer>
                <Table size="md">
                  <Thead>
                    <Tr fontWeight="900">
                      {tab.headers.map((header, index) => (
                        <Th key={`${tab.label}_${index}}`}>{header}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tab.isLoading ? getLoadingState(tab.headers) : tab.entries}
                  </Tbody>
                </Table>
                {tab.entries && tab.entries.length === 0 && (
                  <div>
                    No items found - create an entry, or try updating filters,
                    dates or settings
                  </div>
                )}
              </TableContainer>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
}

export default DataTabs;
