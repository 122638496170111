import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Collapse, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { SubLink } from "../../types/routes";

interface CollapsibleNavLinkProps {
  navName: string;
  navIcon: React.ReactNode;
  navLink: string;
  subLinks: SubLink[];
  isCollapsed?: boolean;
  isMobile?: boolean;
  handleClick?: () => void;
  onSubLinkClick?: () => void;
}

export const CollapsibleNavLink: React.FC<CollapsibleNavLinkProps> = ({
  navName,
  navIcon,
  navLink,
  subLinks,
  isCollapsed = false,
  isMobile = false,
  handleClick,
  onSubLinkClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // Check if current route matches this nav item or its children
  const isParentActive = location.pathname === `/${navLink}`;
  const isChildActive = subLinks.some(
    (subLink) => location.pathname === `/${subLink.navLink}`,
  );

  const isActive = isParentActive && isChildActive;

  // // Add effect to collapse submenu when sidebar collapses
  // useEffect(() => {
  //   if (isCollapsed && !isMobile) {
  //     console.log("setting open to false");
  //     setIsOpen(false);
  //   }
  // }, [isCollapsed, isMobile]);

  const constructPath = (link: SubLink) => {
    const params = new URLSearchParams();
    if (link.params) {
      Object.entries(link.params).forEach(([key, value]) => {
        params.set(key, value);
      });
    }
    return `/${navLink}${link.path}${
      params.toString() ? "?" + params.toString() : ""
    }`;
  };

  return (
    <Box>
      <Button
        w="full"
        variant="ghost"
        onClick={() => {
          setIsOpen(!isOpen);
          if (subLinks.length > 0) {
            navigate(constructPath(subLinks[0]));
          }
          if (handleClick) {
            handleClick();
          }
        }}
        p={3}
        bg={isActive ? "teal.50" : "transparent"}
        color={isActive ? "teal.500" : "inherit"}
        _hover={{ bg: "teal.50" }}
        _active={{ bg: "teal.100" }}
        display="flex"
        justifyContent={isCollapsed ? "center" : "flex-start"}
      >
        <Flex
          alignItems="center"
          w="full"
          justifyContent={isCollapsed ? "center" : "space-between"}
        >
          <Flex
            alignItems="center"
            justifyContent={isCollapsed ? "center" : "flex-start"}
          >
            <Box
              fontSize="xl"
              w="24px"
              h="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={isActive ? "teal.500" : "inherit"}
            >
              {navIcon}
            </Box>
            {(!isCollapsed || isMobile) && (
              <Text ml={3} noOfLines={1} fontWeight="semibold">
                {navName}
              </Text>
            )}
          </Flex>
          {(!isCollapsed || isMobile) && (
            <ChevronDownIcon
              transform={isOpen ? "rotate(180deg)" : undefined}
              transition="transform 0.2s"
            />
          )}
        </Flex>
      </Button>
      <Collapse in={isOpen && (!isCollapsed || isMobile)} animateOpacity>
        <VStack align="stretch" pl={!isCollapsed || isMobile ? 8 : 0} pt={2}>
          {subLinks.map((link) => {
            const isSubLinkActive =
              searchParams.get("tab") === link?.params?.tab;
            return (
              <Link key={link.name} to={constructPath(link)}>
                <Button
                  variant="ghost"
                  w="full"
                  size="sm"
                  justifyContent="flex-start"
                  bg={isSubLinkActive ? "teal.50" : "transparent"}
                  color={isSubLinkActive ? "teal.500" : "inherit"}
                  fontWeight={isSubLinkActive ? "semibold" : "normal"}
                  _hover={{ bg: "teal.50" }}
                  onClick={onSubLinkClick ? onSubLinkClick : undefined}
                >
                  {link.name}
                </Button>
              </Link>
            );
          })}
        </VStack>
      </Collapse>
    </Box>
  );
};
