import React from "react";
import Select, { StylesConfig } from "react-select";

interface OrderStatusFilterProps {
  selectedStatuses: string[];
  setSelectedStatuses: (statuses: string[]) => void;
}

const OrderStatusFilter = ({
  selectedStatuses,
  setSelectedStatuses,
}: OrderStatusFilterProps) => {
  const options = [
    {
      value: "PENDING",
      label: <span>PENDING</span>,
      color: "#744210",
      backgroundColor: "#FEFCBF",
      hoverColor: "rgba(254, 252, 191, 0.5)",
    },
    {
      value: "PROCESSING",
      label: <span>PROCESSING</span>,
      color: "#22543D",
      backgroundColor: "#C6F6D5",
      hoverColor: "rgba(198, 246, 213, 0.5)",
    },
    {
      value: "IN_TRANSIT",
      label: <span>IN TRANSIT</span>,
      color: "#2B6CB0",
      backgroundColor: "#BEE3F8",
      hoverColor: "rgba(190, 227, 248, 0.5)",
    },
    {
      value: "DELIVERED",
      label: <span>DELIVERED</span>,
      color: "#718096",
      backgroundColor: "#EDF2F7",
      hoverColor: "rgba(237, 242, 247, 0.5)",
    },
    {
      value: "CANCELLED",
      label: <span>CANCELLED</span>,
      color: "#742A2A",
      backgroundColor: "#FED7D7",
      hoverColor: "rgba(254, 215, 215, 0.5)",
    },
  ];

  interface StatusOption {
    value: string;
    label: React.ReactNode;
    color: string;
    backgroundColor: string;
    hoverColor: string;
  }

  const handleChange = (newValue: readonly StatusOption[]) => {
    setSelectedStatuses(newValue.map((option) => option.value));
  };

  const colourStyles: StylesConfig<StatusOption, true> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "inherit",
      width: "420px",
    }),
    valueContainer: (styles) => ({
      ...styles,
      height: "36px",
      overflow: "hidden",
      ":hover": {
        height: "auto",
      },
    }),
    option: (styles, { data }) => ({
      ...styles,
      backgroundColor: "transparent",
      color: data.color,
      fontWeight: "bold",
      fontSize: "12px",
      "& span": {
        backgroundColor: data.backgroundColor,
        padding: "2px 6px",
        borderRadius: "4px",
      },
      ":hover": {
        backgroundColor: data.hoverColor,
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      fontWeight: "bold",
      fontSize: "12px",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.backgroundColor,
      color: data.color,
      fontWeight: "bold",
      fontSize: "12px",
      padding: "2px 6px",
      borderRadius: "4px",
    }),
  };
  const optionsDefaultValue = selectedStatuses.map((status) =>
    options.find((option) => option.value === status),
  );

  return (
    <Select
      defaultValue={optionsDefaultValue as StatusOption[]}
      onChange={handleChange}
      options={options}
      isMulti
      styles={colourStyles}
      placeholder="Filter by status"
    />
  );
};

export default OrderStatusFilter;
