import React, { useEffect, useState, forwardRef } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import {
  useGetBuyerLocationsQuery,
  useGetCurrentAndOutstandingInvoicesQuery,
  useGetPaymentsQuery,
  useSendAccountStatementEmailMutation,
  useSendUnpaidInvoicesEmailMutation,
} from "../redux/apiSlice";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import DataTabs from "../components/DataTabs/DataTabs";
import { Location } from "../types";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import Page from "../components/Page/Page";
import AddPaymentModal from "../feature/PaymentsView/AddPaymentModal";
import { getLocationAndOrgName } from "../utils/invoiceUtils";
import CustomerSearch from "../components/CustomerSearch/CustomerSearch";
import AddCustomerModal from "../feature/AccountsView/AddCustomerModal";
import PaymentViewEntry from "../feature/PaymentsView/PaymentViewEntry";
import { AccountEntry, AccountInfo } from "../feature/AccountsView/types";
import AccountsViewEntry from "../feature/AccountsView/AccountsViewEntry";
import EmailDetailsModal from "../components/EmailDetailsModal/EmailDetailsModal";
import { ChevronDownIcon } from "@chakra-ui/icons";
import EmailDetailsWithMonthRangePickerModal from "../components/EmailDetailsModal/EmailDetailsWithMonthRangePickerModal.tsx";
import { useSearchParams } from "react-router-dom";

const ForwardedMenu = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Menu>
>((props) => <Menu {...props}>{props.children}</Menu>);

function AccountsPage(): React.ReactElement {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);
  const toast = useToast();
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  const {
    data: buyerLocations,
    isLoading: isLoadingBuyerLocations,
    error: errorBuyerLocations,
  } = useGetBuyerLocationsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const {
    data: invoices,
    error: invoicesError,
    isLoading: invoicesLoading,
  } = useGetCurrentAndOutstandingInvoicesQuery(
    {
      supplierOrganisationId: organisationId,
      supplierLocationId: locationId,
      startTime: startDate,
      endTime: endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const {
    data: payments,
    error: paymentsError,
    isLoading: paymentsLoading,
  } = useGetPaymentsQuery(
    {
      organisationId,
      locationId,
      paymentDateGte: startDate,
      paymentDateLte: endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const [customerFilter, setCustomerFilter] = useState<string | undefined>(
    undefined,
  );
  const [filteredBuyers, setFilteredBuyers] = useState<Location[]>(
    buyerLocations || [],
  );
  const [accountsData, setAccountsData] = useState<AccountInfo[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "0";

  const getTabIndex = (tab: string) => {
    switch (tab) {
      case "1":
        return 1;
      case "0":
      default:
        return 0;
    }
  };

  const tabIndex = getTabIndex(initialTab);

  const handleTabChange = (index: number) => {
    const newTab = index.toString();
    setSearchParams({ tab: newTab });
  };

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams({ tab: "0" });
    }
  }, [searchParams, setSearchParams]);

  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    null,
  );

  enum ACTION_TYPES {
    UNPAID_INVOICES = "UNPAID_INVOICES",
    ACCOUNT_SUMMARY = "ACCOUNT_SUMMARY",
  }

  const [sendUnpaidInvoicesEmail, { isLoading: isSendingUnpaidInvoiceEmail }] =
    useSendUnpaidInvoicesEmailMutation();
  const [sendAccountSummaryEmail, { isLoading: isSendingAccountSummaryEmail }] =
    useSendAccountStatementEmailMutation();
  const [isUnpaidInvoicesEmailModalOpen, setIsUnpaidInvoicesEmailModalOpen] =
    useState(false);
  const [isAccountSummaryEmailModalOpen, setIsAccountSummaryEmailModalOpen] =
    useState(false);

  useEffect(() => {
    if (buyerLocations) {
      if (customerFilter && customerFilter.length > 0) {
        const locationList = buyerLocations.filter(
          (buyer) => getLocationAndOrgName(buyer) === customerFilter,
        );
        setFilteredBuyers(locationList);
      } else {
        setFilteredBuyers(buyerLocations);
      }
    }
  }, [buyerLocations, customerFilter]);

  useEffect(() => {
    if (buyerLocations && invoices) {
      // map location_id to the summed sales total for that location across the entries in sales, setAccountData
      const locations =
        filteredBuyers && filteredBuyers.length > 0
          ? filteredBuyers
          : buyerLocations;

      const accountsData: AccountInfo[] = [];
      locations.forEach((buyer) => {
        const buyerSales = invoices.filter(
          (invoice) => invoice.location_id === buyer.id,
        );
        const totalSales = buyerSales.reduce(
          (acc, sale) => acc + Number(sale.total),
          0,
        );
        const totalCredit = buyerSales.reduce(
          (acc, sale) => acc + Number(sale.credit),
          0,
        );

        const buyerPayments =
          payments?.filter(
            (payment) => payment.customer_location_id === buyer.id,
          ) || [];
        const totalPayments =
          buyerPayments.reduce(
            (acc, payment) => acc + Number(payment.amount),
            0,
          ) || 0;

        let accountEntries: AccountEntry[] = buyerSales.map(
          (invoice) =>
            ({
              date: invoice.invoice_date,
              locationId: invoice.location_id,
              organisationId: invoice.organisation_id,
              name: ` - (${invoice.invoice_date.slice(0, 10)}) Invoice #${
                invoice.id
              }`,
              amountOwed: Number(invoice.total),
              creditAmount: Number(invoice.credit),
              amountPaid: Number(invoice.amount_paid),
              amountRemaining: Number(invoice.total_due),
              balance: Number(invoice.total) - Number(invoice.credit),
              isInvoice: true,
              status: invoice.status,
            }) as AccountEntry,
        );

        accountEntries = accountEntries
          .concat(
            buyerPayments.map(
              (payment) =>
                ({
                  date: payment.payment_date,
                  locationId: payment.customer_location_id,
                  name: ` - (${payment.payment_date.slice(0, 10)}) Payment #${
                    payment.id
                  }`,
                  amountOwed: 0,
                  creditAmount: 0,
                  amountPaid: Number(payment.amount),
                  amountRemaining:
                    Number(payment.amount) -
                    Number(payment.amount_assigned || 0),
                  balance: -Number(payment.amount),
                  isInvoice: false,
                  status: "",
                }) as AccountEntry,
            ),
          )
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          );

        accountsData.push({
          date: "",
          locationId: buyer.id,
          organisationId: buyer.organisation_id,
          name: getLocationAndOrgName(buyer),
          amountOwed: totalSales,
          creditAmount: totalCredit,
          amountPaid: totalPayments,
          balance: totalSales - totalCredit - totalPayments,
          accountEntries: accountEntries.sort((a, b) =>
            a.date.localeCompare(b.date),
          ),
        } as AccountInfo);
      });
      setAccountsData(accountsData);
    } else {
      setAccountsData([]);
    }
  }, [buyerLocations, filteredBuyers, invoices, payments]);

  const handleCheckAccount = (accountId: number) => {
    setSelectedAccountId(accountId === selectedAccountId ? null : accountId);
  };

  const handleSendUnpaidInvoicesEmail = async ({
    toEmails,
    ccEmails,
    bccEmails,
  }: {
    toEmails: string[];
    ccEmails: string[];
    bccEmails: string[];
  }) => {
    if (!selectedAccountId || !organisationId || !locationId) return;

    const selectedAccount = accountsData.find(
      (account) => account.locationId === selectedAccountId,
    );
    if (!selectedAccount) return;

    try {
      await sendUnpaidInvoicesEmail({
        organisationId,
        locationId,
        buyerOrganisationId: selectedAccount.organisationId,
        buyerLocationId: selectedAccount.locationId,
        toEmails,
        ccEmails,
        bccEmails,
      }).unwrap();

      toast({
        title: "Email sent successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsUnpaidInvoicesEmailModalOpen(false);
    } catch {
      toast({
        title: "Failed to send email",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSendAccountSummaryEmail = async ({
    toEmails,
    ccEmails,
    bccEmails,
    monthStart,
    monthEnd,
  }: {
    toEmails: string[];
    ccEmails: string[];
    bccEmails: string[];
    monthStart?: Date | null;
    monthEnd?: Date | null;
  }) => {
    if (!selectedAccountId || !organisationId || !locationId) return;

    const selectedAccount = accountsData.find(
      (account) => account.locationId === selectedAccountId,
    );
    if (!selectedAccount) return;

    try {
      await sendAccountSummaryEmail({
        organisationId,
        locationId,
        buyerOrganisationId: selectedAccount.organisationId,
        buyerLocationId: selectedAccount.locationId,
        toEmails,
        ccEmails,
        bccEmails,
        monthStart: monthStart?.toISOString(),
        monthEnd: monthEnd?.toISOString(),
        timezoneOffset: new Date().getTimezoneOffset(),
      }).unwrap();

      toast({
        title: "Email sent successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsAccountSummaryEmailModalOpen(false);
    } catch {
      toast({
        title: "Failed to send email",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleActionClick = (type: ACTION_TYPES) => {
    if (!selectedAccountId) {
      toast({
        title: "Please select an account",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (type === ACTION_TYPES.UNPAID_INVOICES) {
      setIsUnpaidInvoicesEmailModalOpen(true);
    } else if (type === ACTION_TYPES.ACCOUNT_SUMMARY) {
      setIsAccountSummaryEmailModalOpen(true);
    }
  };

  const getModal = () => (
    <>
      {tabIndex === 0 ? (
        <AddCustomerModal isOpen={isOpen} onClose={onClose} />
      ) : (
        <AddPaymentModal isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );

  return (
    <>
      <PageOverview
        heading={"Accounts"}
        subheading={
          tabIndex === 1
            ? startDate && endDate && `${startDate} - ${endDate}`
            : ""
        }
        showDateRangePicker={tabIndex === 1}
        endElement={
          <>
            {tabIndex === 0 && accountsData && (
              <Flex
                gap={2}
                alignItems={"end"}
                flexDirection={["column", "row"]}
              >
                <Tooltip label="Actions" aria-label="actions">
                  <ForwardedMenu>
                    <MenuButton
                      ml={2}
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      onClick={() => {
                        if (!selectedAccountId) {
                          toast({
                            title:
                              "Please select an account to perform actions",
                            status: "warning",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      }}
                    >
                      Actions
                    </MenuButton>
                    <MenuList textColor={"black"}>
                      <MenuItem
                        isDisabled={
                          isSendingUnpaidInvoiceEmail || !selectedAccountId
                        }
                        onClick={() =>
                          handleActionClick(ACTION_TYPES.UNPAID_INVOICES)
                        }
                      >
                        {isSendingUnpaidInvoiceEmail ? (
                          <Box
                            w={"100%"}
                            display={"flex"}
                            justifyContent={"center"}
                            justifyItems={"center"}
                          >
                            <Spinner />
                          </Box>
                        ) : (
                          "Send Unpaid Invoices Email"
                        )}
                      </MenuItem>
                      <MenuItem
                        isDisabled={
                          isSendingUnpaidInvoiceEmail || !selectedAccountId
                        }
                        onClick={() =>
                          handleActionClick(ACTION_TYPES.ACCOUNT_SUMMARY)
                        }
                      >
                        {isSendingAccountSummaryEmail ? (
                          <Box
                            w={"100%"}
                            display={"flex"}
                            justifyContent={"center"}
                            justifyItems={"center"}
                          >
                            <Spinner />
                          </Box>
                        ) : (
                          "Send Account Summary Email"
                        )}
                      </MenuItem>
                    </MenuList>
                  </ForwardedMenu>
                </Tooltip>
              </Flex>
            )}
          </>
        }
      />
      <Page
        isLoading={
          invoicesLoading || paymentsLoading || isLoadingBuyerLocations
        }
        isError={Boolean(invoicesError || paymentsError || errorBuyerLocations)}
      >
        <>
          <CustomerSearch
            setCustomerName={setCustomerFilter}
            includeSearchIcon
          />
          {isOpen && getModal()}
          <DataTabs
            showButton={true}
            onChange={handleTabChange}
            tabIndex={tabIndex}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            hideTabs
            data={[
              {
                label: "Accounts Receivables",
                buttonLabel: "Add Customer",
                isLoading:
                  paymentsLoading || invoicesLoading || isLoadingBuyerLocations,
                headers: [
                  "SELECT",
                  "Name",
                  "Amount Owed",
                  "Credit Amount",
                  "Amount Paid",
                  "Amount Remaining",
                ],
                entries: accountsData
                  ? accountsData
                      .slice()
                      .sort((a, b) => b.balance - a.balance)
                      .map((accountInfo, index) => (
                        <AccountsViewEntry
                          key={accountInfo.locationId + accountInfo.name}
                          index={index}
                          accountInfo={accountInfo}
                          isSelected={
                            selectedAccountId === accountInfo.locationId
                          }
                          onSelect={() =>
                            handleCheckAccount(accountInfo.locationId)
                          }
                        />
                      ))
                  : [],
              },
              {
                label: "Payments",
                headers: [
                  "Date",
                  "Account",
                  "Amount",
                  "Amount Assigned",
                  "Amount Remaining",
                  "Payment Type",
                ],
                isLoading:
                  paymentsLoading || invoicesLoading || isLoadingBuyerLocations,
                entries: payments
                  ? payments
                      .slice()
                      .sort(
                        (a, b) =>
                          new Date(b.payment_date).getTime() -
                          new Date(a.payment_date).getTime(),
                      )
                      .map((payment, index) => (
                        <PaymentViewEntry
                          key={index}
                          index={index}
                          payment={payment}
                        />
                      ))
                  : [],
              },
            ]}
          />
          {isAccountSummaryEmailModalOpen && (
            <EmailDetailsWithMonthRangePickerModal
              isOpen={isAccountSummaryEmailModalOpen}
              onClose={() => setIsAccountSummaryEmailModalOpen(false)}
              sendEmail={handleSendAccountSummaryEmail}
              isSending={
                isSendingAccountSummaryEmail || isSendingUnpaidInvoiceEmail
              }
              defaultToEmails={[]} // You can add default emails if available
              defaultCcEmails={[]}
              title="Send Account Summary Email"
            />
          )}
          {isUnpaidInvoicesEmailModalOpen && (
            <EmailDetailsModal
              isOpen={isUnpaidInvoicesEmailModalOpen}
              onClose={() => setIsUnpaidInvoicesEmailModalOpen(false)}
              sendEmail={handleSendUnpaidInvoicesEmail}
              isSending={
                isSendingUnpaidInvoiceEmail || isSendingAccountSummaryEmail
              }
              defaultToEmails={[]} // You can add default emails if available
              defaultCcEmails={[]}
              title="Send Unpaid Invoices Email"
            />
          )}
        </>
      </Page>
    </>
  );
}

export default AccountsPage;
