import { Tr } from "@chakra-ui/react";
import { useState } from "react";
import * as React from "react";

interface InteractiveRowProps {
  itemIndex: number | string;
  children?: React.ReactNode[];
  onClick?: (e: any) => void;
}
export default function InteractiveRow({
  itemIndex,
  children,
  onClick,
}: InteractiveRowProps) {
  const [bgColor, setBgColor] = useState("");

  return (
    <Tr
      key={itemIndex}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        if (onClick) onClick(e);
        setBgColor("");
      }}
      bg={bgColor}
      onMouseEnter={() => setBgColor("lightgray")}
      onMouseLeave={() => setBgColor("")}
    >
      {children}
    </Tr>
  );
}
