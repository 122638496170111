import * as React from "react";
import { Box, Divider, Heading, useDisclosure } from "@chakra-ui/react";
import { useGetHarvestRecordsQuery } from "../../redux/apiSlice";
import { filterDatesSlice } from "../../redux/filterDatesSlice";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import DataTabs from "../../components/DataTabs/DataTabs";
import HarvestModal from "./HarvestModal";
import { HarvestItem, Product, ProductUnit } from "../../types";
import { useEffect, useState } from "react";
import HarvestViewEntry from "./HarvestViewEntry";
import * as Sentry from "@sentry/react";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import Page from "../../components/Page/Page";

const HarvestsView = (): React.ReactElement => {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const {
    data: harvests,
    error: harvestsError,
    isLoading: harvestsLoading,
  } = useGetHarvestRecordsQuery({
    organisationId,
    locationId,
    startDate,
    endDate,
  });

  const [productFilterId, setProductFilterId] = useState<
    number | null | undefined
  >(undefined);
  const [productFilterName, setProductFilterName] = useState<string | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [harvestTotal, setHarvestTotal] = useState<
    {
      product_id: number;
      product?: Product;
      product_unit_id: number;
      product_unit?: ProductUnit;
      total: number;
    }[]
  >([]);
  // const [harvestBaseUnit, setHarvestBaseUnit] = useState<string>("lb");
  const harvestBaseUnit = "lb";
  const [harvestBaseUnitTotal, setHarvestBaseUnitTotal] = useState<
    number | string
  >(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [harvestItems, setHarvestItems] = useState<HarvestItem[]>([]);

  useEffect(() => {
    if (productFilterId && harvestItems) {
      // Set harvest total to be a list of the summed harvest items, where quantities for the same product units are summed
      const totalHarvest = harvestItems.reduce(
        (acc, item) => {
          const existing = acc.find(
            (total) =>
              total.product_id === item.product_id &&
              total.product_unit_id === item.product_unit_id,
          );
          if (existing) {
            existing.total += Number(item.quantity);
          } else {
            acc.push({
              product_id: item.product_id,
              product: item.product,
              product_unit_id: item.product_unit_id,
              product_unit: item.product_unit,
              total: Number(item.quantity),
            });
          }
          return acc;
        },
        [] as {
          product_id: number;
          product?: Product;
          product_unit_id: number;
          product_unit?: ProductUnit;
          total: number;
        }[],
      );
      if (totalHarvest) {
        setHarvestTotal(totalHarvest);
        let baseUnitTotal = 0;
        for (let i = 0; i < totalHarvest.length; i++) {
          if (totalHarvest[i].product_unit?.unit_measure === harvestBaseUnit) {
            baseUnitTotal +=
              totalHarvest[i].total *
              (totalHarvest[i].product_unit?.unit_quantity || 0);
          } else if (totalHarvest[i].product_unit?.unit_measure === "kg") {
            baseUnitTotal +=
              totalHarvest[i].total *
              (totalHarvest[i].product_unit?.unit_quantity || 0) *
              2.20462;
          } else {
            console.error("Unsupported unit conversion in Harvest records");
            Sentry.captureException(
              `Unsupported unit conversion in Harvest records: ${totalHarvest[i].product_unit?.unit_measure}, base unit ${harvestBaseUnit}`,
            );
          }
        }
        setHarvestBaseUnitTotal(Number(baseUnitTotal).toFixed(2));
      } else {
        setHarvestTotal([]);
        setHarvestBaseUnitTotal("0");
      }
    } else {
      setHarvestTotal([]);
      setHarvestBaseUnitTotal("0");
    }
  }, [productFilterId, harvestItems]);

  useEffect(() => {
    if (harvests) {
      const sortedHarvests = harvests.slice().sort((a, b) => {
        return (
          new Date(b.harvest_date).getTime() -
          new Date(a.harvest_date).getTime()
        );
      });
      let items = sortedHarvests
        .map((harvest) =>
          harvest.harvest_items.map((harvestItem) => ({
            ...harvestItem,
            harvest: harvest,
            harvest_date: harvest.harvest_date,
          })),
        )
        .flat();
      if (productFilterId) {
        items = items.filter((item) => item.product_id === productFilterId);
      }
      setHarvestItems(items);
    }
  }, [harvests, productFilterId]);

  return (
    <Page isLoading={harvestsLoading} isError={Boolean(harvestsError)}>
      <HarvestModal isOpen={isOpen} onClose={onClose} />
      <InventoryProductSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setProductFilterId={setProductFilterId}
        setProductName={setProductFilterName}
        includeSearchIcon
      />
      <DataTabs
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        data={[
          {
            label: "Harvests",
            headers: ["Harvest Date", "Product", "Unit", "Quantity"],
            entries:
              harvestItems &&
              harvestItems.map((harvestItem, index) => {
                return (
                  <HarvestViewEntry
                    itemIndex={index}
                    harvest={harvestItem.harvest}
                    harvestItem={harvestItem}
                  />
                );
              }),
          },
        ]}
      />
      {harvestTotal && harvestTotal.length > 0 && (
        <>
          <Divider />
          <Box marginTop={"1rem"}>
            <Heading fontSize={22} marginBottom={"0.6rem"}>
              Total Harvested:
            </Heading>
            <ul>
              {harvestTotal.map((total) => (
                <li>
                  {total.product?.name} - {total.total} x{" "}
                  {getProductUnitLabel(total.product_unit)}{" "}
                </li>
              ))}
            </ul>
            <b>
              Total in {harvestBaseUnit}: {harvestBaseUnitTotal}
            </b>
          </Box>
        </>
      )}
      {harvestsLoading && <p>Loading...</p>}
      {harvestsError && <p>Error</p>}
      {!harvestsLoading &&
        !harvestsError &&
        harvests &&
        harvestItems.length === 0 && (
          <>
            {productFilterId ? (
              <>
                <p>
                  No harvests found for selected product "{productFilterName}"
                </p>
                <p>Try updating your search for this period</p>
              </>
            ) : (
              <p>No harvests found</p>
            )}
          </>
        )}
    </Page>
  );
};

export default HarvestsView;
