export const COLUMN_TYPE_OPTIONS = [
  {
    value: "product_name",
    label: "Product Name",
    icon: "🏷️",
    exclusive: true,
  },
  {
    value: "unit",
    label: "Unit Info",
    icon: "📦",
    exclusive: true,
  },
  {
    value: "price",
    label: "Price",
    icon: "💰",
    exclusive: true,
  },
  {
    value: "weight",
    label: "Weight",
    icon: "⚖️",
    exclusive: true,
  },
  {
    value: "origin",
    label: "Origin",
    icon: "🌍",
    exclusive: false,
  },
  {
    value: "producer",
    label: "Producer",
    icon: "👥",
    exclusive: false,
  },
  {
    value: "certificates",
    label: "Certificates",
    icon: "📜",
    exclusive: false,
  },
  {
    value: "upc",
    label: "UPC",
    icon: "📦",
    exclusive: false,
  },
  {
    value: "vendor_sku",
    label: "Vendor SKU",
    icon: "🔢",
    exclusive: false,
  },
];

export const getColumnTypeOption = (type: string | string[]) => {
  const typeValue = Array.isArray(type) ? type[0] : type;

  return COLUMN_TYPE_OPTIONS.find((opt) => opt.value === typeValue);
};
