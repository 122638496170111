import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
  VStack,
  Input,
  Select,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { ColumnType, ColumnConfig, MappingPreset } from "./types";
import VirtualizedDataTable from "../../../components/DataTable/VirtualizedDataTable";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ArrowBackIcon, DeleteIcon } from "@chakra-ui/icons";
import { getColumnTypeOption } from "./utils";

interface ColumnMappingConfigProps {
  previewData: string[][];
  setPreviewData: (data: string[][]) => void;
  startRow: number;
  onStartRowChange: (row: number) => void;
  endRow: number;
  onEndRowChange: (row: number) => void;
  headerRow: number;
  onHeaderRowChange: (row: number) => void;
  onConfirm: () => void;
  onCancel: () => void;
  columnTypeSelect: (header: string) => React.ReactNode;
  columnConfigs: ColumnConfig[];
  baseUnit: string;
  setColumnConfigs: React.Dispatch<React.SetStateAction<ColumnConfig[]>>;
  handleColumnTypeChange: (columnId: string, newType: ColumnType[]) => void;
  selectionStep: "header" | "start" | "end";
  setSelectionStep: (step: "header" | "start" | "end") => void;
  selectedLocationId: number | null;
  selectedOrganisationId: number | null;
}

const STORAGE_KEY = "columnMappingPresets";

// Add a utility function to check if a column is empty
const isColumnEmpty = (data: string[][], columnIndex: number): boolean => {
  return data.every(
    (row) =>
      !row[columnIndex] ||
      row[columnIndex].trim() === "" ||
      row[columnIndex] === "-",
  );
};

// Add a function to get non-empty columns
const getNonEmptyColumns = (data: string[][]): number[] => {
  if (!data.length || !data[0]) return [];

  return data[0].reduce((acc: number[], _, index) => {
    if (!isColumnEmpty(data, index)) {
      acc.push(index);
    }
    return acc;
  }, []);
};

interface SavePresetModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
}

const SavePresetModal = React.memo(
  ({ isOpen, onClose, onSave }: SavePresetModalProps) => {
    const [localPresetName, setLocalPresetName] = useState("");

    const handleSave = () => {
      onSave(localPresetName);
      setLocalPresetName("");
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save Mapping Preset</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Enter preset name"
              value={localPresetName}
              onChange={(e) => setLocalPresetName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              onClick={handleSave}
              isDisabled={!localPresetName.trim()}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);

SavePresetModal.displayName = "SavePresetModal";

const ColumnMappingConfig: React.FC<ColumnMappingConfigProps> = ({
  previewData,
  setPreviewData,
  startRow,
  onStartRowChange,
  endRow,
  onEndRowChange,
  headerRow,
  onHeaderRowChange,
  onConfirm,
  onCancel,
  columnTypeSelect,
  columnConfigs,
  baseUnit,
  setColumnConfigs,
  handleColumnTypeChange,
  selectionStep,
  setSelectionStep,
  selectedLocationId,
  selectedOrganisationId,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [presets, setPresets] = useState<MappingPreset[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<string>("");

  const columnHelper = createColumnHelper<string[]>();
  const handleRowClick = React.useCallback(
    (rowIndex: number) => {
      switch (selectionStep) {
        case "header":
          onHeaderRowChange(rowIndex);
          break;
        case "start":
          if (rowIndex > headerRow) {
            onStartRowChange(rowIndex);
          }
          break;
        case "end":
          if (rowIndex > startRow) {
            onEndRowChange(rowIndex);
          }
          break;
      }
    },
    [
      selectionStep,
      onHeaderRowChange,
      onStartRowChange,
      onEndRowChange,
      headerRow,
      startRow,
    ],
  );

  const handleRemoveColumn = useCallback(
    (columnIndex: number) => {
      // Update preview data by removing the specified column
      const newPreviewData = previewData.map((row) =>
        row.filter((_, index) => index !== columnIndex),
      );
      setPreviewData(newPreviewData);

      // Update column configs by removing the config for the deleted column
      const columnId =
        previewData[headerRow]?.[columnIndex] || `column${columnIndex}`;
      setColumnConfigs((prevConfigs: ColumnConfig[]) =>
        prevConfigs.filter((config) => config.id !== columnId),
      );

      // Show a toast notification
      toast({
        title: "Column removed",
        description: "The column has been removed from the mapping",
        status: "success",
        duration: 3000,
      });
    },
    [previewData, setPreviewData, headerRow, setColumnConfigs, toast],
  );

  const nonEmptyColumns = React.useMemo(
    () => getNonEmptyColumns(previewData),
    [previewData],
  );

  const getColumnDisplayName = useCallback(
    (colIndex: number, maxLength: number = 10) => {
      const columnName =
        previewData[headerRow]?.[colIndex] || `Column ${colIndex + 1}`;

      if (columnName.length > 15) {
        return `${columnName.slice(0, maxLength)}...`;
      }

      return columnName;
    },
    [previewData, headerRow],
  );

  const columns = React.useMemo(() => {
    if (!previewData[0]) return [];

    return [
      columnHelper.accessor((row) => String(previewData.indexOf(row) + 1), {
        id: "rowNumber",
        header: "Row",
        cell: (info) => (
          <Box
            cursor="pointer"
            width="100%"
            textAlign="center"
            onClick={() => handleRowClick(info.row.index)}
          >
            <Text fontWeight="bold">{info.getValue()}</Text>
          </Box>
        ),
        size: 5,
      }),
      ...nonEmptyColumns.map((colIndex) =>
        columnHelper.accessor((row) => row[colIndex], {
          id: previewData[headerRow]?.[colIndex] || `column${colIndex}`,
          header: () => (
            <Flex
              direction="column"
              align="center"
              justifyContent="space-between"
              height="full"
            >
              <Tooltip
                label={
                  previewData[headerRow]?.[colIndex] || `Column ${colIndex + 1}`
                }
              >
                <VStack spacing={1} align="stretch">
                  <Text fontSize="sm">{getColumnDisplayName(colIndex)}</Text>
                  {selectionStep === "end" && columnConfigs && (
                    <>
                      {(() => {
                        const columnId =
                          previewData[headerRow]?.[colIndex] ||
                          `column${colIndex}`;
                        const config = columnConfigs.find(
                          (c) => c.id === columnId,
                        );

                        if (!config?.types?.length) return null;

                        const typeLabels = config.types.map((type) => {
                          const typeString = Array.isArray(type)
                            ? type.join("")
                            : type;
                          const option = getColumnTypeOption(typeString);
                          return option
                            ? `${option.icon} ${option.label}`
                            : typeString;
                        });

                        return (
                          <Text fontSize="xs" color="gray.600">
                            {typeLabels.join(" • ")}
                          </Text>
                        );
                      })()}
                    </>
                  )}
                </VStack>
              </Tooltip>
              {selectionStep === "end" && (
                <Box py="2">
                  {columnTypeSelect(
                    previewData[headerRow]?.[colIndex] || `column${colIndex}`,
                  )}
                </Box>
              )}
              <Tooltip label="Remove column">
                <IconButton
                  aria-label="Remove column"
                  icon={<DeleteIcon />}
                  position="relative"
                  bottom="0"
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  ml="auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveColumn(colIndex);
                  }}
                />
              </Tooltip>
            </Flex>
          ),
          cell: (info) => {
            const value = info.getValue() as string;
            const header =
              previewData[headerRow]?.[colIndex] || `column${colIndex}`;
            const columnConfig = columnConfigs?.find((c) => c.id === header);

            // Format cell value based on column type
            const getFormattedValue = () => {
              if (!columnConfig) return value;

              switch (columnConfig.types[0]) {
                case "price": {
                  let parsedToFloat: number | string = parseFloat(value);
                  if (isNaN(parsedToFloat)) {
                    // If the value has symbols like $ or , then remove them and try again
                    parsedToFloat = parseFloat(
                      value.replace(/[$,]/g, ""),
                    ).toFixed(2);
                  }
                  if (isNaN(+parsedToFloat)) {
                    return value;
                  }
                  return value ? `$${parsedToFloat}` : "";
                }
                case "weight":
                  return value ? `${value} ${baseUnit}` : "";
                case "product_name":
                  return value || "";
                default:
                  return value;
              }
            };

            return (
              <Tooltip label={value}>
                <Box
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  cursor="pointer"
                  width="100%"
                  px={2}
                  onClick={() => handleRowClick(info.row.index)}
                  _hover={{ bg: "gray.50" }}
                >
                  {getFormattedValue()}
                </Box>
              </Tooltip>
            );
          },
          size: 90 / nonEmptyColumns.length,
        }),
      ),
    ];
  }, [
    previewData,
    columnHelper,
    handleRowClick,
    headerRow,
    nonEmptyColumns,
    columnTypeSelect,
    selectionStep,
    columnConfigs,
    baseUnit,
    handleRemoveColumn,
    getColumnDisplayName,
  ]);

  // Add a summary of filtered columns
  const columnSummary = React.useMemo(() => {
    const totalColumns = previewData[0]?.length || 0;
    const filteredColumns = nonEmptyColumns.length;
    const hiddenColumns = totalColumns - filteredColumns;

    return hiddenColumns > 0 ? (
      <Text fontSize="sm" color="gray.500" mt={2}>
        {hiddenColumns} empty{" "}
        {hiddenColumns === 1 ? "column was" : "columns were"} hidden
      </Text>
    ) : null;
  }, [previewData, nonEmptyColumns]);

  const getRowStyles = React.useCallback(
    (rowIndex: number) => ({
      cursor: "pointer",
      backgroundColor:
        rowIndex === headerRow
          ? "var(--chakra-colors-purple-50)"
          : rowIndex === startRow && selectionStep !== "header"
          ? "var(--chakra-colors-blue-50)"
          : rowIndex === endRow && selectionStep === "end"
          ? "var(--chakra-colors-green-50)"
          : rowIndex > startRow && rowIndex < endRow && selectionStep === "end"
          ? "var(--chakra-colors-gray-50)"
          : rowIndex % 2 === 0
          ? "#f8f9fa"
          : "white",
      "&:hover": {
        backgroundColor:
          rowIndex === headerRow
            ? "var(--chakra-colors-purple-100)"
            : rowIndex === startRow
            ? "var(--chakra-colors-blue-100)"
            : rowIndex === endRow
            ? "var(--chakra-colors-green-100)"
            : "var(--chakra-colors-gray-100)",
      },
    }),
    [headerRow, startRow, endRow, selectionStep],
  );

  const handleNextStep = () => {
    switch (selectionStep) {
      case "header":
        setSelectionStep("start");
        onStartRowChange(headerRow + 1);
        break;
      case "start":
        setSelectionStep("end");
        onEndRowChange(startRow + 10);
        break;
      case "end":
        onConfirm();
        break;
    }
  };

  const getStepContent = () => {
    switch (selectionStep) {
      case "header":
        return {
          title: "Select Header Row",
          description: "Click on the row that contains your column headers",
          info: `Selected header row: ${headerRow + 1}`,
          rowIndicator: (
            <Text fontSize="sm" color="purple.500">
              Selected row will be highlighted in{" "}
              <Box as="span" fontWeight="bold">
                purple
              </Box>
            </Text>
          ),
        };
      case "start":
        return {
          title: "Select Start Row",
          description:
            "Click on the first row where you want to start mapping data",
          info: `Start mapping from row: ${startRow + 1}`,
          rowIndicator: (
            <Text fontSize="sm" color="blue.500">
              Selected row will be highlighted in{" "}
              <Box as="span" fontWeight="bold">
                blue
              </Box>
            </Text>
          ),
        };
      case "end":
        return {
          title: "Select End Row",
          description:
            "Click on the last row where you want to end mapping data",
          info: `Data range: rows ${startRow + 1} - ${endRow + 1} (${
            endRow - startRow + 1
          } rows)`,
          rowIndicator: (
            <Text fontSize="sm" color="green.500">
              Selected row will be highlighted in{" "}
              <Text as="span" fontWeight="bold">
                green
              </Text>{" "}
              and selected range in{" "}
              <Text as="span" fontWeight="bold">
                gray
              </Text>
            </Text>
          ),
        };
    }
  };

  const stepContent = getStepContent();

  // Memoize handlers
  const handleSavePreset = useCallback(
    (name: string) => {
      if (!name.trim()) {
        toast({
          title: "Error",
          description: "Please enter a preset name",
          status: "error",
          duration: 3000,
        });
        return;
      }

      const newPreset: MappingPreset = {
        id: Date.now().toString(),
        name: name.trim(),
        locationId: selectedLocationId,
        organisationId: selectedOrganisationId,
        headerRow,
        startRow,
        endRow,
        createdAt: new Date().toISOString(),
        columnConfigs,
        priceColumnId:
          columnConfigs.find((c) => c.types.includes("price"))?.id || null,
        weightColumnId:
          columnConfigs.find((c) => c.types.includes("weight"))?.id || null,
        productNameColumnId:
          columnConfigs.find((c) => c.types.includes("product_name"))?.id ||
          null,
      };

      const savedPresets = localStorage.getItem(STORAGE_KEY);
      const presets: MappingPreset[] = savedPresets
        ? JSON.parse(savedPresets)
        : [];
      presets.push(newPreset);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(presets));

      toast({
        title: "Success",
        description: "Preset saved successfully",
        status: "success",
        duration: 3000,
      });
      onClose();
    },
    [
      selectedLocationId,
      selectedOrganisationId,
      headerRow,
      startRow,
      endRow,
      columnConfigs,
      toast,
      onClose,
    ],
  );

  const handleLoadPreset = useCallback(
    (presetId: string) => {
      const preset = presets.find(({ id }) => id === presetId);
      if (preset) {
        onHeaderRowChange(preset.headerRow);
        onStartRowChange(preset.startRow);
        onEndRowChange(preset.endRow);
        setSelectionStep("end");

        // Get the column IDs from the preset's configuration
        const presetColumnIds = preset.columnConfigs.map((config) => config.id);

        // Filter the preview data to only include columns that are in the preset
        const filteredPreviewData = previewData.map((row) => {
          const headerRow = previewData[preset.headerRow];
          return row.filter((_, colIndex) => {
            const columnId = headerRow[colIndex];
            return presetColumnIds.includes(columnId);
          });
        });

        setPreviewData(filteredPreviewData);
        setColumnConfigs(preset.columnConfigs);

        // Apply column type configurations
        if (preset.priceColumnId) {
          handleColumnTypeChange(preset.priceColumnId, ["price"]);
        }
        if (preset.weightColumnId) {
          handleColumnTypeChange(preset.weightColumnId, ["weight"]);
        }
        if (preset.productNameColumnId) {
          handleColumnTypeChange(preset.productNameColumnId, ["product_name"]);
        }

        toast({
          title: "Success",
          description: "Preset loaded successfully",
          status: "success",
          duration: 3000,
        });
      }
    },
    [
      presets,
      previewData,
      onHeaderRowChange,
      onStartRowChange,
      onEndRowChange,
      setSelectionStep,
      setPreviewData,
      setColumnConfigs,
      toast,
      handleColumnTypeChange,
    ],
  );

  // Load presets only once on mount
  useEffect(() => {
    const savedPresets = localStorage.getItem(STORAGE_KEY);
    if (savedPresets) {
      setPresets(JSON.parse(savedPresets));
    }
  }, []);

  // Memoize the preset selector
  const PresetSelector = useMemo(() => {
    // Filter presets based on location
    const locationPresets = presets.filter(
      (preset) => preset.locationId === selectedLocationId,
    );

    // If there are location-specific presets, show only those
    // Otherwise, show all presets
    const displayPresets =
      locationPresets.length > 0 ? locationPresets : presets;

    return (
      <Select
        placeholder="Load preset"
        value={selectedPreset}
        onChange={(e) => {
          setSelectedPreset(e.target.value);
          handleLoadPreset(e.target.value);
        }}
        width="200px"
      >
        {displayPresets.map((preset) => (
          <option key={preset.id} value={preset.id}>
            {preset.name}
            {!locationPresets.length &&
              preset.locationId &&
              " (Other Location)"}
          </option>
        ))}
      </Select>
    );
  }, [presets, selectedPreset, handleLoadPreset, selectedLocationId]);

  return (
    <VStack spacing={1} align="stretch">
      <Flex direction="column" gap={2}>
        <Text fontWeight="bold" mb={2}>
          {stepContent.title}
        </Text>
        <Flex direction="column">
          <Text fontSize="sm" color="gray.600">
            {stepContent.description}
          </Text>
          {stepContent.rowIndicator}
          {columnSummary}
        </Flex>
      </Flex>

      <Flex align="center" gap={4}>
        <Text fontSize="sm" color="gray.600" fontWeight="bold">
          {stepContent.info}
        </Text>
      </Flex>

      <VirtualizedDataTable
        data={previewData}
        columns={columns as ColumnDef<string[], unknown>[]}
        isFullscreen={false}
        getRowStyles={getRowStyles}
        onRowClick={handleRowClick}
        height={"45vh"}
      />
      <Flex justify="space-between" align="center">
        {PresetSelector}
        <Flex justify="flex-end" gap={4} mt={4}>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          {selectionStep !== "header" && (
            <Button
              variant="outline"
              onClick={() => {
                if (selectionStep === "end") {
                  setSelectionStep("start");
                } else if (selectionStep === "start") {
                  setSelectionStep("header");
                }
              }}
              leftIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          )}
          {selectionStep === "header" && (
            <Button variant="outline" onClick={() => onHeaderRowChange(0)}>
              Use First Row
            </Button>
          )}
          <Button
            colorScheme="teal"
            onClick={handleNextStep}
            isDisabled={
              (selectionStep === "start" && startRow <= headerRow) ||
              (selectionStep === "end" && endRow <= startRow)
            }
          >
            {selectionStep === "end" ? "Confirm Selection" : "Next"}
          </Button>
        </Flex>
      </Flex>

      {/* Progress indicator */}
      <Flex justify="center" gap={2}>
        {["header", "start", "end"].map((step) => (
          <Box
            key={step}
            w="8px"
            h="8px"
            borderRadius="full"
            bg={selectionStep === step ? "blue.500" : "gray.200"}
          />
        ))}
      </Flex>

      <Box mb={4} mr="auto">
        {selectionStep === "end" && (
          <Button colorScheme="teal" onClick={onOpen}>
            Save as Preset
          </Button>
        )}
      </Box>

      <SavePresetModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={handleSavePreset}
      />
    </VStack>
  );
};

export default React.memo(ColumnMappingConfig);
