import { AddIcon, ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import * as chakra from "@chakra-ui/react";
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import Fuse from "fuse.js";
import Papa from "papaparse";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import VirtualizedDataTable from "../../../components/DataTable/VirtualizedDataTable.tsx";
import FileUpload from "../../../components/FileUpload/FileUpload.tsx";
import SupplierLocationSearch from "../../../components/SupplierLocationSearch/SupplierLocationSearch.tsx";
import { useGetProductsQuery } from "../../../redux/apiSlice.ts";
import { Product, ProductUnit } from "../../../types.ts";
import {
  processProductName,
  removeProductStopwords,
  removeTrailingS,
} from "../../../utils/nlpUtils.ts";
import { capitalizeWords } from "../../../utils/stringUtils.ts";
import AddProductModal from "../../ProductView/AddProductModal.tsx";
import ColumnMappingConfig from "./ColumnMappingConfig.tsx";
import { ColumnConfig, ColumnType } from "./types.ts";
import { COLUMN_TYPE_OPTIONS, getColumnTypeOption } from "./utils";

interface ImportPricesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type PriceImport = {
  product_name?: string;
  product_id?: number;
  product_unit_id?: number;
  product?: Product;
  suggested_product?: Product;
  product_suggestions?: Product[];
  confident_match?: boolean;
  product_unit?: ProductUnit;
  supplier_product_name: string;
  certificates?: string;
  certifying_body?: string;
  unit_quantity: number;
  price: number;
  price_per_base_unit: number;
  origin: string;
  producer_name: string;
};
type RawDataRow = {
  [key: string]: string;
};

// const KG_TO_LB = 2.20462;

const ImportPricesModal = ({
  isOpen,
  onClose,
}: ImportPricesModalProps): React.ReactElement => {
  const toast = chakra.useToast();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(true);
  const [parsedData, setParsedData] = useState<PriceImport[]>([]);
  const [baseUnit, setBaseUnit] = useState<string>("kg");
  const parsedRowsCount = React.useRef(0);
  const tempParsedData = React.useRef<PriceImport[]>([]);
  const parserRef = React.useRef<unknown>(null);
  const [fileProcessed, setFileProcessed] = useState(false);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState<number | null>(null);
  const [initialProductName, setInitialProductName] = useState<string>("");
  const [addProductDescription, setAddProductDescription] =
    useState<string>("");
  const [fromDate, setFromDate] = useState<string>(
    new Date().toISOString().split("T")[0],
  );
  const [toDate, setToDate] = useState<string>("");
  const [showColumnMapping, setShowColumnMapping] = useState(false);
  const [previewData, setPreviewData] = useState<string[][]>([]);
  const [headerRow, setHeaderRow] = useState(0);
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState<number>(startRow + 1);

  const { data: products } = useGetProductsQuery("");

  // Add new state for tracking the view state
  const [currentView, setCurrentView] = useState<"mapping" | "preview">(
    "mapping",
  );

  // Add state for column configurations
  const [columnConfigs, setColumnConfigs] = useState<ColumnConfig[]>([]);

  // Add these state variables to track price and weight columns
  const [priceColumnId, setPriceColumnId] = useState<string | null>(null);
  const [weightColumnId, setWeightColumnId] = useState<string | null>(null);

  // Add state for tracking product name column
  const [productNameColumnId, setProductNameColumnId] = useState<string | null>(
    null,
  );

  const [selectedSupplierLocationId, setSelectedSupplierLocationId] = useState<
    number | null
  >(null);
  const [selectedSupplierOrganisationId, setSelectedSupplierOrganisationId] =
    useState<number | null>(null);

  // Update matchProductsWithPrices to be more strict about requirements
  const matchProductsWithPrices = useCallback(
    (
      rawPriceData: PriceImport[],
      newProductNameColumnId: string,
    ): PriceImport[] => {
      // Early return if we don't have the required data
      if (
        !products?.length ||
        !newProductNameColumnId ||
        !rawPriceData?.length
      ) {
        console.log("Missing required data for product matching:", {
          hasProducts: `${!!products?.length} - ${products?.length}`,
          hasProductNameColumn: `${!!newProductNameColumnId} - ${newProductNameColumnId}`,
          hasData: `${!!rawPriceData?.length} - ${rawPriceData?.length}`,
        });
        return rawPriceData;
      }

      const productsProcessed = products.map((product) => ({
        ...product,
        keyName: processProductName(product.name),
      }));

      const productFuse = new Fuse(productsProcessed, {
        keys: ["keyName"],
      });
      const matchedData = rawPriceData.map((row) => {
        const newRow = { ...row };
        let supplierName = row[
          newProductNameColumnId as keyof PriceImport
        ] as string;

        if (!supplierName || !supplierName.length) {
          return newRow;
        }

        supplierName = removeProductStopwords(processProductName(supplierName));
        const matches = productFuse.search(supplierName?.trim());

        if (matches && matches.length) {
          const bestMatch = matches[0];
          newRow.suggested_product = bestMatch.item;
          newRow.product = bestMatch.item;
          newRow.product_suggestions = matches
            .map((match) => match.item)
            .slice(0, 5);

          if (
            removeTrailingS(supplierName) ===
            removeTrailingS(processProductName(bestMatch.item.name))
          ) {
            newRow.confident_match = true;
          } else if (bestMatch.score && bestMatch.score < 0.2) {
            newRow.confident_match = true;
          }
        }

        return newRow;
      });

      return matchedData;
    },
    [products],
  );

  const handleColumnTypeChange = useCallback(
    (columnId: string, newType: ColumnType[]) => {
      setColumnConfigs((prevConfigs) => {
        if (!prevConfigs.length) {
          return prevConfigs;
        }

        // Update the types for the selected column
        const newConfigs = prevConfigs.map((config) => {
          if (config.id === columnId) {
            const uniqueTypes = Array.from(new Set(newType));
            return { ...config, types: uniqueTypes };
          }
          return config;
        });
        return newConfigs;
      });

      // Only handle product name separately since it requires data updates
      if (newType.includes("product_name")) {
        setProductNameColumnId(columnId);

        // Update parsedData to include supplier_product_name
        if (parsedData.length > 0) {
          const matchedData = matchProductsWithPrices(
            parsedData.map((row) => ({
              ...row,
              supplier_product_name: row[
                columnId as keyof PriceImport
              ] as string,
            })),
            columnId,
          );
          setParsedData(matchedData);
        }
      }

      if (newType.length === 0) {
        setParsedData((prev) => [...prev]);
      }
    },
    [parsedData, matchProductsWithPrices],
  );

  // const calculatePricePerBaseUnit = (price: number, quantity: number, baseUnit: string): number => {
  //   const pricePerKg = price / quantity;
  //   return baseUnit === "lb" ? pricePerKg * KG_TO_LB : pricePerKg;
  // };

  // Add this function to reset all states
  const resetMappingStates = useCallback(() => {
    setHeaderRow(0);
    setStartRow(1);
    setEndRow(1);
    setSelectionStep("header");
    setColumnConfigs([]);
    setPriceColumnId(null);
    setWeightColumnId(null);
    setProductNameColumnId(null);
    setCurrentView("mapping");
    setShowColumnMapping(true);
    setPreviewData([]);
    setParsedData([]);
    setFileProcessed(false);
  }, []);

  // Update the handleFileUpload function
  const handleFileUpload = useCallback(
    (file: File | null) => {
      if (!fromDate) {
        toast({
          title: "From Date is required",
          description: "Please select a start date for these prices",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!selectedSupplierLocationId) {
        toast({
          title: "Supplier is required",
          description: "Please select a supplier for these prices",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Reset all states when a new file is uploaded
      resetMappingStates();

      if (!file) {
        return;
      }

      parsedRowsCount.current = 0;
      tempParsedData.current = [];
      Papa.parse(file, {
        complete: (results) => {
          setPreviewData(results.data as string[][]);
          setEndRow((results.data as string[][]).length - 1);
          setShowColumnMapping(true);
        },
        error: (error) => {
          toast({
            title: "Failed to read file",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      });
    },
    [fromDate, toast, resetMappingStates, selectedSupplierLocationId],
  );

  useEffect(() => {
    if (!isOpen) {
      if (
        parserRef.current &&
        typeof parserRef.current === "object" &&
        "abort" in parserRef.current
      ) {
        (parserRef.current as { abort: () => void }).abort();
      }
      setParsedData([]);
      setFileProcessed(false);
      parsedRowsCount.current = 0;
      tempParsedData.current = [];
      setCurrentView("mapping");
    }
  }, [isOpen]);

  const changeSelectedProduct = useCallback(
    (index: number, productId: number) => {
      const updatedData = [...parsedData];
      updatedData[index].product = products?.find(
        (product) => product.id === productId,
      );
      setParsedData(updatedData);
    },
    [parsedData, products],
  );

  const handleAddProductClick = useCallback(
    (rowIndex: number) => {
      const currentRow = parsedData[rowIndex];
      const cleanedProductName = capitalizeWords(
        removeProductStopwords(
          processProductName(currentRow.supplier_product_name),
        ),
      );

      setCurrentRowIndex(rowIndex);
      setInitialProductName(cleanedProductName);
      setAddProductDescription(currentRow.supplier_product_name);
      setIsAddProductModalOpen(true);
    },
    [parsedData],
  );

  const handleAddProductClose = () => {
    setIsAddProductModalOpen(false);
    setCurrentRowIndex(null);
    setInitialProductName("");
  };

  const handleProductCreated = (newProduct: Product) => {
    if (currentRowIndex !== null) {
      const newParsedData = parsedData.map((row, index) => {
        if (index === currentRowIndex) {
          return {
            ...row,
            suggested_product: newProduct,
            product: newProduct,
            product_suggestions: row.product_suggestions
              ? [...row.product_suggestions, newProduct]
              : [newProduct],
          };
        }
        return row;
      });
      setParsedData(newParsedData);
      changeSelectedProduct(currentRowIndex, newProduct.id);
    }
  };

  const getColumnTypeOptions = useCallback(
    (header: string) => {
      return COLUMN_TYPE_OPTIONS.map((option) => ({
        ...option,
        status: option.exclusive
          ? header === productNameColumnId && option.value === "product_name"
            ? "current"
            : header === priceColumnId && option.value === "price"
            ? "current"
            : header === weightColumnId && option.value === "weight"
            ? "current"
            : productNameColumnId && option.value === "product_name"
            ? "taken"
            : priceColumnId && option.value === "price"
            ? "taken"
            : weightColumnId && option.value === "weight"
            ? "taken"
            : undefined
          : undefined,
      }));
    },
    [priceColumnId, productNameColumnId, weightColumnId],
  );

  const ColumnTypeSelect = useCallback(
    ({
      header,
      currentTypes,
      onChange,
    }: {
      header: string;
      currentTypes: ColumnType[];
      onChange: (header: string, types: ColumnType[]) => void;
    }) => {
      const options = getColumnTypeOptions(header);

      const handleTypeToggle = (type: ColumnType) => {
        const newTypes: ColumnType[] = currentTypes.includes(type)
          ? currentTypes.filter((t) => t !== type)
          : [...currentTypes, type];

        onChange(header, newTypes);
      };

      return (
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            size="xs"
            rightIcon={<ChevronDownIcon />}
            bg="white"
            borderColor="gray.200"
            _hover={{ borderColor: "gray.300" }}
          >
            Column Types
          </MenuButton>
          <MenuList minWidth="200px">
            {options.map((option) => (
              <MenuItem key={option.value} onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  isChecked={currentTypes.includes(option.value as ColumnType)}
                  onChange={() => handleTypeToggle(option.value as ColumnType)}
                  isDisabled={option.status === "taken"}
                >
                  {`${option.icon} ${option.label}`}
                  {option.status === "current" && " (Current)"}
                  {option.status === "taken" && " (Already Set)"}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      );
    },
    [getColumnTypeOptions],
  );
  const hasData = useCallback(
    (data: RawDataRow[], columnId: string): boolean => {
      return data.some((row) => {
        const value = row[columnId];
        return (
          value !== undefined &&
          value !== null &&
          value?.trim &&
          value?.trim() !== ""
        );
      });
    },
    [],
  );

  const columns = React.useMemo(() => {
    if (!parsedData.length || !parsedData[0]) {
      return [];
    }
    const headersToOmit = ["supplier_product_name"];
    const headers = Object.keys(parsedData[0]);
    const nonEmptyHeaders = headers.filter(
      (header) =>
        Boolean(header) &&
        hasData(parsedData as unknown as RawDataRow[], header) &&
        !headersToOmit.includes(header),
    );

    const headerColumns: ColumnDef<PriceImport>[] = nonEmptyHeaders.map(
      (header, index) => ({
        id: header || `column${index}`,
        accessorKey: header,
        header: () => (
          <chakra.Flex direction="column" align="stretch" gap={1} width="100%">
            <chakra.Text fontWeight="medium" fontSize="sm">
              {header || `Column ${index}`}
            </chakra.Text>
            <chakra.Text fontSize="xs" color="gray.500">
              {columnConfigs
                .find((c) => c.id === header)
                ?.types.map((type) => {
                  const option = getColumnTypeOption(type);
                  return option ? `${option.icon} ${option.label}` : type;
                })
                .join(" • ")}
            </chakra.Text>
          </chakra.Flex>
        ),
        cell: (info) => {
          const value = info.getValue() as string;

          return (
            <chakra.Tooltip label={value}>
              <chakra.Box
                maxW={isFullscreen ? "350px" : "150px"}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {value}
              </chakra.Box>
            </chakra.Tooltip>
          );
        },
        size: 100 / (headers.length + 2), // +2 to account for product suggestion and price per unit columns
      }),
    );

    // Add product suggestion column after the first column (usually product name)
    const allColumns: ColumnDef<PriceImport>[] = [
      headerColumns[0],
      {
        id: "suggested_product",
        accessorKey: "suggested_product",
        header: "Product",
        cell: (info) => {
          const row = info.row.original;
          const suggestions = row.product_suggestions || [];
          const currentProduct = row.product;
          const suggestedProduct = row.suggested_product;

          return (
            <chakra.Flex gap={2} alignItems="center" maxW="220px">
              <chakra.Select
                color={
                  currentProduct?.id === suggestedProduct?.id
                    ? row.confident_match
                      ? "green.500"
                      : "orange.500"
                    : "black"
                }
                onChange={(e) =>
                  changeSelectedProduct(info.row.index, Number(e.target.value))
                }
                value={currentProduct?.id || ""}
                placeholder="Select product..."
              >
                {suggestions.map((product: Product) => (
                  <option
                    key={product.id}
                    value={product.id}
                    style={{
                      color:
                        product.id === suggestedProduct?.id
                          ? row.confident_match
                            ? "green"
                            : "orange"
                          : "black",
                    }}
                  >
                    {product.name}
                  </option>
                ))}
              </chakra.Select>
              <chakra.Tooltip label="Add new product">
                <chakra.IconButton
                  aria-label="Add new product"
                  icon={<AddIcon />}
                  size="sm"
                  onClick={() => handleAddProductClick(info.row.index)}
                />
              </chakra.Tooltip>
            </chakra.Flex>
          );
        },
        size: 40,
      },
      ...headerColumns.slice(1),
    ];

    return allColumns;
  }, [
    parsedData,
    hasData,
    columnConfigs,
    isFullscreen,
    changeSelectedProduct,
    handleAddProductClick,
  ]);

  // Update pricePerUnitColumn to be more reactive
  // const pricePerUnitColumn = useMemo(
  //   (): ColumnDef<PriceImport> => ({
  //     id: "price_per_base_unit",
  //     header: `Price per ${baseUnit}`,
  //     accessorFn: (row) => {
  //       if (!priceColumnId || !weightColumnId) {
  //         return null;
  //       }

  //       const price = parseFloat(row[priceColumnId as keyof PriceImport] as string);
  //       const weight = parseFloat(row[weightColumnId as keyof PriceImport] as string);

  //       if (isNaN(price) || isNaN(weight) || weight === 0) {
  //         return null;
  //       }

  //       return calculatePricePerBaseUnit(price, weight, baseUnit);
  //     },
  //     cell: (info) => {
  //       const value = info.getValue() as string;
  //       return (
  //         <chakra.Tooltip label={value}>
  //           <chakra.Box
  //             maxW={isFullscreen ? "350px" : "150px"}
  //             overflow="hidden"
  //             textOverflow="ellipsis"
  //             whiteSpace="nowrap"
  //             color={!priceColumnId || !weightColumnId ? "gray.400" : undefined}
  //           >
  //             {value
  //               ? `$${parseFloat(value).toFixed(2)}/${baseUnit}`
  //               : !priceColumnId || !weightColumnId
  //               ? "Set price and weight columns"
  //               : "-"}
  //           </chakra.Box>
  //         </chakra.Tooltip>
  //       );
  //     },
  //     size: 20,
  //   }),
  //   [baseUnit, priceColumnId, weightColumnId, isFullscreen]
  // ); // Add all dependencies

  const handleConfirm = () => {
    if (
      !previewData ||
      headerRow === null ||
      startRow === null ||
      endRow === null
    ) {
      return;
    }

    // Process the selected range of data
    const headers = previewData[headerRow];
    const dataRows = previewData.slice(startRow, endRow + 1);

    // Process the data with the selected range
    const processedData = dataRows.map((row) => {
      const rowData: { [key: string]: string } = {};
      headers.forEach((header, index) => {
        rowData[header] = row[index];
      });
      return rowData;
    });

    // Add supplier_product_name from the product name column if it exists
    const dataWithSupplierNames = processedData.map((row) => ({
      ...row,
      supplier_product_name: productNameColumnId
        ? row[productNameColumnId]
        : "",
    }));

    // Run the product matching
    const matchedData = matchProductsWithPrices(
      dataWithSupplierNames as PriceImport[],
      productNameColumnId || "",
    );

    setParsedData(matchedData);
    setShowColumnMapping(false);
    setFileProcessed(true);
    setCurrentView("preview");
  };

  const handleCancel = () => {
    setShowColumnMapping(false);
    setHeaderRow(0);
    setStartRow(1);
    setEndRow(startRow + 10);
    setPreviewData([]);
  };

  // Add a handler for going back to mapping view
  const handleBackToMapping = () => {
    setCurrentView("mapping");
    setShowColumnMapping(true);
    // Reset selection step to "end" since we already have all the configurations
    setSelectionStep("end");
  };

  // Add selection step state to the parent
  const [selectionStep, setSelectionStep] = useState<
    "header" | "start" | "end"
  >("header");

  const initializeColumnConfigs = useCallback(
    (headerRowIndex: number) => {
      if (previewData.length > 0 && headerRowIndex !== null) {
        const headers = previewData[headerRowIndex];
        const initialConfigs = headers.map((header, index) => ({
          id: header.trim() || `column${index}`,
          types: [] as ColumnType[],
          header: header.trim() || `column${index}`,
        }));
        setColumnConfigs(initialConfigs);
      }
    },
    [previewData],
  );

  return (
    <>
      <chakra.Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size={isFullscreen ? "full" : "5xl"}
      >
        <chakra.ModalOverlay />
        <chakra.ModalContent>
          <chakra.ModalHeader>
            <chakra.Flex alignItems="center">
              <chakra.Stack w="80%" direction="row" spacing={4}>
                <chakra.Heading w="600px">
                  Import Supplier Prices
                </chakra.Heading>
                <chakra.Spacer />
                <SupplierLocationSearch
                  includeSearchIcon
                  setSupplierLocationId={setSelectedSupplierLocationId}
                  setSupplierOrganisationId={setSelectedSupplierOrganisationId}
                  placeholder="Select supplier for prices"
                />
              </chakra.Stack>
              <chakra.Spacer />
              <chakra.Button
                onClick={() => setIsFullscreen(!isFullscreen)}
                size="md"
                variant="outline"
                mr={8}
              >
                {isFullscreen ? (
                  <AiOutlineFullscreenExit />
                ) : (
                  <AiOutlineFullscreen />
                )}
              </chakra.Button>
            </chakra.Flex>
          </chakra.ModalHeader>
          <chakra.ModalCloseButton />
          <chakra.ModalBody>
            {isOpen && (
              <>
                <chakra.Flex gap={4} justifyContent="space-between">
                  {currentView !== "preview" && (
                    <FileUpload
                      onFileRead={handleFileUpload}
                      buttonLabel="Import File"
                      isProcessed={fileProcessed}
                    />
                  )}
                  <chakra.Flex
                    gap={4}
                    borderWidth="1px"
                    borderColor="border.disabled"
                    p={4}
                    borderRadius="md"
                    ml="auto"
                  >
                    <chakra.FormControl w="150px">
                      <chakra.FormLabel>Base Unit</chakra.FormLabel>
                      <chakra.Select
                        value={baseUnit}
                        onChange={(e) => {
                          setBaseUnit(e.target.value);
                          // Force a refresh of the table data
                          setParsedData((prev) => [...prev]);
                        }}
                      >
                        <option value="kg">Kilograms (kg)</option>
                        <option value="lb">Pounds (lb)</option>
                      </chakra.Select>
                    </chakra.FormControl>
                    <chakra.FormControl isRequired w="200px">
                      <chakra.FormLabel>From Date</chakra.FormLabel>
                      <chakra.Input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </chakra.FormControl>
                    <chakra.FormControl w="200px">
                      <chakra.FormLabel>To Date (Optional)</chakra.FormLabel>
                      <chakra.Input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        min={fromDate}
                      />
                    </chakra.FormControl>
                  </chakra.Flex>
                </chakra.Flex>
                {currentView === "mapping" &&
                  showColumnMapping &&
                  previewData.length > 0 && (
                    <chakra.Box mt={4}>
                      <ColumnMappingConfig
                        selectedLocationId={selectedSupplierLocationId}
                        selectedOrganisationId={selectedSupplierOrganisationId}
                        previewData={previewData}
                        setPreviewData={setPreviewData}
                        headerRow={headerRow}
                        onHeaderRowChange={(index) => {
                          initializeColumnConfigs(index);
                          setHeaderRow(index);
                        }}
                        startRow={startRow}
                        onStartRowChange={setStartRow}
                        endRow={endRow}
                        onEndRowChange={setEndRow}
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                        columnTypeSelect={(header: string) => (
                          <ColumnTypeSelect
                            header={header}
                            currentTypes={
                              columnConfigs.find((c) => c.id === header)
                                ?.types || []
                            }
                            onChange={(header, newType) =>
                              handleColumnTypeChange(header, newType)
                            }
                          />
                        )}
                        columnConfigs={columnConfigs}
                        baseUnit={baseUnit}
                        setColumnConfigs={setColumnConfigs}
                        handleColumnTypeChange={handleColumnTypeChange}
                        selectionStep={selectionStep}
                        setSelectionStep={setSelectionStep}
                      />
                    </chakra.Box>
                  )}
                {currentView === "preview" && parsedData.length > 0 && (
                  <chakra.Box mt={4}>
                    <chakra.Flex justify="space-between" mb={4}>
                      <chakra.Button
                        leftIcon={<ArrowBackIcon />}
                        onClick={handleBackToMapping}
                      >
                        Back to Header Selection
                      </chakra.Button>
                    </chakra.Flex>
                    <VirtualizedDataTable
                      data={parsedData}
                      columns={columns}
                      isFullscreen={isFullscreen}
                    />
                    {/* <VirtualizedDataTable data={parsedData} columns={[...columns, pricePerUnitColumn]} isFullscreen={isFullscreen} /> */}
                  </chakra.Box>
                )}
              </>
            )}
          </chakra.ModalBody>
          <chakra.ModalFooter>
            <chakra.Button colorScheme="teal" onClick={onClose}>
              Close
            </chakra.Button>
          </chakra.ModalFooter>
        </chakra.ModalContent>
      </chakra.Modal>
      {isAddProductModalOpen && (
        <AddProductModal
          title={addProductDescription}
          isOpen={isAddProductModalOpen}
          onClose={handleAddProductClose}
          onProductCreated={handleProductCreated}
          initialProductName={initialProductName}
        />
      )}
    </>
  );
};

export default ImportPricesModal;
