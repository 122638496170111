import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  FormControl,
  FormLabel,
  ModalFooter,
  useToast,
  Spacer,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { Product, ProductUnit } from "../../types";
import {
  usePostBarcodeListMutation,
  usePutBarcodeListMutation,
} from "../../redux/apiSlice.ts";
import UnitAndCaseBarcodeScan from "../../feature/StockLevels/UnitAndCaseBarcodeScan.tsx";

interface BarcodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  product: Product | undefined;
}

export const BarcodeModal: React.FC<BarcodeModalProps> = ({
  isOpen,
  onClose,
  product,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const toast = useToast();

  const [unitToParentUnitMap, setUnitToParentUnitMap] = useState<
    Map<number, number | undefined>
  >(new Map());
  const [unitArray, setUnitArray] = useState<(ProductUnit | undefined)[][]>([]);

  useEffect(() => {
    // organise units as unit_id -> parent_unit_id | undefined
    const unitsWithSubUnits = product?.product_units.filter(
      (unit) => unit.sub_unit_id !== null,
    );
    const unitsWithoutSubUnits = product?.product_units.filter(
      (unit) => unit.sub_unit_id === null,
    );
    const map = new Map<number, number | undefined>();
    const unitIdToUnitMap = new Map<number, ProductUnit>();
    unitsWithSubUnits?.forEach((unit) => {
      if (!unit.sub_unit_id) return;
      map.set(unit.sub_unit_id, unit.id);
      unitIdToUnitMap.set(unit.id, unit);
      if (unit.sub_unit) {
        unitIdToUnitMap.set(unit.sub_unit_id, unit.sub_unit);
      }
    });
    unitsWithoutSubUnits?.forEach((unit) => {
      if (map.has(unit.id)) return;
      map.set(unit.id, undefined);
      if (!unitIdToUnitMap.has(unit.id)) {
        unitIdToUnitMap.set(unit.id, unit);
      }
    });
    setUnitToParentUnitMap(map);
    const unitsArray: (Product | undefined)[][] = [];
    map.forEach((parentUnitId, unitId) => {
      const parentUnit = parentUnitId
        ? unitIdToUnitMap.get(parentUnitId)
        : parentUnitId;
      unitsArray.push([unitIdToUnitMap.get(unitId), parentUnit]);
    });
    setUnitArray(unitsArray);
  }, [product]);

  if (!product) {
    toast({
      title: "Error",
      description: "Product not found",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isFullscreen ? "full" : "2xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Flex alignItems="center" justifyContent="space-between">
            <Text>Scan Barcode: {product?.name}</Text>
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="sm"
              variant="outline"
              mt={6}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box
            mb={6}
            p={4}
            borderRadius="md"
            bg="gray.50"
            border="1px"
            borderColor="gray.200"
          >
            <Flex direction="column" gap={2} justifyContent={"space-between"}>
              {unitArray.map(([unit, parentUnit], index) => (
                <UnitAndCaseBarcodeScan
                  product={product}
                  unit={unit}
                  parentUnit={parentUnit}
                  key={index}
                />
              ))}
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Spacer />
          <Button colorScheme="teal" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
