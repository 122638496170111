import React from "react";
import {
  Box,
  HStack,
  Select,
  Text,
  NumberInput,
  NumberInputField,
  Button,
} from "@chakra-ui/react";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getProductUnitLabel } from "../../utils/productUnitUtils.ts";
import { useVirtualizer } from "@tanstack/react-virtual";
import { OrderListItem } from "./OrderListModal.tsx";

interface CreateOrderListProps {
  orderList: OrderListItem[];
  isFullscreen?: boolean;
  handleCachedQuantityChange: (updatedItem: OrderListItem) => void;
  handleSelectedPriceChange: (updatedItem: OrderListItem) => void;
  supplierIdToNameMap: Map<number, string>;
}

const CreateOrderList = ({
  orderList,
  isFullscreen,
  handleCachedQuantityChange,
  handleSelectedPriceChange,
  supplierIdToNameMap,
}: CreateOrderListProps): React.ReactElement => {
  const columnHelper = createColumnHelper<OrderListItem>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnDef<OrderListItem, any>[] = React.useMemo(
    () => [
      columnHelper.accessor("product", {
        cell: (info) => (
          <Text noOfLines={2} title={info.getValue()?.name} align="left">
            {info.getValue()?.name}
          </Text>
        ),
        header: "Product",
        size: isFullscreen ? 350 : 220,
      }),
      columnHelper.accessor("product_unit", {
        cell: (info) => (
          <Text noOfLines={1} title={getProductUnitLabel(info.getValue())}>
            {getProductUnitLabel(info.getValue())}
          </Text>
        ),
        header: "Unit",
        size: isFullscreen ? 240 : 120,
      }),
      columnHelper.accessor("quantity", {
        cell: (info) => {
          const QuantityCell = () => {
            const [localValue, setLocalValue] = React.useState(info.getValue());

            React.useEffect(() => {
              setLocalValue(info.getValue());
            }, []);

            const step = info.row.original.selected_price?.in_multiples_of || 1;

            const handleIncrement = () => {
              const newValue = Number(localValue) + step;
              setLocalValue(newValue);
              handleCachedQuantityChange({
                ...info.row.original,
                quantity: newValue,
                total:
                  newValue *
                  Number(info.row.original.selected_price?.price || 1),
              });
            };

            const handleDecrement = () => {
              const newValue = Math.max(0, Number(localValue) - step);
              setLocalValue(newValue);
              handleCachedQuantityChange({
                ...info.row.original,
                quantity: newValue,
                total:
                  newValue * Number(info.row.original.selected_price.price),
              });
            };

            return (
              <HStack spacing={2}>
                <Button size="sm" onClick={handleDecrement}>
                  -
                </Button>
                <NumberInput
                  value={localValue}
                  precision={2}
                  step={step}
                  min={0}
                  onChange={(valueString) => {
                    setLocalValue(Number(valueString));
                  }}
                  onBlur={() => {
                    const value = Number(localValue);
                    handleCachedQuantityChange({
                      ...info.row.original,
                      quantity: value,
                      total:
                        value * Number(info.row.original.selected_price.price),
                    });
                  }}
                  w="140px"
                  size="lg"
                  onClick={(e) => e.stopPropagation()}
                >
                  <NumberInputField height="48px" />
                </NumberInput>
                <Button size="sm" onClick={handleIncrement}>
                  +
                </Button>
              </HStack>
            );
          };

          return <QuantityCell />;
        },
        header: "Quantity",
        meta: { isNumeric: true },
        size: isFullscreen ? 250 : 200,
      }),
      // columnHelper.accessor("suggested_price", {
      //   header: "Suggested Price",
      //   cell: (info) =>
      //     `$${(info.getValue()?.price || 0).toFixed(
      //       2,
      //     )} (${supplierIdToNameMap.get(
      //       info.getValue()?.supplier_organisation_id,
      //     )})`,
      //   size: isFullscreen ? 340 : 180,
      // }),
      columnHelper.accessor("selected_price", {
        header: "Selected Price",
        cell: (info) => (
          <Select
            name="selected_price"
            onClick={(e) => e.stopPropagation()}
            value={info.getValue()?.id}
            onChange={(e) => {
              const selectedPrice = info.row.original.prices.find(
                (price) => price.id === +e.target.value,
              );
              if (selectedPrice) {
                handleSelectedPriceChange({
                  ...info.row.original,
                  selected_price: selectedPrice,
                  total:
                    Number(selectedPrice.price) *
                    Number(info.row.original.quantity),
                });
              }
            }}
          >
            {info.row.original.prices.map((price) => (
              <option
                key={price.id ? price.id + price.price : price.price}
                value={price.id}
              >
                {`$${price.price.toFixed(2)} (${supplierIdToNameMap.get(
                  price.supplier_organisation_id,
                )} - ${price.in_multiples_of}${
                  info.row.original.product_unit.unit_measure
                } case)`}
              </option>
            ))}
          </Select>
        ),
        size: isFullscreen ? 320 : 180,
      }),
      columnHelper.accessor("total", {
        header: "Total",
        cell: (info) => `$${(info.getValue() || 0).toFixed(2)}`,
        size: isFullscreen ? 224 : 120,
      }),
    ],
    [
      columnHelper,
      isFullscreen,
      handleCachedQuantityChange,
      supplierIdToNameMap,
      handleSelectedPriceChange,
    ],
  );

  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const table = useReactTable({
    data: orderList,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 60, // Increase the row height
    getScrollElement: () => tableContainerRef.current,
    overscan: 5,
  });

  return (
    <>
      <Box
        height={isFullscreen ? "66vh" : "50vh"}
        width="100%"
        borderWidth={1}
        borderColor="gray.200"
        borderRadius="md"
        overflow="auto"
        ref={tableContainerRef}
        boxShadow="sm"
        bg="white"
        position="relative"
      >
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead
            style={{
              position: "sticky",
              top: 0,
              background: "white",
              zIndex: 1,
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                style={{ display: "flex", width: "100%" }}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: `${header.column.getSize()}%`,
                      maxWidth: header.column.columnDef.maxSize,
                      padding: "12px 8px",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "1px solid #e0e0e0",
                      borderBottom: "2px solid #e0e0e0",
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              display: "block",
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: "relative",
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr
                  key={row.id}
                  style={{
                    display: "flex",
                    position: "absolute",
                    transform: `translateY(${virtualRow.start}px)`,
                    width: "100%",
                    height: "60px",
                    backgroundColor:
                      virtualRow.index % 2 === 0 ? "#f8f9fa" : "white",
                    borderBottom: "1px solid #e0e0e0",
                    cursor: "pointer",
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          width: `${cell.column.getSize()}%`,
                          maxWidth: cell.column.columnDef.maxSize,
                          padding: "8px",
                          textAlign: "center",
                          borderRight: "1px solid #e0e0e0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      <HStack p={2} bg="white" boxShadow="md" justifyContent={"flex-end"}>
        <Text fontSize="lg" fontWeight="bold">
          Total: $
          {orderList.reduce((acc, item) => acc + item.total, 0).toFixed(2)}
        </Text>
      </HStack>
    </>
  );
};

export default CreateOrderList;
