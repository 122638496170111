import { Box, Image } from "@chakra-ui/react";
import React from "react";

interface LogoProps {
  width?: number;
  justifyContent?: string;
  onClick?: () => void;
}

export const Logo = ({
  width = 48, // Default width in pixels
  justifyContent = "center",
  onClick,
}: LogoProps): React.ReactElement => (
  <Box display="flex" justifyContent={justifyContent} onClick={onClick}>
    <Image
      width={`${width}px`}
      height={`${width}px`}
      minWidth={`${width}px`}
      src={new URL("../../assets/images/stockyCart.png", import.meta.url).href}
      objectFit="contain"
    />
  </Box>
);
