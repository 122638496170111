import React, { useCallback, useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import { useAppSelector } from "../redux/hooks";
import SalesOrdersView from "../feature/OrdersView/SalesOrdersView";
import { userSlice } from "../redux/userSlice";
import {
  useBulkPatchOrderStatusMutation,
  useGetLocationByIdQuery,
  useGetSalesOrdersQuery,
} from "../redux/apiSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { IoPrint } from "react-icons/io5";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Order } from "../types";
import OrderListPdf from "../feature/PdfPrinter/OrderListPdf";
import { ChevronDownIcon } from "@chakra-ui/icons";
import * as Sentry from "@sentry/react";

function SalesOrdersPage(): React.ReactElement {
  const toast = useToast();
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);

  const navigate = useNavigate();

  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const { data: currentLocation } = useGetLocationByIdQuery(locationId);

  const { data: orders } = useGetSalesOrdersQuery(
    {
      organisationId,
      locationId,
      startDate,
      endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const [bulkPatchOrderStatus, { isLoading: isLoadingPatchOp }] =
    useBulkPatchOrderStatusMutation();

  const [areAllOrdersChecked, setAreAllOrdersChecked] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState<Map<number, boolean>>(
    new Map(),
  );
  const [selectedOrders, setSelectedOrders] = useState<Order[]>();
  const [filterOrders, setFilterOrders] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const initialTab = searchParams.get("tab") || "sales-orders";

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams({ tab: "sales-orders" });
    }
  }, [searchParams, setSearchParams]);

  const getTabIndex = (tab: string) => {
    switch (tab) {
      case "sales-invoices":
        return 1;
      case "sales-orders":
      default:
        return 0;
    }
  };

  const tabIndex = getTabIndex(initialTab);

  const handleTabChange = (index: number) => {
    const newTab = index === 0 ? "sales-orders" : "sales-invoices";
    setSearchParams({ tab: newTab });
  };

  useEffect(() => {
    if (currentLocation && !currentLocation.is_supplier) {
      navigate("/products");
    }
  }, [currentLocation]);

  useEffect(() => {
    const selectedOrders = orders?.filter((order) => {
      return !!checkedOrders.get(order?.id || 0);
    });
    setSelectedOrders(selectedOrders);
  }, [checkedOrders]);

  const formatDateTime = (date: Date) => {
    const pad = (num: number) => (num < 10 ? "0" + num : num);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate(),
    )}_${pad(date.getHours())}-${pad(date.getMinutes())}-${pad(
      date.getSeconds(),
    )}`;
  };

  const currentDateTime = () => formatDateTime(new Date());

  const handleCheckAllOrders = useCallback(
    (value: boolean) => {
      const newCheckedOrders = new Map<number, boolean>();
      let ordersToCheck = orders?.slice();
      if (filterOrders && value) {
        ordersToCheck = ordersToCheck?.filter(
          (order) => order.status !== "DELIVERED",
        );
      }
      ordersToCheck?.forEach((order) => {
        newCheckedOrders.set(order.id || 0, value);
      });
      setCheckedOrders(newCheckedOrders);
    },
    [orders, filterOrders],
  );

  const handleCheckOrder = useCallback((orderId: number, value: boolean) => {
    setCheckedOrders((prevCheckedOrders) => {
      const newCheckedOrders = new Map<number, boolean>(prevCheckedOrders);
      newCheckedOrders.set(orderId, value);
      return newCheckedOrders;
    });
  }, []);

  useEffect(() => {
    let ordersToCheck = orders?.slice();
    if (filterOrders) {
      ordersToCheck = ordersToCheck?.filter(
        (order) => order.status !== "DELIVERED",
      );
    }
    // considered as all checked if each order ID from ordersToCheck is present with true in the checkedOrders map
    const areAllChecked = ordersToCheck?.every(
      (order) => checkedOrders.get(order.id || 0) === true,
    );
    setAreAllOrdersChecked(areAllChecked || false);
  }, [checkedOrders, orders, filterOrders]);

  const handleMarkProcessedAsDelivered = async () => {
    const orderIds = Array.from(checkedOrders.entries())
      .filter((value) => value[1])
      .map(([key]) => key);

    await bulkPatchOrderStatus({
      orderIds,
      status: "DELIVERED",
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Orders marked as delivered",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setCheckedOrders(new Map());
        setAreAllOrdersChecked(false);
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Failed to mark orders as delivered",
          description:
            "Please try again or contact support if the issue persists",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <PageOverview
        heading={"Sales"}
        subheading={startDate && endDate && `${startDate} - ${endDate}`}
        showDateRangePicker={true}
        endElement={
          <>
            {initialTab === "sales-orders" && orders && (
              <Flex
                gap={2}
                alignItems={"end"}
                flexDirection={["column", "row"]}
              >
                <PDFDownloadLink
                  document={<OrderListPdf orders={selectedOrders || []} />}
                  fileName={`order-list-${currentDateTime()}.pdf`}
                >
                  <Tooltip
                    label="Print Packing List"
                    aria-label="Print Packing List"
                  >
                    <IconButton
                      aria-label={"Print Packing List"}
                      icon={<IoPrint />}
                      colorScheme={"teal"}
                      isDisabled={
                        !selectedOrders || selectedOrders?.length === 0
                      }
                    />
                  </Tooltip>
                </PDFDownloadLink>
                <Tooltip label="Options" aria-label="options">
                  <Menu>
                    <MenuButton
                      ml={2}
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      isDisabled={
                        !selectedOrders || selectedOrders?.length === 0
                      }
                    >
                      Actions
                    </MenuButton>
                    <MenuList textColor={"black"}>
                      <MenuItem
                        isDisabled={isLoadingPatchOp}
                        onClick={handleMarkProcessedAsDelivered}
                      >
                        {isLoadingPatchOp ? (
                          <Box
                            w={"100%"}
                            display={"flex"}
                            justifyContent={"center"}
                            justifyItems={"center"}
                          >
                            <Spinner />
                          </Box>
                        ) : (
                          "Mark processed as delivered"
                        )}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Tooltip>
              </Flex>
            )}
          </>
        }
      />
      <SalesOrdersView
        checkedOrders={checkedOrders}
        handleCheckOrder={handleCheckOrder}
        allOrdersChecked={areAllOrdersChecked}
        handleCheckAllOrders={handleCheckAllOrders}
        tabIndex={tabIndex}
        setTabIndex={handleTabChange}
        filterOrders={filterOrders}
        setFilterOrders={setFilterOrders}
      />
    </>
  );
}

export default SalesOrdersPage;
