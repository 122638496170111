import React, { useState, useEffect } from "react";
import { HStack, IconButton } from "@chakra-ui/react";
import StorageAreaSearchOrCreate from "../../components/StorageAreaSearch/StorageAreaSearchOrCreate";
import { useGetStorageAreasQuery } from "../../redux/apiSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import { userSlice } from "../../redux/userSlice.ts";
import { TbSelect } from "react-icons/tb";

interface StorageAreaTabSelectProps {
  storageAreaId: number | null | undefined;
  setStorageAreaId: (id: number | null | undefined) => void;
}

export default function StorageAreaTabSelect({
  storageAreaId,
  setStorageAreaId,
}: StorageAreaTabSelectProps): React.ReactElement {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);
  const {
    data: storageAreas = [],
    error: storageAreasError,
    isLoading: storageAreasLoading,
  } = useGetStorageAreasQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [storageAreaName, setStorageAreaName] = useState<string>(
    "Totals (No Storage Area)",
  );

  useEffect(() => {
    if (storageAreaId) {
      const storageArea = storageAreas.find((sa) => sa.id === storageAreaId);
      if (storageArea) {
        setStorageAreaName(storageArea.name);
      }
    } else {
      setStorageAreaName("Totals (No Storage Area)");
    }
  }, [storageAreaId, storageAreas]);

  const [storageAreaSearchTerm, setStorageAreaSearchTerm] =
    useState<string>("");

  const [showSelect, setShowSelect] = useState(false);
  const handleSelect = (id: number | null | undefined) => {
    if (id !== undefined) {
      setStorageAreaId(id === 0 ? undefined : id);
      setShowSelect(false);
    }
  };

  return (
    <HStack
      onClick={(e) => {
        if (showSelect) e.stopPropagation();
      }}
    >
      {showSelect ? (
        <StorageAreaSearchOrCreate
          selectedStorageAreaId={storageAreaId}
          searchTerm={storageAreaSearchTerm}
          setSearchTerm={setStorageAreaSearchTerm}
          setStorageAreaId={handleSelect}
        />
      ) : (
        <>
          <div>{storageAreaName}</div>
          <IconButton
            pl={4}
            size="xs"
            theme="ghost"
            aria-label={"Select storage area"}
            icon={<TbSelect />}
            onClick={() => setShowSelect(true)}
          />
        </>
      )}
    </HStack>
  );
}
