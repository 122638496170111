import { Td, useDisclosure } from "@chakra-ui/react";
import React from "react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { StorageArea } from "../../types";
import StorageAreaDetailModal from "./StorageAreaDetailModal.tsx";

interface StorageAreaViewEntryProps {
  index: number;
  storageArea: StorageArea;
}

export default function StorageAreaViewEntry({
  index,
  storageArea,
}: StorageAreaViewEntryProps): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && (
        <StorageAreaDetailModal
          isOpen={isOpen}
          onClose={onClose}
          storageArea={storageArea}
        />
      )}
      <InteractiveRow itemIndex={index} onClick={isOpen ? onClose : onOpen}>
        <></>
        <Td fontSize="sm">{storageArea.name}</Td>
      </InteractiveRow>
    </>
  );
}
