import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Address,
  Barcode,
  Container,
  ContainerCostItem,
  ContainerInvoiceJunction,
  DailySales,
  DeliveryAddress,
  EventSalesReconciliation,
  EventSalesReconciliationItem,
  GrowArea,
  Harvest,
  HarvestItem,
  InventoryItem,
  Invoice,
  InvoiceBase,
  Location,
  LocationBase,
  LoginResponse,
  Order,
  OrderBase,
  OrderItem,
  Organisation,
  OrganisationBase,
  Payment,
  PaymentBase,
  PaymentInvoiceJunction,
  PaymentUpdate,
  Price,
  Product,
  ProductUnit,
  ProductUnitBase,
  SeedRecord,
  StockMovement,
  StockTakeEvent,
  StorageArea,
  SupplierLocation,
  TokenResponse,
  Transplant,
  User,
} from "../types";
import { RootState } from "./store";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { setToken, logout } from "./userSlice";
import { Mutex } from "async-mutex";
import * as Sentry from "@sentry/react";

const formOrgLocationUrl = (
  organisationId?: string | number,
  locationId?: string | number,
  isSupplier?: boolean,
) => {
  return `/${isSupplier ? "supplier-" : ""}organisation/${organisationId}${
    locationId ? `/${isSupplier ? "supplier-" : ""}location/${locationId}` : ""
  }`;
};

const addDateFilters = (
  url: string,
  startDate?: string,
  endDate?: string,
  isTime: boolean = false,
) => {
  const hasQueryParams = url.includes("?");
  const paramName = isTime ? "time" : "date";
  const dateFilters =
    startDate && endDate
      ? `start_${paramName}=${startDate}&end_${paramName}=${
          endDate.length > 10 ? endDate : endDate + "T23:59:59"
        }` // Add end of day
      : "";
  return `${url}${hasQueryParams ? "&" : "?"}${dateFilters}`;
};

const addQueriesIfDefined = (url: string, queries: object) => {
  const hasQueryParams = url.includes("?");
  const queryStrings = Object.entries(queries)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`);
  return `${url}${hasQueryParams ? "&" : "?"}${queryStrings.join("&")}`;
};

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.PROD
    ? import.meta.env.VITE_REACT_APP_API_URL ?? "http://localhost:8000"
    : "http://localhost:8000",
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.user.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    Sentry.captureException(
      `Error caught on api request ${args} - error: ${JSON.stringify(
        result.error,
        null,
        2,
      )}`,
    );
    const state = api.getState() as RootState;
    if (
      result.error.status === 401 &&
      state.user.isAuthenticated &&
      !state.user.isLoggingIn
    ) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await baseQuery(
            "/refresh-token",
            api,
            extraOptions,
            // @ts-expect-error - data present
          ).data?.access_token;
          if (refreshResult) {
            api.dispatch(setToken(refreshResult));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            // TODO: raise toast to indicate expired session
            api.dispatch(logout());
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Address",
    "Container",
    "Forecasts",
    "GrowArea",
    "Harvest",
    "InventoryItem",
    "InventoryProduct",
    "Invoice",
    "Order",
    "Payment",
    "PaymentInvoice",
    "Price",
    "Product",
    "ProductUnit",
    "Sales",
    "EventSalesReconciliation",
    "Buyers",
    "Suppliers",
    "SeedRecord",
    "StockMovement",
    "StockTakeEvent",
    "StorageArea",
    "Transplant",
    "Organisations",
    "Locations",
  ],
  endpoints: (builder) => ({
    postRegisterUser: builder.mutation<
      User,
      { email: string; password: string }
    >({
      query: ({ email, password }) => ({
        url: "/register",
        method: "POST",
        body: { email, password },
      }),
    }),
    postUserLogin: builder.mutation<LoginResponse, FormData>({
      query: (formData) => ({
        url: "/login",
        method: "POST",
        body: formData,
        formData: true,
      }),
    }),
    postRefreshToken: builder.mutation<TokenResponse, string>({
      query: () => ({
        url: "/refresh-token",
        method: "POST",
      }),
    }),
    getCurrentUser: builder.query<User, string>({
      query: () => "/current-user",
    }),
    postRequestResetPassword: builder.mutation<User, { email: string }>({
      query: ({ email }) => ({
        url: "/request-reset-password",
        method: "POST",
        body: { email },
      }),
    }),
    postResetPassword: builder.mutation<
      User,
      {
        email: string;
        password: string;
        email_key: string;
        url_key: string;
        token: string;
      }
    >({
      query: ({ email, password, email_key, url_key, token }) => ({
        url: "/reset-password",
        method: "POST",
        body: { email, password, email_key, url_key, token },
      }),
    }),
  }),
});

const orgApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisations: builder.query<Organisation[], string>({
      query: () => "/organisations",
      providesTags: ["Organisations"],
    }),
    getLocations: builder.query<Location[], string>({
      query: () => "/locations",
      providesTags: ["Locations"],
    }),
    getOrganisationLocations: builder.query<
      Location[],
      { organisationId?: string | number }
    >({
      query: ({ organisationId }) =>
        `/organisation/${organisationId}/locations`,
      providesTags: ["Buyers"],
    }),
    getOrganisationById: builder.query<Organisation, string | undefined>({
      query: (organisationId) => `/organisation/${organisationId}`,
    }),
    getLocationById: builder.query<Location, string | undefined>({
      query: (locationId) => `/location/${locationId}`,
    }),
    postBuyingLocation: builder.mutation<
      Location,
      {
        supplierOrganisationId: string | number;
        supplierLocationId: string | number;
        buyerLocation: LocationBase;
      }
    >({
      query: ({
        supplierOrganisationId,
        supplierLocationId,
        buyerLocation,
      }) => ({
        url: `${formOrgLocationUrl(
          supplierOrganisationId,
          supplierLocationId,
        )}/location/buyer`,
        method: "POST",
        body: buyerLocation,
      }),
      invalidatesTags: ["Buyers"],
    }),
    postCustomerOrganisationWithLocation: builder.mutation<
      Organisation,
      {
        organisationId: string | number;
        locationId: string | number;
        customerOrganisation: OrganisationBase;
        customerLocation: LocationBase;
      }
    >({
      query: ({
        organisationId,
        locationId,
        customerOrganisation,
        customerLocation,
      }) => ({
        url: `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/customer-organisation-with-location`,
        method: "POST",
        body: {
          customer_organisation: customerOrganisation,
          customer_location: customerLocation,
        },
      }),
      invalidatesTags: ["Organisations", "Locations", "Buyers"],
    }),
  }),
  overrideExisting: "throw",
});

const supplyChainApi = orgApi.injectEndpoints({
  endpoints: (builder) => ({
    getSuppliers: builder.query<
      Organisation[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/suppliers`,
      providesTags: ["Suppliers"],
    }),
    getSupplierLocations: builder.query<
      Location[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/supplier-locations`,
      providesTags: ["Suppliers"],
    }),
    getBuyers: builder.query<
      Organisation[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/buyers`,
      providesTags: ["Buyers"],
    }),
    getBuyerLocations: builder.query<
      Location[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/buyer-locations`,
      providesTags: ["Buyers"],
    }),
    postBuyerLocationRelation: builder.mutation<
      SupplierLocation,
      {
        organisationId?: string | number;
        locationId?: string | number;
        buyerOrganisationId?: string | number;
        buyerLocationId?: string | number;
      }
    >({
      query: ({
        organisationId,
        locationId,
        buyerOrganisationId,
        buyerLocationId,
      }) => ({
        url: `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/buyer?buyer_organisation_id=${buyerOrganisationId}&buyer_location_id=${buyerLocationId}`,
        method: "POST",
      }),
      invalidatesTags: ["Buyers"],
    }),
    deleteBuyerLocationRelation: builder.mutation<
      SupplierLocation,
      {
        organisationId?: string | number;
        locationId?: string | number;
        buyerOrganisationId?: string | number;
        buyerLocationId?: string | number;
      }
    >({
      query: ({
        organisationId,
        locationId,
        buyerOrganisationId,
        buyerLocationId,
      }) => ({
        url: `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/buyer-organisation/${buyerOrganisationId}/buyer-location/${buyerLocationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Buyers"],
    }),
  }),
  overrideExisting: "throw",
});

const addressApi = supplyChainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAddress: builder.query<Address, string>({
      query: (addressId) => `/address/${addressId}`,
      providesTags: ["Address"],
    }),
    getAddresses: builder.query<Address[], string>({
      query: () => "/addresses",
      providesTags: ["Address"],
    }),
    postAddress: builder.mutation<Address, Address>({
      query: (address) => ({
        url: "/address",
        method: "POST",
        body: address,
      }),
      invalidatesTags: ["Address"],
    }),
    getDeliveryAddresses: builder.query<
      DeliveryAddress[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/delivery-addresses`,
      providesTags: ["Address"],
    }),
    postDeliveryAddress: builder.mutation<DeliveryAddress, DeliveryAddress>({
      query: (address) => ({
        url: "/delivery-address",
        method: "POST",
        body: address,
      }),
      invalidatesTags: ["Address"],
    }),
    deleteDeliveryAddress: builder.mutation<DeliveryAddress, DeliveryAddress>({
      query: (address) => ({
        url: `/delivery-address/${address.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Address"],
    }),
  }),
  overrideExisting: "throw",
});

const productApi = addressApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<Product[], string>({
      query: () => "/products",
      providesTags: ["Product"],
    }),
    postProduct: builder.mutation<Product, Product>({
      query: (product) => ({
        url: `/product`,
        method: "POST",
        body: product,
      }),
      invalidatesTags: [
        "ProductUnit",
        "Product",
        "InventoryItem",
        "StockMovement",
      ],
    }),
    getProductUnits: builder.query<ProductUnit[], string>({
      query: () => "/products/units",
      providesTags: ["ProductUnit"],
    }),
    getUnitsForProduct: builder.query<ProductUnit[], number>({
      query: (productId) => `/product/${productId}/units`,
      providesTags: ["ProductUnit"],
    }),
    postProductUnit: builder.mutation<ProductUnit, ProductUnit>({
      query: (productUnit) => ({
        url: `/product/${productUnit.product_id}/unit`,
        method: "POST",
        body: productUnit,
      }),
      invalidatesTags: [
        "ProductUnit",
        "Product",
        "InventoryItem",
        "StockMovement",
      ],
    }),
    postProductUnitList: builder.mutation<
      ProductUnit[],
      {
        productId: number;
        productUnits: ProductUnitBase[];
      }
    >({
      query: ({ productId, productUnits }) => ({
        url: `/product/${productId}/unit-list`,
        method: "POST",
        body: productUnits,
      }),
      invalidatesTags: [
        "ProductUnit",
        "Product",
        "InventoryItem",
        "StockMovement",
      ],
    }),
    postBarcode: builder.mutation<Product, { barcode: Barcode }>({
      query: ({ barcode }) => ({
        url: `/barcode`,
        method: "POST",
        body: barcode,
      }),
      invalidatesTags: ["Product", "ProductUnit", "InventoryItem"],
    }),
    putBarcode: builder.mutation<Product, { barcode: Barcode }>({
      query: ({ barcode }) => ({
        url: `/barcode/${barcode.id}`,
        method: "PUT",
        body: barcode,
      }),
      invalidatesTags: ["Product", "ProductUnit", "InventoryItem"],
    }),
    postBarcodeList: builder.mutation<Product, { barcodes: Barcode[] }>({
      query: ({ barcodes }) => ({
        url: `/barcode/list`,
        method: "POST",
        body: barcodes,
      }),
      invalidatesTags: ["Product", "ProductUnit", "InventoryItem"],
    }),
    putBarcodeList: builder.mutation<Product, { barcodes: Barcode[] }>({
      query: ({ barcodes }) => ({
        url: `/barcode/list`,
        method: "PUT",
        body: barcodes,
      }),
      invalidatesTags: ["Product", "ProductUnit", "InventoryItem"],
    }),
    getProductUnitByBarcode: builder.query<ProductUnit, string>({
      query: (barcode) => `/barcode/${barcode}/product-unit`,
    }),
    getInventoryItems: builder.query<
      InventoryItem[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/inventory`,
      providesTags: ["InventoryItem"],
    }),
    getInventoryProducts: builder.query<Product[], number | string>({
      query: (organisationId: number | string) =>
        `/organisation/${organisationId}/inventory-products`,
      providesTags: ["InventoryItem"],
    }),
    postInventoryItem: builder.mutation<
      InventoryItem,
      {
        organisationId: string | number;
        inventoryItem: InventoryItem;
      }
    >({
      query: ({ organisationId, inventoryItem }) => ({
        url: `/organisation/${organisationId}/inventory-item`,
        method: "POST",
        body: inventoryItem,
      }),
      invalidatesTags: ["InventoryItem", "StockMovement"],
    }),
    postInventoryItemList: builder.mutation<
      InventoryItem[],
      {
        organisationId: string | number;
        inventoryItems: InventoryItem[];
      }
    >({
      query: ({ organisationId, inventoryItems }) => ({
        url: `/organisation/${organisationId}/inventory-item-list`,
        method: "POST",
        body: inventoryItems,
      }),
      invalidatesTags: ["InventoryItem", "StockMovement"],
    }),
    deleteInventoryItem: builder.mutation<InventoryItem, InventoryItem>({
      query: (inventoryItem) => ({
        url: `/inventory-item/${inventoryItem.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["InventoryItem", "StockMovement"],
    }),
    deleteInventoryItemList: builder.mutation<
      InventoryItem[],
      {
        inventoryItemIds: number[];
      }
    >({
      query: ({ inventoryItemIds }) => ({
        url: "/inventory-item-list",
        method: "DELETE",
        body: inventoryItemIds,
      }),
      invalidatesTags: ["InventoryItem", "StockMovement"],
    }),
    deleteInventoryItemsFromUnitIdList: builder.mutation<
      InventoryItem[],
      {
        organisationId: string | number;
        productUnitIds: number[];
      }
    >({
      query: ({ organisationId, productUnitIds }) => ({
        url: `/organisation/${organisationId}/inventory-item-list-from-unit-id`,
        method: "DELETE",
        body: productUnitIds,
      }),
      invalidatesTags: ["InventoryItem", "StockMovement"],
    }),
    searchProducts: builder.query<Product[], string>({
      query: (searchTerm) => `/products?name__icontains=${searchTerm}`,
    }),
    findExactMatchProduct: builder.query<Product | null, string>({
      query: (searchTerm) => `/products?name=${searchTerm}`,
    }),
  }),
  overrideExisting: "throw",
});

const priceApi = productApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrices: builder.query<Price[], string | number>({
      query: (supplierOrgId: string | number) =>
        `/prices${
          supplierOrgId ? `?supplier_organisation_id=${supplierOrgId}` : ""
        }`,
      providesTags: ["Price"],
    }),
    getPriceListForBuyer: builder.query<
      Price[],
      { supplierOrgId?: string | number; buyerOrgId?: string | number }
    >({
      query: ({ supplierOrgId, buyerOrgId }) =>
        `/prices?supplier_organisation_id=${supplierOrgId}&buyer_organisation_id=${buyerOrgId}`,
      providesTags: ["Price"],
    }),
  }),
  overrideExisting: "throw",
});

const orderApi = priceApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<
      Order[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ organisationId, locationId, startDate, endDate }) =>
        addDateFilters(
          `${formOrgLocationUrl(organisationId, locationId)}/orders`,
          startDate,
          endDate,
        ),
      providesTags: ["Order"],
    }),
    getPurchaseOrderById: builder.query<
      Order,
      {
        orderId: string | number;
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ orderId, organisationId, locationId }) =>
        `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/purchase-order/${orderId}`,
    }),
    getSalesOrderById: builder.query<
      Order,
      {
        orderId: string | number;
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ orderId, organisationId, locationId }) =>
        `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/sales-order/${orderId}`,
    }),
    getSalesOrders: builder.query<
      Order[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ organisationId, locationId, startDate, endDate }) =>
        addDateFilters(
          `${formOrgLocationUrl(organisationId, locationId)}/sales-orders`,
          startDate,
          endDate,
        ),
      providesTags: ["Order"],
    }),
    postOrder: builder.mutation<Order, OrderBase>({
      query: (order) => ({
        url: `/order`,
        method: "POST",
        body: order,
      }),
      invalidatesTags: ["Order", "Invoice", "StockMovement"],
    }),
    postOrderList: builder.mutation<Order[], OrderBase[]>({
      query: (orders) => ({
        url: `/order/list`,
        method: "POST",
        body: orders,
      }),
      invalidatesTags: ["Order", "Invoice", "StockMovement"],
    }),
    putOrderItems: builder.mutation<
      Order,
      { orderId: number; orderItems: OrderItem[] }
    >({
      query: ({ orderId, orderItems }) => ({
        url: `/order/${orderId}/items`,
        method: "PUT",
        body: orderItems,
      }),
      invalidatesTags: ["Order", "Invoice", "StockMovement"],
    }),
    bulkPatchOrderStatus: builder.mutation<
      Response,
      { orderIds: number[]; status: string }
    >({
      query: ({ orderIds, status }) => ({
        url: `/orders/status?status=${status}`,
        method: "PATCH",
        body: orderIds,
      }),
      invalidatesTags: ["Order", "StockMovement"],
    }),
    getInvoices: builder.query<
      Invoice[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ organisationId, locationId, startDate, endDate }) =>
        addDateFilters(
          `${formOrgLocationUrl(organisationId, locationId)}/invoices`,
          startDate,
          endDate,
        ),
      providesTags: ["Invoice", "StockMovement"],
    }),
    getSupplierInvoices: builder.query<
      Invoice[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        buyerOrgId?: string | number;
        buyerLocationId?: string | number;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({
        organisationId,
        locationId,
        buyerOrgId,
        buyerLocationId,
        startDate,
        endDate,
      }) =>
        addQueriesIfDefined(
          addDateFilters(
            `${formOrgLocationUrl(
              organisationId,
              locationId,
            )}/supplier-invoices`,
            startDate,
            endDate,
          ),
          {
            buyer_organisation_id: buyerOrgId,
            buyer_location_id: buyerLocationId,
          },
        ),
      providesTags: ["Invoice"],
    }),
    getPurchaseInvoiceById: builder.query<
      Invoice,
      {
        invoiceId: string | number;
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ invoiceId, organisationId, locationId }) =>
        `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/purchase-invoice/${invoiceId}`,
    }),
    getSupplierInvoiceById: builder.query<
      Invoice,
      {
        invoiceId: string | number;
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ invoiceId, organisationId, locationId }) =>
        `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/supplier-invoice/${invoiceId}`,
    }),
    getCurrentAndOutstandingInvoices: builder.query<
      Invoice[],
      {
        supplierOrganisationId?: string | number;
        supplierLocationId?: string | number;
        startTime: string;
        endTime: string;
        buyerLocationId?: string | number;
      }
    >({
      query: ({
        supplierOrganisationId,
        supplierLocationId,
        startTime,
        endTime,
        buyerLocationId,
      }) =>
        addQueriesIfDefined(
          addDateFilters(
            `${formOrgLocationUrl(
              supplierOrganisationId,
              supplierLocationId,
              true,
            )}/invoices/current-and-outstanding`,
            startTime,
            endTime,
            true,
          ),
          { buyer_location_id: buyerLocationId },
        ),
    }),
    getInvoiceByOrderId: builder.query<Invoice | null, string>({
      query: (orderId) => `/invoice?order_id=${orderId}`,
    }),
    postInvoice: builder.mutation<
      Invoice,
      {
        invoice: Invoice | InvoiceBase;
        isSupplierView?: boolean;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ invoice, isSupplierView }) => ({
        url: `/invoice${isSupplierView ? "?supplier_view=true" : ""}`,
        method: "POST",
        body: invoice,
      }),
      invalidatesTags: ["Invoice", "Order", "Price", "StockMovement"],
    }),
    putInvoice: builder.mutation<Invoice, Invoice>({
      query: (invoice) => ({
        url: `/invoice/${invoice.id}`,
        method: "PUT",
        body: invoice,
      }),
      invalidatesTags: ["Invoice", "Order", "Price", "StockMovement"],
    }),
    postInvoicePdfEmail: builder.mutation<
      void,
      {
        invoiceId: string | number;
        blob: string | ArrayBuffer | null;
        toEmails: string[];
        ccEmails: string[];
        bccEmails: string[];
      }
    >({
      query: ({ invoiceId, blob, toEmails, ccEmails, bccEmails }) => ({
        url: `/invoice/${invoiceId}/email`,
        method: "POST",
        contentType: "application/pdf",
        body: {
          blob,
          to_emails: toEmails,
          cc_emails: ccEmails,
          bcc_emails: bccEmails,
        },
      }),
    }),
    updateOrderCustomer: builder.mutation<
      Order,
      {
        orderId: number;
        customerOrganisationId: number;
        customerLocationId: number;
      }
    >({
      query: ({ orderId, customerOrganisationId, customerLocationId }) => ({
        url: `/order/${orderId}/customer?customer_organisation_id=${customerOrganisationId}&customer_location_id=${customerLocationId}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Order"],
    }),
  }),
  overrideExisting: "throw",
});

const harvestApi = orderApi.injectEndpoints({
  endpoints: (builder) => ({
    getHarvestRecords: builder.query<
      Harvest[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ organisationId, locationId, startDate, endDate }) =>
        addDateFilters(
          `${formOrgLocationUrl(organisationId, locationId)}/harvests`,
          startDate,
          endDate,
        ),
      providesTags: ["Harvest"],
    }),
    postHarvestRecord: builder.mutation<Harvest, Harvest>({
      query: (harvest) => ({
        url: "/harvest",
        method: "POST",
        body: harvest,
      }),
      invalidatesTags: ["Harvest", "StockMovement"],
    }),
    deleteHarvestRecord: builder.mutation<Harvest, Harvest>({
      query: (harvest) => ({
        url: `/harvest/${harvest.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Harvest", "StockMovement"],
    }),
    deleteHarvestRecordItem: builder.mutation<HarvestItem, HarvestItem>({
      query: (harvestItem) => ({
        url: `/harvest/${harvestItem.harvest_id}/item/${harvestItem.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Harvest", "StockMovement"],
    }),
    getHarvestedProducts: builder.query<
      Product[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ organisationId, locationId, startDate, endDate }) =>
        addDateFilters(
          `${formOrgLocationUrl(organisationId, locationId)}/harvests/products`,
          startDate,
          endDate,
        ),
    }),
    exportHarvestRecords: builder.query<
      void,
      {
        organisationId?: string | number;
        locationId?: string | number;
        startDate: string;
        endDate: string;
      }
    >({
      queryFn: async (
        { organisationId, locationId, startDate, endDate },
        _api,
        _extraOptions,
        baseQuery,
      ) => {
        const url = addDateFilters(
          `${formOrgLocationUrl(organisationId, locationId)}/harvests/export`,
          startDate,
          endDate,
        );

        try {
          const response = await baseQuery({
            url,
            responseHandler: (response) => response.blob(),
          });

          if (response.error) {
            return { error: response.error };
          }

          const blob = response.data as void; // hack to allow return without queryFn error
          return { data: blob };
        } catch (error) {
          Sentry.captureException(
            `Error caught on api request ${url} - error: ${JSON.stringify(
              error,
              null,
              2,
            )}`,
          );
          return { error: undefined };
        }
      },
    }),
  }),
  overrideExisting: "throw",
});

const salesApi = harvestApi.injectEndpoints({
  endpoints: (builder) => ({
    getAggregatedDailySales: builder.query<
      DailySales[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startTime: string;
        endTime: string;
      }
    >({
      query: ({ organisationId, locationId, startTime, endTime }) =>
        addDateFilters(
          `${formOrgLocationUrl(
            organisationId,
            locationId,
          )}/sales/aggregated/daily`,
          startTime,
          endTime,
          true,
        ),
    }),
    getAggregatedDailyForecasts: builder.query<
      DailySales[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startTime: string;
        endTime: string;
      }
    >({
      query: ({ organisationId, locationId, startTime, endTime }) =>
        addDateFilters(
          `${formOrgLocationUrl(
            organisationId,
            locationId,
          )}/forecasts/aggregated/daily`,
          startTime,
          endTime,
          true,
        ),
    }),
    getEventSalesReconciliation: builder.query<
      EventSalesReconciliation[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startTime: string;
        endTime: string;
      }
    >({
      query: ({ organisationId, locationId, startTime, endTime }) =>
        addDateFilters(
          `${formOrgLocationUrl(
            organisationId,
            locationId,
          )}/event-sales-reconciliation`,
          startTime,
          endTime,
          true,
        ),
      providesTags: ["EventSalesReconciliation"],
    }),
    postEventSalesReconciliation: builder.mutation<
      EventSalesReconciliation,
      EventSalesReconciliation
    >({
      query: (eventSalesReconciliation) => ({
        url: "/event-sales-reconciliation",
        method: "POST",
        body: eventSalesReconciliation,
      }),
      invalidatesTags: ["EventSalesReconciliation", "StockMovement"],
    }),
    putEventSalesReconciliation: builder.mutation<
      EventSalesReconciliation,
      { event: EventSalesReconciliation; items: EventSalesReconciliationItem[] }
    >({
      query: ({ event, items }) => ({
        url: `/event-sales-reconciliation/${event.id}`,
        method: "PUT",
        body: { ...event, items },
      }),
      invalidatesTags: ["EventSalesReconciliation", "StockMovement"],
    }),
  }),
  overrideExisting: "throw",
});

const containerApi = salesApi.injectEndpoints({
  endpoints: (builder) => ({
    getContainer: builder.query<Container, string>({
      query: (containerId) => `/container/${containerId}`,
    }),
    getContainers: builder.query<
      Container[],
      {
        organisationId?: string | number | null;
        locationId?: string | number | null;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `/containers?organisation_id=${organisationId || null}&location_id=${
          locationId || null
        }`,
      providesTags: ["Container"],
    }),
    postContainer: builder.mutation<Container, Container>({
      query: (container) => ({
        url: "/container",
        method: "POST",
        body: container,
      }),
      invalidatesTags: ["Container"],
    }),
    putContainer: builder.mutation<Container, Container>({
      query: (container) => ({
        url: `/container/${container.id}`,
        method: "PUT",
        body: container,
      }),
      invalidatesTags: ["Container"],
    }),
    deleteContainer: builder.mutation<Container, Container>({
      query: (container) => ({
        url: `/container/${container.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Container"],
    }),
    postContainerCostItem: builder.mutation<
      Container,
      { container: Container; costItem: ContainerCostItem }
    >({
      query: ({ container, costItem }) => ({
        url: `/container/${container.id}/cost-item`,
        method: "POST",
        body: costItem,
      }),
      invalidatesTags: ["Container"],
    }),
    putContainerCostItem: builder.mutation<
      Container,
      { container: Container; costItem: ContainerCostItem }
    >({
      query: ({ container, costItem }) => ({
        url: `/container/${container.id}/cost-item/${costItem.id}`,
        method: "PUT",
        body: costItem,
      }),
      invalidatesTags: ["Container"],
    }),
    deleteContainerCostItem: builder.mutation<
      Container,
      { container: Container; costItem: ContainerCostItem }
    >({
      query: ({ container, costItem }) => ({
        url: `/container/${container.id}/cost-item/${costItem.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Container"],
    }),
    postContainerInvoice: builder.mutation<
      ContainerInvoiceJunction,
      { container: Container; invoice: Invoice }
    >({
      query: ({ container, invoice }) => ({
        url: `/container/${container.id}/invoice`,
        method: "POST",
        body: invoice,
      }),
      invalidatesTags: ["Container"],
    }),
    getContainerInvoices: builder.query<
      ContainerInvoiceJunction[],
      { containerId: string }
    >({
      query: ({ containerId }) => `/container/${containerId}/invoices`,
    }),
    deleteContainerInvoice: builder.mutation<
      ContainerInvoiceJunction,
      { container: Container; invoice: Invoice }
    >({
      query: ({ container, invoice }) => ({
        url: `/container/${container.id}/invoice/${invoice.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Container"],
    }),
  }),
  overrideExisting: "throw",
});

const paymentsApi = containerApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query<
      Payment[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        customerOrgId?: string | number;
        customerLocationId?: string | number;
        paymentDate?: string;
        paymentDateGte?: string;
        paymentDateLte?: string;
      }
    >({
      query: ({
        organisationId,
        locationId,
        customerOrgId,
        customerLocationId,
        paymentDate,
        paymentDateGte,
        paymentDateLte,
      }) =>
        addQueriesIfDefined(
          `${formOrgLocationUrl(organisationId, locationId)}/payments`,
          {
            customer_organisation_id: customerOrgId,
            customer_location_id: customerLocationId,
            payment_date: paymentDate,
            payment_date__gte: paymentDateGte,
            payment_date__lte: paymentDateLte,
          },
        ),
      providesTags: ["Payment"],
    }),
    postPayment: builder.mutation<Payment, PaymentBase>({
      query: (payment) => ({
        url: `/payment`,
        method: "POST",
        body: payment,
      }),
      invalidatesTags: ["Payment", "Invoice", "PaymentInvoice"],
    }),
    putPayment: builder.mutation<Payment, PaymentUpdate>({
      query: (payment) => ({
        url: `/payment/${payment.id}`,
        method: "PUT",
        body: payment,
      }),
      invalidatesTags: ["Payment", "Invoice", "PaymentInvoice"],
    }),
    deletePayment: builder.mutation<Payment, Payment>({
      query: (payment) => ({
        url: `/payment/${payment.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payment", "Invoice", "PaymentInvoice"],
    }),
    getPaymentInvoicesForPayment: builder.query<
      PaymentInvoiceJunction[],
      { paymentId: number }
    >({
      query: ({ paymentId }) => `/payment/${paymentId}/payment-invoices`,
      providesTags: ["PaymentInvoice"],
    }),
    sendUnpaidInvoicesEmail: builder.mutation<
      { message: string },
      {
        organisationId: string | number;
        locationId: string | number;
        buyerOrganisationId: string | number;
        buyerLocationId: string | number;
        toEmails: string[];
        ccEmails: string[];
        bccEmails: string[];
      }
    >({
      query: ({
        organisationId,
        locationId,
        buyerOrganisationId,
        buyerLocationId,
        toEmails,
        ccEmails,
        bccEmails,
      }) => ({
        url: `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/send-unpaid-invoices-email`,
        method: "POST",
        params: {
          buyer_organisation_id: buyerOrganisationId,
          buyer_location_id: buyerLocationId,
        },
        body: {
          to_emails: toEmails,
          cc_emails: ccEmails,
          bcc_emails: bccEmails,
        },
      }),
    }),
    sendAccountStatementEmail: builder.mutation<
      { message: string },
      {
        organisationId: string | number;
        locationId: string | number;
        buyerOrganisationId: string | number;
        buyerLocationId: string | number;
        toEmails: string[];
        ccEmails: string[];
        bccEmails: string[];
        monthStart?: string;
        monthEnd?: string;
        timezoneOffset: number;
      }
    >({
      query: ({
        organisationId,
        locationId,
        buyerOrganisationId,
        buyerLocationId,
        toEmails,
        ccEmails,
        bccEmails,
        monthStart,
        monthEnd,
        timezoneOffset,
      }) => ({
        url: `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/send-monthly-account-statement-email`,
        method: "POST",
        params: {
          buyer_organisation_id: buyerOrganisationId,
          buyer_location_id: buyerLocationId,
        },
        body: {
          to_emails: toEmails,
          cc_emails: ccEmails,
          bcc_emails: bccEmails,
          month_start: monthStart,
          month_end: monthEnd,
          timezone_offset: timezoneOffset,
        },
      }),
    }),
  }),
  overrideExisting: "throw",
});

const crmApi = paymentsApi.injectEndpoints({
  endpoints: (builder) => ({
    postDemoRequest: builder.mutation<
      { message: string },
      {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        businessType: string;
        company: string;
        role: string;
        message: string;
      }
    >({
      query: ({
        firstName,
        lastName,
        email,
        phone,
        businessType,
        company,
        role,
        message,
      }) => ({
        url: "/demo-request",
        method: "POST",
        body: {
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          company,
          company_type: businessType,
          role,
          message,
        },
      }),
    }),
  }),
  overrideExisting: "throw",
});

const growApi = crmApi.injectEndpoints({
  endpoints: (builder) => ({
    postGrowArea: builder.mutation<GrowArea, GrowArea>({
      query: (growArea) => ({
        url: "/grow-area",
        method: "POST",
        body: growArea,
      }),
      invalidatesTags: ["GrowArea"],
    }),
    getGrowAreas: builder.query<
      GrowArea[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/grow-areas`,
      providesTags: ["GrowArea"],
    }),
    postSeedRecord: builder.mutation<SeedRecord, SeedRecord>({
      query: (seedRecord) => ({
        url: "/seed-record",
        method: "POST",
        body: seedRecord,
      }),
      invalidatesTags: ["SeedRecord"],
    }),
    getSeedRecords: builder.query<
      SeedRecord[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/seed-records`,
      providesTags: ["SeedRecord"],
    }),
    postTransplant: builder.mutation<Transplant, Transplant>({
      query: (transplant) => ({
        url: "/transplant",
        method: "POST",
        body: transplant,
      }),
      invalidatesTags: ["Transplant"],
    }),
    getTransplants: builder.query<
      Transplant[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/transplants`,
      providesTags: ["Transplant"],
    }),
  }),
  overrideExisting: "throw",
});

const stockApi = growApi.injectEndpoints({
  endpoints: (builder) => ({
    postStorageArea: builder.mutation<StorageArea, StorageArea>({
      query: (storageArea) => ({
        url: "/storage-area",
        method: "POST",
        body: storageArea,
      }),
      invalidatesTags: ["StorageArea"],
    }),
    getStorageAreas: builder.query<
      StorageArea[],
      {
        organisationId?: string | number;
        locationId?: string | number;
      }
    >({
      query: ({ organisationId, locationId }) =>
        `${formOrgLocationUrl(organisationId, locationId)}/storage-areas`,
      providesTags: ["StorageArea"],
    }),
    postStockTakeEvent: builder.mutation<StockTakeEvent, StockTakeEvent>({
      query: (stockTakeEvent) => ({
        url: "/stock-take-event",
        method: "POST",
        body: stockTakeEvent,
      }),
      invalidatesTags: ["StockMovement", "StockTakeEvent"],
    }),
    putStockTakeEvent: builder.mutation<StockTakeEvent, StockTakeEvent>({
      query: (stockTakeEvent) => ({
        url: `/stock-take-event/${stockTakeEvent.id}`,
        method: "PUT",
        body: stockTakeEvent,
      }),
      invalidatesTags: ["StockMovement", "StockTakeEvent"],
    }),
    getStockTakeEvents: builder.query<
      StockTakeEvent[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ organisationId, locationId, startDate, endDate }) =>
        addDateFilters(
          `${formOrgLocationUrl(organisationId, locationId)}/stock-take-events`,
          startDate,
          endDate,
        ),
      providesTags: ["StockTakeEvent"],
    }),
    deleteStockTakeEvent: builder.mutation<StockTakeEvent, StockTakeEvent>({
      query: (stockTakeEvent) => ({
        url: `/stock-take-event/${stockTakeEvent.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["StockMovement", "StockTakeEvent"],
    }),
    mergeStockTakeEvents: builder.mutation<
      StockTakeEvent,
      {
        organisationId: string | number;
        locationId: string | number;
        stockTakeEventIds: number[];
        deleteMergedEvents: boolean;
      }
    >({
      query: ({
        organisationId,
        locationId,
        stockTakeEventIds,
        deleteMergedEvents,
      }) => ({
        url: `${formOrgLocationUrl(
          organisationId,
          locationId,
        )}/stock-take-events/merge`,
        method: "POST",
        body: {
          stock_take_event_ids: stockTakeEventIds,
          delete_merged_events: deleteMergedEvents,
        },
      }),
      invalidatesTags: ["StockMovement", "StockTakeEvent"],
    }),
    getStockMovement: builder.query<
      StockMovement[],
      {
        organisationId?: string | number;
        locationId?: string | number;
        startDatetime?: string;
        endDatetime?: string;
      }
    >({
      query: ({ organisationId, locationId, startDatetime, endDatetime }) =>
        addQueriesIfDefined(
          `${formOrgLocationUrl(organisationId, locationId)}/stock-movement`,
          {
            start_datetime: startDatetime,
            end_datetime: endDatetime,
          },
        ),
      providesTags: ["StockMovement"],
    }),
  }),
  overrideExisting: "throw",
});

export const {
  usePostRegisterUserMutation,
  usePostRequestResetPasswordMutation,
  usePostResetPasswordMutation,
  usePostUserLoginMutation,
  usePostRefreshTokenMutation,
  useGetCurrentUserQuery,
  useGetOrganisationsQuery,
  useGetLocationsQuery,
  useGetOrganisationLocationsQuery,
  useGetOrganisationByIdQuery,
  useGetLocationByIdQuery,
  usePostBuyingLocationMutation,
  useGetAddressQuery,
  useGetAddressesQuery,
  usePostAddressMutation,
  useGetDeliveryAddressesQuery,
  usePostDeliveryAddressMutation,
  useDeleteDeliveryAddressMutation,
  useGetProductsQuery,
  usePostProductMutation,
  useGetProductUnitsQuery,
  useGetUnitsForProductQuery,
  usePostProductUnitMutation,
  usePostProductUnitListMutation,
  usePostBarcodeMutation,
  usePutBarcodeMutation,
  usePostBarcodeListMutation,
  usePutBarcodeListMutation,
  useGetProductUnitByBarcodeQuery,
  useGetInventoryItemsQuery,
  useGetInventoryProductsQuery,
  usePostInventoryItemMutation,
  usePostInventoryItemListMutation,
  useDeleteInventoryItemMutation,
  useDeleteInventoryItemListMutation,
  useDeleteInventoryItemsFromUnitIdListMutation,
  useSearchProductsQuery,
  useFindExactMatchProductQuery,
  useGetPricesQuery,
  useGetPriceListForBuyerQuery,
  useGetOrdersQuery,
  useGetPurchaseOrderByIdQuery,
  useGetSalesOrderByIdQuery,
  useGetSalesOrdersQuery,
  usePostOrderMutation,
  usePostOrderListMutation,
  usePutOrderItemsMutation,
  useBulkPatchOrderStatusMutation,
  useGetInvoicesQuery,
  useGetPurchaseInvoiceByIdQuery,
  useGetSupplierInvoicesQuery,
  useGetSupplierInvoiceByIdQuery,
  useGetCurrentAndOutstandingInvoicesQuery,
  useGetInvoiceByOrderIdQuery,
  usePostInvoiceMutation,
  usePutInvoiceMutation,
  usePostInvoicePdfEmailMutation,
  useGetHarvestRecordsQuery,
  usePostHarvestRecordMutation,
  useDeleteHarvestRecordMutation,
  useDeleteHarvestRecordItemMutation,
  useGetHarvestedProductsQuery,
  useExportHarvestRecordsQuery,
  useGetAggregatedDailySalesQuery,
  useGetAggregatedDailyForecastsQuery,
  useGetEventSalesReconciliationQuery,
  usePostEventSalesReconciliationMutation,
  usePutEventSalesReconciliationMutation,
  useGetSuppliersQuery,
  useGetSupplierLocationsQuery,
  useGetBuyersQuery,
  useGetBuyerLocationsQuery,
  usePostBuyerLocationRelationMutation,
  useDeleteBuyerLocationRelationMutation,
  usePostCustomerOrganisationWithLocationMutation,
  useGetContainerQuery,
  useGetContainersQuery,
  usePostContainerMutation,
  usePutContainerMutation,
  useDeleteContainerMutation,
  usePostContainerCostItemMutation,
  usePutContainerCostItemMutation,
  useDeleteContainerCostItemMutation,
  usePostContainerInvoiceMutation,
  useGetPaymentsQuery,
  usePostPaymentMutation,
  usePutPaymentMutation,
  useDeletePaymentMutation,
  useGetPaymentInvoicesForPaymentQuery,
  usePostDemoRequestMutation,
  useUpdateOrderCustomerMutation,
  useSendUnpaidInvoicesEmailMutation,
  useSendAccountStatementEmailMutation,
  usePostGrowAreaMutation,
  useGetGrowAreasQuery,
  usePostSeedRecordMutation,
  useGetSeedRecordsQuery,
  usePostTransplantMutation,
  useGetTransplantsQuery,
  usePostStorageAreaMutation,
  useGetStorageAreasQuery,
  useGetStockMovementQuery,
  usePostStockTakeEventMutation,
  usePutStockTakeEventMutation,
  useGetStockTakeEventsQuery,
  useDeleteStockTakeEventMutation,
  useMergeStockTakeEventsMutation,
} = stockApi;
