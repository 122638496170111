import React, { useState, useEffect } from "react";
import {
  Button,
  HStack,
  IconButton,
  Input,
  Td,
  Tr,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import AddUnitSelectField from "../../components/AddUnitSelectField/AddUnitSelectField";
import { BarcodeModal } from "../../components/BarcodeModal/BarcodeModal";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch";
import { useGetInventoryProductsQuery } from "../../redux/apiSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import { userSlice } from "../../redux/userSlice.ts";
import { Product, ProductUnit, StockTake } from "../../types";
import BarcodeProductUnitSearch from "../../components/BarcodeProductUnitSearch/BarcodeProductUnitSearch.tsx";
import { TbFileBarcode } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";

interface StorageAreaTabProps {
  storageAreaId: number | null | undefined;
  stockTakes: StockTake[];
  updateStockTakes: (stockTakes: StockTake[]) => void;
  productId?: number | null | undefined;
}

export default function StorageAreaTabData({
  storageAreaId,
  stockTakes,
  updateStockTakes,
  productId,
}: StorageAreaTabProps): React.ReactNode[] {
  const toast = useToast();
  const [currentStockTakes, setCurrentStockTakes] =
    useState<StockTake[]>(stockTakes);
  const [newStockTake, setNewStockTake] = useState<Partial<StockTake>>({});
  const [currentUnits, setCurrentUnits] = useState<ProductUnit[]>([]);

  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const [selectedProduct, setSelectedProduct] = useState<
    Product | null | undefined
  >(null);
  const [selectedProductId, setSelectedProductId] = useState<
    number | null | undefined
  >(productId);
  const [selectedProductName, setSelectedProductName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [barcodeModalProduct, setBarcodeModalProduct] = useState<
    Product | null | undefined
  >(undefined);

  const { data: products } = useGetInventoryProductsQuery(organisationId || 0, {
    skip: !organisationId,
  });

  const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false);

  useEffect(() => {
    setCurrentStockTakes(stockTakes);
  }, [stockTakes]);

  useEffect(() => {
    if (products && selectedProductId) {
      const currentProduct = products.find(
        (product) => product.id === selectedProductId,
      );
      setSelectedProduct(currentProduct);
      setSelectedProductName(currentProduct?.name || "");
      setSearchTerm(currentProduct?.name || "");
      const units = currentProduct?.product_units || [];
      const sortedUnits = units?.slice().sort((a, b) => {
        // put units with box, case, carton, etc. at the top
        if (a.unit_label === "box") return -1;
        if (b.unit_label === "box") return 1;
        if (a.unit_label === "case") return -1;
        if (b.unit_label === "case") return 1;
        if (a.unit_label === "carton") return -1;
        if (b.unit_label === "carton") return 1;
        else if (a.unit_label === b.unit_label) {
          if (a.unit_measure === b.unit_measure)
            return Number(a.unit_quantity) - Number(b.unit_quantity);
          return a.unit_measure.localeCompare(b.unit_measure);
        }
        return a.unit_label.localeCompare(b.unit_label);
      });
      setCurrentUnits(sortedUnits);
      if (
        newStockTake &&
        (!newStockTake.product || newStockTake.product_id !== selectedProductId)
      ) {
        setNewStockTake({
          ...newStockTake,
          product: currentProduct,
          product_id: selectedProductId,
        });
      }
    } else {
      setCurrentUnits([]);
    }
  }, [selectedProductId, products]);

  useEffect(() => {
    // setCurrentStorageAreaId(storageAreaId);
    setCurrentStockTakes(stockTakes);
  }, [storageAreaId]);

  const handleAddStockTake = () => {
    if (!selectedProductId || !selectedProduct) {
      toast({
        title: "Warning",
        description: "Please select a product before adding a stock take.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (
      newStockTake.product_id &&
      newStockTake.product_unit_id &&
      newStockTake.quantity
    ) {
      const updatedStockTakes = [
        ...currentStockTakes,
        newStockTake as StockTake,
      ];
      setCurrentStockTakes(updatedStockTakes);
      updateStockTakes(updatedStockTakes);
      setNewStockTake({
        product_id: selectedProductId,
        product: selectedProduct,
      });
      setSelectedProductId(null);
      setSelectedProductName("");
      setSearchTerm("");
    } else {
      toast({
        title: "Warning",
        description:
          "Please select a product, unit and quantity before adding a stock take.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditStockTake = (
    index: number,
    updatedStockTake: Partial<StockTake>,
  ) => {
    const updatedStockTakes = currentStockTakes.map((stockTake, i) =>
      i === index ? { ...stockTake, ...updatedStockTake } : stockTake,
    );
    setCurrentStockTakes(updatedStockTakes);
    updateStockTakes(updatedStockTakes);
  };

  const handleDeleteStockTake = (index: number) => {
    const updatedStockTakes = currentStockTakes.filter((_, i) => i !== index);
    setCurrentStockTakes(updatedStockTakes);
    updateStockTakes(updatedStockTakes);
  };

  const [isTablet] = useMediaQuery("(max-width: 1024px)");

  const getProductUnits = (stockTakeProduct: Product | undefined) => {
    return stockTakeProduct === undefined
      ? []
      : stockTakeProduct?.product_units?.length
      ? stockTakeProduct?.product_units
      : products?.find((product) => product.id === stockTakeProduct.id)
          ?.product_units || [];
  };

  return [
    ...stockTakes.map((stockTake, index) => (
      <Tr key={index}>
        <Td p={0}>{stockTake.product?.name}</Td>
        <Td p={0}>
          {stockTake.product_unit ? (
            <AddUnitSelectField
              selectedProductId={stockTake.product?.id}
              selectedProductName={stockTake.product?.name || ""}
              selectedUnitId={stockTake.product_unit.id}
              setSelectedUnitId={(id) =>
                handleEditStockTake(index, {
                  product_unit: getProductUnits(stockTake.product).find(
                    (unit) => unit.id === id,
                  ),
                })
              }
              units={getProductUnits(stockTake.product)}
              addUnitsEnabled={false}
              setUnits={() => {}}
            />
          ) : (
            "Error on unit"
          )}
        </Td>
        <Td p={2} maxWidth={"120px"}>
          <Input
            maxWidth={"120px"}
            value={stockTake.lot_code}
            onChange={(e) =>
              handleEditStockTake(index, { lot_code: e.target.value })
            }
          />
        </Td>
        <Td p={2}>
          <Input
            size={isTablet ? "sm" : "md"}
            type="date"
            maxWidth={isTablet ? "130px" : "auto"}
            value={stockTake.expiry_date || ""}
            onChange={(e) =>
              handleEditStockTake(index, { expiry_date: e.target.value })
            }
          />
        </Td>
        <Td p={2} maxWidth={isTablet ? "60px" : "120px"}>
          <Input
            maxWidth={isTablet ? "60px" : "120px"}
            type="number"
            value={stockTake.quantity}
            onChange={(e) =>
              handleEditStockTake(index, {
                quantity: Number(e.target.value),
              })
            }
          />
        </Td>
        <Td p={2}>
          <IconButton
            size="sm"
            aria-label="Delete stock take"
            icon={<MdDeleteForever />}
            onClick={() => handleDeleteStockTake(index)}
          />
          <IconButton
            size="sm"
            aria-label="Add barcodes"
            icon={<TbFileBarcode />}
            onClick={() => {
              setBarcodeModalProduct(
                products?.find((p) => p.id === stockTake.product_id),
              );
              setIsBarcodeModalOpen(true);
            }}
          />
        </Td>
      </Tr>
    )),
    <Tr>
      {isBarcodeModalOpen && barcodeModalProduct && (
        <BarcodeModal
          isOpen={isBarcodeModalOpen}
          onClose={() => setIsBarcodeModalOpen(false)}
          onScan={() => {}}
          product={barcodeModalProduct}
          productName={selectedProduct?.name || ""}
          productUnit={newStockTake.product_unit}
        />
      )}
      <Td p={0}>
        <HStack>
          <BarcodeProductUnitSearch />
          <InventoryProductSearch
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setProductFilterId={setSelectedProductId}
            setProductName={setSelectedProductName}
          />
        </HStack>
      </Td>
      <Td p={0}>
        <AddUnitSelectField
          selectedProductId={newStockTake.product_id}
          selectedProductName={selectedProductName}
          selectedUnitId={newStockTake.product_unit_id}
          setSelectedUnitId={(id) =>
            setNewStockTake({
              ...newStockTake,
              product_unit_id: id,
              product_unit: currentUnits.find((unit) => unit.id === id),
            })
          }
          units={currentUnits}
          setUnits={setCurrentUnits}
        />
      </Td>
      <Td p={0} maxWidth={"120px"}>
        <Input
          maxWidth={"120px"}
          value={newStockTake.lot_code || ""}
          onChange={(e) =>
            setNewStockTake({
              ...newStockTake,
              lot_code: e.target.value,
            })
          }
        />
      </Td>
      <Td p={0}>
        <Input
          size={isTablet ? "sm" : "md"}
          type="date"
          maxWidth={isTablet ? "130px" : "auto"}
          value={newStockTake.expiry_date || ""}
          onChange={(e) =>
            setNewStockTake({
              ...newStockTake,
              expiry_date: e.target.value,
            })
          }
        />
      </Td>
      <Td maxWidth={"60px"} p={0}>
        <Input
          maxWidth={"60px"}
          type="number"
          value={newStockTake.quantity || ""}
          onChange={(e) =>
            setNewStockTake({
              ...newStockTake,
              quantity: Number(e.target.value),
            })
          }
        />
      </Td>
      <Td p={isTablet ? 2 : "auto"}>
        <HStack maxW={"60px"}>
          <Button colorScheme="teal" size="sm" onClick={handleAddStockTake}>
            +
          </Button>
          <IconButton
            size="sm"
            aria-label="Add barcodes"
            icon={<TbFileBarcode />}
            onClick={() => {
              setBarcodeModalProduct(selectedProduct);
              setIsBarcodeModalOpen(true);
            }}
            isDisabled={!selectedProduct}
          />
        </HStack>
      </Td>
    </Tr>,
  ];
}
