import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import StackEntry from "../../components/StackEntry/StackEntry";
import { StorageArea } from "../../types";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import { usePostStorageAreaMutation } from "../../redux/apiSlice.ts";

interface StorageAreaDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  storageArea?: StorageArea;
}

const StorageAreaDetailModal = ({
  isOpen,
  onClose,
  storageArea,
}: StorageAreaDetailModalProps): React.ReactElement => {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const [postStorageArea, { isLoading }] = usePostStorageAreaMutation();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const [name, setName] = useState<string>(storageArea?.name || "");

  useEffect(() => {
    setName(storageArea?.name || "");
  }, [storageArea]);

  const handleSave = () => {
    const payload = {
      location_id: Number(locationId),
      organisation_id: Number(organisationId),
      name,
    } as StorageArea;
    postStorageArea(payload)
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Storage Area created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Error - failed to save storage area",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isFullscreen ? "full" : "5xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent height="100vh" margin={{ base: 0, md: "auto" }}>
        <ModalCloseButton />
        <ModalHeader padding={{ base: 4, md: 6 }}>
          <Flex alignItems="center">
            <Heading>Storage Area: {name}</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={8}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody
          flex="1"
          overflow="auto"
          display="flex"
          flexDirection="column"
          px={{ base: 4, md: 6 }}
        >
          <Box pt={4}>
            <Flex direction={["column", "row"]} gap={4}>
              <StackEntry mb={8}>
                <FormLabel>Storage Area Name</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </StackEntry>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter flexDirection="column" width="100%" px={4} py={6}>
          <Button
            colorScheme="teal"
            onClick={handleSave}
            isLoading={isLoading}
            isDisabled={Boolean(!name)}
            flex={{ base: 1, sm: "0 1 auto" }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default StorageAreaDetailModal;
