import React, { useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import { useGetInventoryProductsQuery } from "../redux/apiSlice";
import { Badge, HStack, Td, useDisclosure } from "@chakra-ui/react";
import DataTabs from "../components/DataTabs/DataTabs";
import { Product } from "../types";
import { getProductUnitLabel } from "../utils/productUnitUtils";
import ProductViewEntry from "../feature/ProductView/ProductViewEntry";
import AddProductModal from "../feature/ProductView/AddProductModal";
import InventoryProductSearch from "../components/InventoryProductSearch/InventoryProductSearch";
import Page from "../components/Page/Page";
import { skipToken } from "@reduxjs/toolkit/query";

export const badgeColors = [
  "blue",
  "green",
  "orange",
  "purple",
  "teal",
  "pink",
  "red",
  "cyan",
];

function ProductsPage(): React.ReactElement {
  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const [productList, setProductList] = useState<Product[]>([]);
  const [productFilterId, setProductFilterId] = useState<
    number | null | undefined
  >(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: inventoryProducts,
    isLoading: productsLoading,
    error,
  } = useGetInventoryProductsQuery(organisationId ?? skipToken);

  useEffect(() => {
    if (inventoryProducts) {
      let filteredProducts = inventoryProducts
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));

      if (productFilterId) {
        filteredProducts = filteredProducts.filter(
          (product) => product.id === productFilterId,
        );
      }
      setProductList(filteredProducts);
    }
  }, [inventoryProducts, productFilterId]);

  return (
    <>
      <PageOverview heading={"Products"} subheading={""} />
      <Page isLoading={productsLoading} isError={Boolean(error)}>
        <HStack>
          <InventoryProductSearch
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setProductFilterId={setProductFilterId}
            includeSearchIcon
          />
        </HStack>
        <AddProductModal isOpen={isOpen} onClose={onClose} />
        <DataTabs
          showButton={true}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          data={[
            {
              label: "Products",
              headers: ["Name", "Units"],
              entries: productList
                ? productList.map((product, index) => (
                    <ProductViewEntry
                      key={index}
                      itemIndex={index}
                      product={product}
                    >
                      <Td fontSize="sm">{product.name}</Td>
                      <Td fontSize="sm">
                        {product.product_units.map((unit, unitIndex) => (
                          <Badge
                            colorScheme={badgeColors[unitIndex % 8]}
                            fontSize="0.8em"
                            key={unit.id}
                            marginRight="0.5rem"
                          >
                            {getProductUnitLabel(unit)}
                          </Badge>
                        ))}
                      </Td>
                    </ProductViewEntry>
                  ))
                : [],
            },
          ]}
        />
      </Page>
    </>
  );
}

export default ProductsPage;
