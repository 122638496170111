import React, { useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import StorageAreaSearchOrCreate from "../../components/StorageAreaSearch/StorageAreaSearchOrCreate.tsx";

interface SelectStorageAreaModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddStorageAreaCount: (storageAreaId: number | null) => void;
}

const SelectStorageAreaModal = ({
  isOpen,
  onClose,
  handleAddStorageAreaCount,
}: SelectStorageAreaModalProps): React.ReactElement => {
  const [storageAreaSearchTerm, setStorageAreaSearchTerm] =
    useState<string>("");
  const [storageAreaId, setStorageAreaId] = useState<number | null | undefined>(
    undefined,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent height="100vh" margin={{ base: 0, md: "auto" }}>
        <ModalCloseButton />
        <ModalHeader padding={{ base: 4, md: 6 }}>
          <Flex alignItems="center">
            <Heading size={"md"}>Select Storage Area</Heading>
            <Spacer />
          </Flex>
        </ModalHeader>
        <ModalBody
          flex="1"
          overflow="auto"
          display="flex"
          flexDirection="column"
          px={{ base: 4, md: 6 }}
        >
          <StorageAreaSearchOrCreate
            selectedStorageAreaId={storageAreaId}
            searchTerm={storageAreaSearchTerm}
            setSearchTerm={setStorageAreaSearchTerm}
            setStorageAreaId={setStorageAreaId}
          />
        </ModalBody>
        <ModalFooter flexDirection="row" width="100%" px={4} py={6}>
          <Button
            colorScheme="red"
            variant="outline"
            onClick={onClose}
            flex={{ base: 1, sm: "0 1 auto" }}
          >
            Cancel
          </Button>
          <Spacer />
          <Button
            colorScheme="teal"
            onClick={() => {
              if (storageAreaId === undefined) return;
              handleAddStorageAreaCount(storageAreaId);
            }}
            flex={{ base: 1, sm: "0 1 auto" }}
            isDisabled={storageAreaId === undefined}
          >
            Add Storage Area Count
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectStorageAreaModal;
