import { format } from "date-fns";
import { CalendarDate } from "../components/Calendar";

export const formatISODate = (date: Date | CalendarDate): string => {
  return format(date, "yyyy-MM-dd");
};

export const getLocalDate = (dateString: any): Date => {
  let [year, month, day] = dateString.split("-").map(Number);
  // Note: JavaScript counts months from 0 to 11. January is 0, February is 1, and so on.
  return new Date(year, month - 1, day);
};

export const monthDiff = (d1: Date, d2: Date): number => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const formatDateTimeString = (datetime: string) => {
  // format datetime string to be more readable, from 2024-11-24T17:18:45.975000 to 2024-11-24 17:18
  return datetime.replace("T", " ").split(".")[0].slice(0, -3);
};

export const formatDateTimeStringToLocal = (datetime: string) => {
  // format datetime string to be more readable, from 2024-11-24T17:18:45.975000 to 2024-11-24 17:18
  // also adjust to local time
  const localTime = new Date(datetime);
  const offset = localTime.getTimezoneOffset();
  localTime.setMinutes(localTime.getMinutes() - offset);
  return format(localTime, "yyyy-MM-dd HH:mm");
};

export const addTimezoneOffset = (datetime: string) => {
  const localTime = new Date(datetime);
  const offset = localTime.getTimezoneOffset();
  localTime.setMinutes(localTime.getMinutes() + offset);
  return localTime.toISOString();
};
