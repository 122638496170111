import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useMediaQuery,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import CreateOrderList from "./CreateOrderList.tsx";
import React, { useEffect, useState } from "react";
import {
  Order,
  OrderBase,
  OrderItem,
  Price,
  Product,
  ProductUnit,
} from "../../types.ts";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch.tsx";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import {
  useGetInventoryProductsQuery,
  useGetSuppliersQuery,
  usePostOrderListMutation,
} from "../../redux/apiSlice.ts";
import { skipToken } from "@reduxjs/toolkit/query";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import OrderAggregator from "./OrderAggregator.tsx";

interface OrderListModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export type OrderListItem = {
  id: number;
  product_id: number;
  product: Product;
  product_unit_id: number;
  product_unit: ProductUnit;
  quantity: number | string;
  suggested_price: Price;
  selected_price: Price;
  prices: Price[];
  total: number;
};

const OrderListModal = ({
  isOpen,
  onClose,
}: OrderListModalProps): React.ReactElement => {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId, getUserId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);
  const userId = useAppSelector(getUserId);

  const { data: products, isLoading: productsLoading } =
    useGetInventoryProductsQuery(organisationId ?? skipToken);

  const { data: suppliers, isLoading: suppliersLoading } = useGetSuppliersQuery(
    {
      organisationId,
      locationId,
    },
  );

  const [postOrderList, { isLoading: isPostingOrderList }] =
    usePostOrderListMutation();

  const [supplierIdToNameMap, setSupplierIdToNameMap] = useState<
    Map<number, string>
  >(
    suppliers
      ? new Map(suppliers.map((supplier) => [supplier.id, supplier.name]))
      : new Map(),
  );

  const [cachedOrderList, setCachedOrderList] = React.useState<OrderListItem[]>(
    [],
  );
  const [orderList, setOrderList] = React.useState<OrderListItem[]>([]);
  const [availablePrices, setAvailablePrices] = React.useState<
    Map<number, { suggested: Price; available: Price[] }>
  >(new Map());

  const [selectedProductId, setSelectedProductId] = useState<
    number | null | undefined
  >(null);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const steps = [
    { title: "First", description: "Quantities & Pricing" },
    { title: "Second", description: "Confirm Orders" },
  ];

  const [sectionOpen, setSectionOpen] = React.useState(true);
  const [isStepOneValid, setIsStepOneValid] = useState<boolean>(false);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [isMobile] = useMediaQuery("(max-width: 478px)");
  const [isFullscreen, setIsFullscreen] = useState<boolean>(true);

  const [orders, setOrders] = React.useState<OrderBase[]>([]);

  useEffect(() => {
    if (
      locationId &&
      organisationId &&
      cachedOrderList &&
      cachedOrderList.length > 0
    ) {
      const supplierIdToOrderItemsMap = new Map<number, OrderItem[]>();
      cachedOrderList.forEach((item) => {
        if (item.quantity || Number(item.quantity) !== 0) {
          const newOrderItem = {
            product_id: item.product_id,
            product: item.product,
            product_unit_id: item.product_unit_id,
            product_unit: item.product_unit,
            quantity: item.quantity,
            price: item.selected_price.price,
            total: item.total,
          } as OrderItem;
          const currentOrderItems = supplierIdToOrderItemsMap.get(
            item.selected_price.supplier_organisation_id,
          );
          if (currentOrderItems) {
            supplierIdToOrderItemsMap.set(
              item.selected_price.supplier_organisation_id,
              [...currentOrderItems, newOrderItem],
            );
          } else {
            supplierIdToOrderItemsMap.set(
              item.selected_price.supplier_organisation_id,
              [newOrderItem],
            );
          }
        }
      });
      const newOrders = Array.from(supplierIdToOrderItemsMap.entries()).map(
        ([supplierId, orderItems]) => {
          return {
            supplier_organisation_id: supplierId,
            location_id: Number(locationId),
            organisation_id: organisationId,
            user_id: userId,
            order_items: orderItems,
            order_date: new Date().toISOString(),
            // TODO: replace with Supplier delivery date
            delivery_date: new Date(
              Date.now() + Math.random() * 7 * 24 * 60 * 60 * 1000,
            ).toISOString(),
            total: orderItems.reduce((acc, cur) => acc + Number(cur.total), 0),
            is_pickup: false,
          } as Order;
        },
      );
      setOrders(newOrders);
    }
  }, [cachedOrderList]);

  useEffect(() => {
    if (suppliers && suppliers.length) {
      const supplierMap = new Map<number, string>();
      suppliers.forEach((supplier) => {
        supplierMap.set(supplier.id, supplier.name);
      });
      setSupplierIdToNameMap(supplierMap);
    }
  }, [suppliers]);

  useEffect(() => {
    if (products && products.length) {
      const prices = new Map<
        number,
        { suggested: Price; available: Price[] }
      >();
      for (const product of products) {
        if (product.name === "" || product.category !== "PRODUCE") {
          continue;
        }
        for (const productUnit of product.product_units) {
          // randomly generate prices - price should be between 0.5 and 2.30 per lb
          const productUnitPrices = suppliers?.map(
            (supplier) =>
              ({
                price: Math.random() * 1.8 + 0.5,
                id: 1,
                product_id: product.id,
                product_unit_id: productUnit.id,
                supplier_organisation_id: supplier.id,
                in_multiples_of:
                  productUnit.unit_quantity > 1
                    ? productUnit.unit_quantity
                    : Math.floor(Math.random() * 8) * 2 + 24,
              }) as Price,
          );
          if (productUnitPrices && productUnitPrices.length) {
            const suggestedPrice = productUnitPrices?.reduce((prev, current) =>
              prev.price < current.price ? prev : current,
            );
            if (suggestedPrice) {
              prices.set(productUnit.id, {
                suggested: suggestedPrice,
                available: productUnitPrices,
              });
            }
          }
        }
      }
      setAvailablePrices(prices);
    }
  }, [products, suppliers]);

  useEffect(() => {
    if (products && products.length) {
      const newOrderList = products
        .slice()
        .filter(
          (product) =>
            product.name.length > 0 && product.category === "PRODUCE",
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((product) => {
          // find the first 1lb unit, default to the first unit if not found
          let productUnit = product.product_units.find(
            (unit) =>
              unit.unit_measure === "lb" && Number(unit.unit_quantity) === 1,
          );
          if (!productUnit) {
            productUnit = product.product_units.find(
              (unit) => unit.unit_measure === "lb",
            );
          }
          if (!productUnit) {
            productUnit = product.product_units[0];
          }
          const priceInfo = availablePrices.get(productUnit.id);
          return {
            id: product.id,
            product_id: product.id,
            product,
            product_unit_id: productUnit.id,
            product_unit: productUnit,
            quantity: 0,
            suggested_price: priceInfo?.suggested,
            selected_price: priceInfo?.suggested,
            //TODO: remove this logic once we have BE implementation to handle the correct id for the price object
            prices:
              priceInfo?.available
                .sort((a, b) => a.price - b.price)
                .map((price, index) => ({
                  ...price,
                  id: (price?.id || 1) + index,
                })) || [],
            total: 0,
          } as OrderListItem;
        });
      setCachedOrderList(newOrderList);
      setOrderList(newOrderList);
    }
  }, [products, availablePrices, isOpen, onClose]);

  useEffect(() => {
    if (selectedProductId) {
      setOrderList(
        cachedOrderList.filter((item) => item.product_id === selectedProductId),
      );
    } else if (searchTerm) {
      setOrderList(
        cachedOrderList.filter((item) =>
          item.product.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setOrderList(cachedOrderList);
    }
  }, [selectedProductId, cachedOrderList]);

  useEffect(() => {
    // valid if at least one quantity has been set
    setIsStepOneValid(
      Boolean(
        orderList.find((item) => item.quantity !== 0 && item.quantity !== "0"),
      ),
    );
  }, [orderList]);

  const handleCachedQuantityChange = (updatedItem: OrderListItem) => {
    if (updatedItem.quantity) {
      const newItems = cachedOrderList.map((item) =>
        item.product_unit_id === updatedItem.product_unit_id
          ? updatedItem
          : item,
      );
      setCachedOrderList(newItems);
    }
  };

  const handleCachedSelectedPriceChange = (updatedItem: OrderListItem) => {
    if (updatedItem.selected_price) {
      const newItems = cachedOrderList.map((item) =>
        item.product_unit_id === updatedItem.product_unit_id
          ? updatedItem
          : item,
      );
      setCachedOrderList(newItems);
    }
  };

  const handleSectionChange = (index: number) => {
    if (index !== activeStep) {
      setSectionOpen(false);
      setTimeout(() => {
        setActiveStep(index);
        setSectionOpen(true);
      }, 400);
    }
  };

  const nextStep = () => {
    if (activeStep < steps.length) {
      handleSectionChange(activeStep + 1);
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      handleSectionChange(activeStep - 1);
    }
  };

  const handleChangeStep = (index: number) => {
    if (index !== activeStep) {
      if (index > activeStep) {
        nextStep();
      } else {
        prevStep();
      }
    }
  };

  const handleSave = () => {
    if (isPostingOrderList) return;
    postOrderList(orders.filter((order) => order.order_items.length > 0))
      .unwrap()
      .then(() => {
        toast({
          title: "Order list created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        setActiveStep(0);
        const quantityResetItems = cachedOrderList.map((item) => ({
          ...item,
          quantity: 0,
        }));
        setCachedOrderList(quantityResetItems);
        setOrderList([]);
      })
      .catch(() => {
        toast({
          title: "Failed to create order list.",
          description: "Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleTransferOrders = (
    fromSupplierId: number,
    toSupplierId: number,
    orderItems: OrderItem[],
  ) => {
    setOrders((prevOrders) => {
      let newOrders = prevOrders.map((order) => {
        if (order.supplier_organisation_id === fromSupplierId) {
          return {
            ...order,
            order_items: order.order_items.filter(
              (item) =>
                !orderItems.some(
                  (transferItem) => transferItem.product_id === item.product_id,
                ),
            ),
            total:
              order.total -
              orderItems.reduce((acc, item) => acc + item.total, 0),
          };
        }
        return order;
      });

      const existingToOrder = newOrders.find(
        (order) => order.supplier_organisation_id === toSupplierId,
      );

      if (existingToOrder) {
        newOrders = newOrders.map((order) => {
          if (order.supplier_organisation_id === toSupplierId) {
            return {
              ...order,
              order_items: [...order.order_items, ...orderItems],
              total:
                order.total +
                orderItems.reduce((acc, item) => acc + item.total, 0),
            };
          }
          return order;
        });
      } else {
        // Create a new order for the supplier if it doesn't exist
        const newOrder: OrderBase = {
          supplier_organisation_id: toSupplierId,
          location_id: Number(locationId),
          organisation_id: Number(organisationId),
          user_id: userId,
          order_items: orderItems,
          order_date: new Date().toISOString(),
          delivery_date: new Date(
            Date.now() + Math.random() * 7 * 24 * 60 * 60 * 1000,
          ).toISOString(),
          total: orderItems.reduce((acc, item) => acc + item.total, 0),
          is_pickup: false,
        };
        newOrders.push(newOrder);
      }

      return newOrders;
    });
  };

  const handleRemoveOrderItemFromOrderItems = (orderItem: OrderItem) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => ({
        ...order,
        order_items: order.order_items.filter(
          (item) => item.product_id !== orderItem.product_id,
        ),
      })),
    );
  };

  const handleEditOrderItemQuantity = (orderItem: OrderItem) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => ({
        ...order,
        order_items: order.order_items.map((item) =>
          item.product_id === orderItem.product_id ? orderItem : item,
        ),
      })),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isFullscreen ? "full" : "5xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center">
            <Heading>Create Order List</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={8}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stepper
            index={activeStep}
            colorScheme={"teal"}
            width={"100%"}
            gap={0}
            marginBottom={"16px"}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            {steps.map((step, index) => (
              <Step key={step.title} onClick={() => handleChangeStep(index)}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <Box flexShrink="0" ml={[4, 0]}>
                  <StepTitle>
                    <Text fontSize={{ base: "sm", md: "md" }}>
                      {step.title}
                    </Text>
                  </StepTitle>
                  <StepDescription>
                    <Text fontSize={{ base: "xxs", md: "sm" }}>
                      {step.description}
                    </Text>
                  </StepDescription>
                </Box>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          <Collapse in={sectionOpen} animateOpacity>
            {activeStep === 0 && (
              <InventoryProductSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setProductFilterId={setSelectedProductId}
                includeSearchIcon
              />
            )}
            <Box pt={4}>
              {activeStep === 0 ? (
                <>
                  {productsLoading || suppliersLoading ? (
                    <Spinner />
                  ) : (
                    <CreateOrderList
                      orderList={orderList || []}
                      isFullscreen={isFullscreen}
                      handleCachedQuantityChange={handleCachedQuantityChange}
                      handleSelectedPriceChange={
                        handleCachedSelectedPriceChange
                      }
                      supplierIdToNameMap={supplierIdToNameMap}
                    />
                  )}
                </>
              ) : (
                <OrderAggregator
                  isFullscreen={isFullscreen}
                  orders={orders}
                  supplierIdToNameMap={supplierIdToNameMap}
                  onTransferOrders={handleTransferOrders}
                  onRemoveOrderItem={handleRemoveOrderItemFromOrderItems}
                  onEditOrderItemQuantity={handleEditOrderItemQuantity}
                />
              )}
            </Box>
          </Collapse>
        </ModalBody>
        <ModalFooter flexDirection="column" width="100%" px={4} py={6}>
          <Flex
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={2}
          >
            <Button
              colorScheme="gray"
              onClick={prevStep}
              flex={{ base: "1 1 100%", sm: "0 1 auto" }}
              mb={{ base: 2, sm: 0 }}
            >
              Back
            </Button>
            <Flex
              gap={2}
              flex={{ base: "1 1 100%", sm: "0 1 auto" }}
              justifyContent={{ base: "space-between", sm: "flex-end" }}
            >
              {activeStep < steps.length - 1 ? (
                <Button
                  onClick={nextStep}
                  isDisabled={!isStepOneValid}
                  flex={{ base: 1, sm: "0 1 auto" }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  colorScheme="teal"
                  onClick={handleSave}
                  isLoading={isPostingOrderList}
                  flex={{ base: 1, sm: "0 1 auto" }}
                >
                  Submit
                </Button>
              )}
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderListModal;
