import React, { useRef } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Box } from "@chakra-ui/react";

interface VirtualizedDataTableProps<T> {
  data: T[];
  columns: ColumnDef<T, unknown>[];
  isFullscreen?: boolean;
  getRowStyles?: (index: number) => React.CSSProperties;
  onRowClick?: (index: number) => void;
  height?: string;
}

function VirtualizedDataTable<T>({
  data,
  columns,
  isFullscreen = false,
  getRowStyles,
  onRowClick,
  height,
}: VirtualizedDataTableProps<T>): React.ReactElement {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 60,
    getScrollElement: () => tableContainerRef.current,
    overscan: 5,
  });

  return (
    <Box
      height={height || (isFullscreen ? "66vh" : "50vh")}
      width="100%"
      borderWidth={1}
      borderColor="gray.200"
      borderRadius="md"
      overflow="auto"
      ref={tableContainerRef}
      boxShadow="sm"
      bg="white"
      position="relative"
    >
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead
          style={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 1,
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} style={{ display: "flex", width: "100%" }}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    width: `${header.column.getSize()}%`,
                    maxWidth: header.column.columnDef.maxSize,
                    padding: "12px 8px",
                    fontWeight: "bold",
                    textAlign: "center",
                    borderRight: "1px solid #e0e0e0",
                    borderBottom: "2px solid #e0e0e0",
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          style={{
            display: "block",
            height: `${rowVirtualizer.getTotalSize()}px`,
            position: "relative",
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index];
            return (
              <tr
                key={row.id}
                style={{
                  display: "flex",
                  position: "absolute",
                  transform: `translateY(${virtualRow.start}px)`,
                  width: "100%",
                  height: "60px",
                  borderBottom: "1px solid #e0e0e0",
                  cursor: "pointer",
                  ...(getRowStyles
                    ? getRowStyles(virtualRow.index)
                    : {
                        backgroundColor:
                          virtualRow.index % 2 === 0 ? "#f8f9fa" : "white",
                      }),
                }}
                onClick={() => onRowClick?.(virtualRow.index)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      width: `${cell.column.getSize()}%`,
                      maxWidth: cell.column.columnDef.maxSize,
                      padding: "8px",
                      textAlign: "center",
                      borderRight: "1px solid #e0e0e0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
}

export default VirtualizedDataTable;
