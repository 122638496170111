import React, { useCallback, useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import AddStockTakeModal from "../feature/StockLevels/AddStockTakeModal.tsx";
import StorageAreaDetailModal from "../feature/InventorySettingsView/StorageAreaDetailModal.tsx";
import DataTabs from "../components/DataTabs/DataTabs.tsx";
import InventoryProductSearch from "../components/InventoryProductSearch/InventoryProductSearch.tsx";
import StockLevelViewEntry from "../feature/StockLevels/StockLevelViewEntry.tsx";
import StockTakeEventViewEntry from "../feature/StockLevels/StockTakeEventViewEntry.tsx";
import StorageAreaViewEntry from "../feature/InventorySettingsView/StorageAreaViewEntry.tsx";
import Page from "../components/Page/Page.tsx";
import { userSlice } from "../redux/userSlice.ts";
import { useAppSelector } from "../redux/hooks.ts";
import {
  useGetStockMovementQuery,
  useGetStockTakeEventsQuery,
  useGetStorageAreasQuery,
  useMergeStockTakeEventsMutation,
} from "../redux/apiSlice.ts";
import {
  ProductUnit,
  StockInfo,
  StockMovement,
  StockTakeEvent,
} from "../types.ts";
import {
  formatProductQuantity,
  getProductUnitLabel,
} from "../utils/productUnitUtils.ts";
import { formatDateTimeString } from "../utils/dateUtils.ts";
import * as Sentry from "@sentry/react";

function StockPage(): React.ReactElement {
  const toast = useToast();
  const { getCurrentLocation, getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const currentLocation = useAppSelector(getCurrentLocation);
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const [isProducer, setIsProducer] = React.useState<boolean>(false);

  useEffect(() => {
    if (currentLocation) {
      setIsProducer(currentLocation.is_producer);
    }
  }, [currentLocation]);

  // const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  // const startDate = useAppSelector(getStartDate);
  // const endDate = useAppSelector(getEndDate);

  const [tabIndex, setTabIndex] = React.useState(0);

  const {
    data: stockMovement = [],
    isLoading: stockMovementLoading,
    error: stockMovementError,
  } = useGetStockMovementQuery(
    {
      organisationId,
      locationId,
      // startDatetime: startDate,
      // endDatetime: endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const {
    data: stockTakeEvents = [],
    isLoading: stockTakeEventsLoading,
    error: stockTakeEventsError,
  } = useGetStockTakeEventsQuery(
    {
      organisationId,
      locationId,
      // startDatetime: startDate,
      // endDatetime: endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const {
    data: storageAreas = [],
    isLoading: storageAreasLoading,
    error: storageAreasError,
  } = useGetStorageAreasQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [mergeStockTakeEvents, { isLoading: isLoadingMergeStockTakes }] =
    useMergeStockTakeEventsMutation();

  const [selectedProductId, setSelectedProductId] = useState<
    number | null | undefined
  >(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [stockInfoData, setStockInfoData] = React.useState<StockInfo[]>([]);

  useEffect(() => {
    if (stockMovement && stockMovement.length > 0) {
      setStockInfoData(
        stockMovement
          .slice()
          .sort((a, b) => {
            // sort by product name followed by unit measure
            if (a.product_name < b.product_name) return -1;
            if (a.product_name > b.product_name) return 1;
            if (a.unit_measure < b.unit_measure) return -1;
            if (a.unit_measure > b.unit_measure) return 1;
            return 0;
          })
          .filter(
            (movement: StockMovement) =>
              selectedProductId
                ? movement.product_id === selectedProductId
                : movement.product_name !== "" &&
                  movement.product_name !== "Abeer Vegetable Ghee", // TODO: Remove this line after demo
          )
          .map(
            (movement: StockMovement) =>
              ({
                product: movement.product_name,
                product_id: movement.product_id,
                unit: getProductUnitLabel({
                  unit_label: movement.unit_label,
                  unit_quantity: movement.unit_quantity,
                  unit_measure: movement.unit_measure,
                } as ProductUnit),
                last_count_date: `${formatProductQuantity(
                  movement.opening_stock.counted,
                ).toString()} (${
                  movement.opening_stock.last_stock_take_time
                    ? formatDateTimeString(
                        movement.opening_stock.last_stock_take_time,
                      )
                    : "Never counted"
                })`,
                current:
                  Number(movement.opening_stock.counted) +
                  Number(movement.closing_stock.balance),
                opening: movement.opening_stock,
                sold: movement.closing_stock.sold,
                purchased: movement.closing_stock.purchased,
                wasted: movement.closing_stock.wasted,
                harvested: movement.closing_stock.harvested,
                unit_movements: movement.unit_movements,
              }) as StockInfo,
          ),
      );
    }
  }, [stockMovement, selectedProductId]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [checkedStockTakes, setCheckedStockTakes] = useState<
    Map<number, boolean>
  >(new Map());
  const [selectedStockTakes, setSelectedStockTakes] =
    useState<StockTakeEvent[]>();

  const handleCheckStockTakeEvent = useCallback(
    (stockTakeId: number, value: boolean) => {
      setCheckedStockTakes((prevCheckedStockTakes) => {
        const newCheckedStockTakes = new Map<number, boolean>(
          prevCheckedStockTakes,
        );
        newCheckedStockTakes.set(stockTakeId, value);
        return newCheckedStockTakes;
      });
    },
    [],
  );

  useEffect(() => {
    const selectedStockTakeEvents = stockTakeEvents?.filter((stockTake) => {
      return !!checkedStockTakes.get(stockTake?.id || 0);
    });
    setSelectedStockTakes(selectedStockTakeEvents);
  }, [checkedStockTakes]);

  const handleMergeStockTakeEvents = (deleteMergedEvents: boolean = false) => {
    if (!selectedStockTakes || selectedStockTakes.length < 2) {
      toast({
        title: "Warning",
        description: "Please select at least 2 stock takes to merge.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!locationId) {
      toast({
        title: "Error",
        description: "Location not defined",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      Sentry.captureException("Location not defined in merge stock takes");
      return;
    }
    if (!organisationId) {
      toast({
        title: "Error",
        description: "Organisation not defined",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      Sentry.captureException("Organisation not defined in merge stock takes");
      return;
    }
    mergeStockTakeEvents({
      organisationId,
      locationId,
      stockTakeEventIds: selectedStockTakes.map((s) => s.id),
      deleteMergedEvents,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Merged stock takes",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((e) => {
        toast({
          title: "Error",
          description: "Error merging stock takes",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        try {
          Sentry.captureException(JSON.stringify(e));
        } catch {
          Sentry.captureException(e);
        }
      });
  };

  return (
    <>
      <PageOverview
        heading={"Stock"}
        // subheading={startDate && endDate && `${startDate} - ${endDate}`}
        // showDateRangePicker={true}
        endElement={
          <>
            {tabIndex === 1 && (
              <Flex
                gap={2}
                alignItems={"end"}
                flexDirection={["column", "row"]}
              >
                <Tooltip label="Options" aria-label="options">
                  <Menu>
                    <MenuButton
                      ml={2}
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      isDisabled={
                        !selectedStockTakes || selectedStockTakes?.length === 0
                      }
                    >
                      Actions
                    </MenuButton>
                    <MenuList textColor={"black"}>
                      <MenuItem
                        isDisabled={isLoadingMergeStockTakes}
                        onClick={() => handleMergeStockTakeEvents(false)}
                      >
                        {isLoadingMergeStockTakes ? (
                          <Box
                            w={"100%"}
                            display={"flex"}
                            justifyContent={"center"}
                            justifyItems={"center"}
                          >
                            <Spinner />
                          </Box>
                        ) : (
                          "Merge Stock Takes"
                        )}
                      </MenuItem>
                      <MenuItem
                        isDisabled={isLoadingMergeStockTakes}
                        onClick={() => handleMergeStockTakeEvents(true)}
                      >
                        {isLoadingMergeStockTakes ? (
                          <Box
                            w={"100%"}
                            display={"flex"}
                            justifyContent={"center"}
                            justifyItems={"center"}
                          >
                            <Spinner />
                          </Box>
                        ) : (
                          "Merge Stock Takes & Delete Originals"
                        )}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Tooltip>
              </Flex>
            )}
          </>
        }
      />
      <Page
        isLoading={
          stockMovementLoading || stockTakeEventsLoading || storageAreasLoading
        }
        isError={Boolean(
          stockMovementError || stockTakeEventsError || storageAreasError,
        )}
      >
        {isOpen && (
          <>
            {tabIndex === 1 ? (
              <AddStockTakeModal isOpen={isOpen} onClose={onClose} />
            ) : (
              tabIndex === 2 && (
                <StorageAreaDetailModal isOpen={isOpen} onClose={onClose} />
              )
            )}
          </>
        )}
        <DataTabs
          onChange={(tab: number) => setTabIndex(tab)}
          onOpen={onOpen}
          onClose={onClose}
          showButton={tabIndex !== 0}
          searchElements={
            <>
              {tabIndex === 0 && (
                <Stack
                  spacing={2}
                  direction={["column", "column", "row"]}
                  width="100%"
                  minW="100px"
                >
                  <InventoryProductSearch
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setProductFilterId={setSelectedProductId}
                    includeSearchIcon
                  />
                </Stack>
              )}
            </>
          }
          data={[
            {
              label: "Stock Levels",
              isLoading: Boolean(stockMovementLoading),
              headers: isProducer
                ? [
                    "Product",
                    "Unit",
                    "Last Counted",
                    // "Prev Balance",
                    "Sold Qty",
                    "Purchased Qty",
                    "Wasted Qty",
                    "Harvested Qty",
                    "Current Qty",
                  ]
                : [
                    "Product",
                    "Unit",
                    "Last Counted",
                    // "Prev Balance",
                    "Sold Qty",
                    "Purchased Qty",
                    "Wasted Qty",
                    "Current Qty",
                  ],
              entries: stockInfoData
                ? stockInfoData
                    .slice()
                    .map((stockInfo, index) => (
                      <StockLevelViewEntry
                        index={index}
                        stockInfo={stockInfo}
                        isProducer={isProducer}
                      />
                    ))
                : [],
            },
            {
              label: "Stock Takes",
              buttonLabel: "Add Stock Take",
              isLoading: stockTakeEventsLoading,
              headers: [
                "",
                "Date",
                "Type",
                "Status",
                "Storage Areas",
                "Delete",
              ],
              entries: stockTakeEvents
                ? stockTakeEvents
                    .slice()
                    .sort((a, b) => {
                      // Sort by date in descending order
                      return (
                        new Date(b.start_time).getTime() -
                        new Date(a.start_time).getTime()
                      );
                    })
                    .map((stockTakeEvent, index) => (
                      <StockTakeEventViewEntry
                        index={index}
                        stockTakeEvent={stockTakeEvent}
                        storageAreas={storageAreas}
                        isChecked={
                          checkedStockTakes.get(stockTakeEvent.id) || false
                        }
                        setChecked={(value: boolean) =>
                          handleCheckStockTakeEvent(stockTakeEvent.id, value)
                        }
                      />
                    ))
                : [],
            },
            {
              label: "Storage Areas",
              buttonLabel: "Storage Area",
              isLoading: storageAreasLoading,
              headers: ["Name"],
              entries: storageAreas
                ? storageAreas
                    .slice()
                    .map((storageArea, index) => (
                      <StorageAreaViewEntry
                        index={index}
                        storageArea={storageArea}
                      />
                    ))
                : [],
            },
          ]}
        />
      </Page>
    </>
  );
}

export default StockPage;
