import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  IconButton,
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Spacer,
  Heading,
  useMediaQuery,
} from "@chakra-ui/react";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { CellContext } from "@tanstack/react-table";
import { Barcode } from "../../types";
import { ProductUnitMgr, ProductUnitSettings } from "./types";
import { ImBarcode } from "react-icons/im";

interface BarcodeInputProps {
  info: CellContext<ProductUnitSettings, Barcode | undefined>;
  productUnitMgr: ProductUnitMgr;
  onChange?: (updatedProductUnitMgr: ProductUnitMgr) => void;
}

export const BarcodeInput = React.memo(
  ({ info, productUnitMgr, onChange }: BarcodeInputProps) => {
    const [localValue, setLocalValue] = React.useState(
      info.getValue()?.code || "",
    );
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [stopStream, setStopStream] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [dimensions, setDimensions] = useState({ width: 300, height: 300 });

    // Update local value if external value changes
    useEffect(() => {
      setLocalValue(info.getValue()?.code || "");
    }, [info.getValue()?.code]);

    // Add window resize handler
    useEffect(() => {
      const updateDimensions = () => {
        if (isFullscreen) {
          const width = Math.min(window.innerWidth * 0.9, 1080); // Max width of 1280px
          const height = Math.min(window.innerHeight * 0.7, 720); // Max height of 720px
          setDimensions({ width, height });
        } else {
          setDimensions({ width: 300, height: 300 });
        }
      };

      updateDimensions();
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }, [isFullscreen]);

    const handleChange = (newValue: string) => {
      setLocalValue(newValue);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (!info.getValue() || info.getValue()?.code.length === 0) {
          productUnitMgr.addProductUnitBarcode(info.row.original, newValue);
        } else {
          productUnitMgr.updateProductUnitBarcode(info.row.original, newValue);
        }
        onChange?.(productUnitMgr);
      }, 1000);
    };

    const handleScan = (error: string | null, result: string | null) => {
      if (result) {
        handleChange(result);
        setStopStream(true);
        onClose();
        setTimeout(() => setStopStream(false), 100);
      }
    };

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const [isTablet] = useMediaQuery("(max-width: 1024px)");

    return (
      <>
        <HStack width="100%">
          <Input
            value={localValue}
            onChange={(e) => handleChange(e.target.value)}
            width="100%"
            minWidth="200px"
          />
          <IconButton
            size={isTablet ? "sm" : "md"}
            aria-label="Scan barcode"
            icon={<ImBarcode />}
            onClick={onOpen}
            flexShrink={0}
          />
        </HStack>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size={isFullscreen ? "full" : "xl"}
        >
          <ModalOverlay />
          <ModalContent height={isFullscreen ? "100vh" : "auto"}>
            <ModalCloseButton />
            <ModalHeader>
              <Flex alignItems="center">
                <Heading size="md">Scan Barcode</Heading>
                <Spacer />
                <Button
                  onClick={() => setIsFullscreen(!isFullscreen)}
                  size="md"
                  variant="outline"
                  mr={8}
                >
                  {isFullscreen ? (
                    <AiOutlineFullscreenExit />
                  ) : (
                    <AiOutlineFullscreen />
                  )}
                </Button>
              </Flex>
            </ModalHeader>
            <ModalBody
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {!stopStream && (
                <BarcodeScannerComponent
                  width={dimensions.width}
                  height={dimensions.height}
                  onUpdate={handleScan}
                  torch={false}
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  },
);

BarcodeInput.displayName = "BarcodeInput";
