import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import { ProductUnit } from "../../types";
import { usePostProductUnitMutation } from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";

interface AddUnitSelectFieldProps {
  focusId?: string;
  selectedProductId: number | undefined | null;
  selectedProductName: string;
  selectedUnitId: number | null | undefined;
  setSelectedUnitId: (value: number) => void;
  units: ProductUnit[];
  setUnits: (value: ProductUnit[]) => void;
  addUnitsEnabled?: boolean;
}
const AddUnitSelectField = ({
  focusId,
  selectedProductId,
  selectedProductName,
  selectedUnitId,
  setSelectedUnitId,
  units,
  setUnits,
  addUnitsEnabled = true,
}: AddUnitSelectFieldProps): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [postProductUnit, { isLoading: isPostingProductUnit }] =
    usePostProductUnitMutation();

  const handleAddProductUnit = () => {
    const productUnitPayload = {
      product_id: selectedProductId,
      unit_label: unitLabel,
      unit_measure: unitMeasure,
      unit_quantity: unitQuantity,
      unit_system: unitSystem,
    } as ProductUnit;

    if (!isPostingProductUnit) {
      postProductUnit(productUnitPayload)
        .unwrap()
        .then((response) => {
          toast({
            title: "Unit created.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setUnits([...units, response]);
          setSelectedUnitId(response.id);
          setTimeout(() => {
            onClose();
          }, 1000);
        })
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          toast({
            title: "Unit failed to create.",
            description:
              "Please check your inputs and try again. If the issue persists please contact support.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const [unitLabel, setUnitLabel] = useState<string>("");
  const [unitQuantity, setUnitQuantity] = useState<number>(1);
  const [unitMeasure, setUnitMeasure] = useState<string | undefined>("lb");
  const [unitSystem, setUnitSystem] = useState<string | undefined>(
    "IMPERIAL_US",
  );

  useEffect(() => {
    setUnitSystem(undefined);
    setUnitQuantity(1);
    setUnitLabel("");
    setUnitMeasure("lb");
    setUnitSystem("IMPERIAL_US");
  }, [onOpen, onClose]);

  const handleMeasureChange = (value: string) => {
    setUnitMeasure(value);
    if (["oz", "lb"].includes(value)) {
      setUnitSystem("IMPERIAL_US");
    } else if (value === "unit") {
      setUnitSystem("QUANTITY");
    } else {
      setUnitSystem("METRIC");
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add item unit</ModalHeader>
          <ModalBody>
            <HStack>
              <FormControl>
                <FormLabel>Product</FormLabel>
                <Text>{selectedProductName}</Text>
              </FormControl>
            </HStack>
            <HStack marginTop={"2rem"}>
              <FormControl>
                <FormLabel>Unit label</FormLabel>
                <Input
                  placeholder={"Unit label..."}
                  onChange={(e) => setUnitLabel(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Unit quantity</FormLabel>
                <NumberInput
                  defaultValue={1}
                  min={0.00001}
                  onChange={(e) => setUnitQuantity(Number(e))}
                >
                  <NumberInputField placeholder="Quantity" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Unit measure</FormLabel>
                <Select onChange={(e) => handleMeasureChange(e.target.value)}>
                  <option value="lb">lb</option>
                  <option value="oz">oz</option>
                  <option value="kg">kg</option>
                  <option value="g">g</option>
                  <option value="unit">each</option>
                </Select>
              </FormControl>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={() => handleAddProductUnit()}
              isLoading={isPostingProductUnit}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Select
        id={focusId}
        placeholder="Select unit..."
        focusBorderColor="brand.400"
        rounded="md"
        value={selectedUnitId || ""}
        onChange={(e) => {
          if (e.target.value === "add unit") {
            onOpen();
          }
          setSelectedUnitId(Number(e.target.value));
        }}
      >
        {units.map((productUnit: any) => (
          <option key={productUnit.id} value={productUnit.id}>
            {getProductUnitLabel(productUnit)}
          </option>
        ))}
        {selectedProductId && addUnitsEnabled && (
          <option value={"add unit"}>+ Add unit</option>
        )}
      </Select>
    </>
  );
};

export default AddUnitSelectField;
