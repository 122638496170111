import { createIcon } from "@chakra-ui/icons";

export const ScanIcon = createIcon({
  displayName: "ScanIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M4 6h2v2H4V6m14 2h2V6h-2v2M4 18h2v-2H4v2m16-2v2h2v-2h-2M3 4v4h2V4h4V2H5c-1.1 0-2 .9-2 2m18 0c0-1.1-.9-2-2-2h-4v2h4v4h2V4M3 20v-4H1v4c0 1.1.9 2 2 2h4v-2H3m18 0h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4z"
    />
  ),
});
