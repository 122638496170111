import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Switch,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { userSlice } from "../../redux/userSlice.ts";
import { useAppSelector } from "../../redux/hooks.ts";
import StorageAreaCount from "./StorageAreaCount";
import {
  StockTake,
  StockTakeEvent,
  StockTakeEventType,
  StockTakeStatus,
} from "../../types";
import {
  useGetStorageAreasQuery,
  usePostStockTakeEventMutation,
} from "../../redux/apiSlice.ts";
import * as Sentry from "@sentry/react";
import { format } from "date-fns";
import DataTabs from "../../components/DataTabs/DataTabs.tsx";
import StorageAreaTabData from "./StorageAreaTabData.tsx";
import StorageAreaTabSelect from "./StorageAreaTabSelect.tsx";
import SelectStorageAreaModal from "./SelectStorageAreaModal.tsx";

interface StockTakeModalProps {
  isOpen: boolean;
  onClose: () => void;
  productId?: number | null;
  stockTakeEvent?: StockTakeEvent;
}

const AddStockTakeModal = ({
  isOpen,
  onClose,
  productId,
  stockTakeEvent,
}: StockTakeModalProps): React.ReactElement => {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const [stockTakeDate, setStockTakeDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd HH:mm"),
  );
  const [stockTakeType, setStockTakeType] = useState<StockTakeEventType>(
    StockTakeEventType.PARTIAL,
  );

  const [stockTakesByStorageArea, setStockTakesByStorageArea] = useState<
    { storageAreaId: number | null; stockTakes: StockTake[] }[]
  >([{ storageAreaId: null, stockTakes: [] }]);

  const [postStockTakeEvent, { isLoading }] = usePostStockTakeEventMutation();

  const {
    data: storageAreas = [],
    error: storageAreasError,
    isLoading: storageAreasLoading,
  } = useGetStorageAreasQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const addStorageAreaCount = (storageAreaId: number | null) => {
    if (stockTakesByStorageArea[0].storageAreaId === null) {
      toast({
        title: "Warning",
        description:
          "Please select a storage area for the first count before adding additional storage areas.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    // Check if storage area already exists
    if (
      stockTakesByStorageArea.find(
        (stockTakeSet) => stockTakeSet.storageAreaId === storageAreaId,
      )
    ) {
      toast({
        title: "Warning",
        description: "Count for this storage area already exists",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setStockTakesByStorageArea([
      ...stockTakesByStorageArea,
      { storageAreaId, stockTakes: [] },
    ]);
    onAddStorageAreaClose();
  };

  const updateStockTakes = (index: number, stockTakes: StockTake[]) => {
    const updatedStockTakesByStorageArea = [...stockTakesByStorageArea];
    updatedStockTakesByStorageArea[index].stockTakes = stockTakes;
    setStockTakesByStorageArea(updatedStockTakesByStorageArea);
  };

  const updateStorageAreaId = (
    index: number,
    storageAreaId: number | null | undefined,
  ) => {
    if (!storageAreaId) {
      // check if the user already has a total count
      const totalIndex = stockTakesByStorageArea.findIndex(
        (stockTakeSet) => stockTakeSet.storageAreaId === null,
      );
      if (totalIndex !== -1 && index !== totalIndex) {
        toast({
          title: "Warning",
          description:
            "Total count already exists - please remove it before selecting a storage area",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      } else {
        storageAreaId = null;
      }
    }
    const updatedStockTakesByStorageArea = [...stockTakesByStorageArea];
    updatedStockTakesByStorageArea[index].storageAreaId = storageAreaId;
    setStockTakesByStorageArea(updatedStockTakesByStorageArea);
  };

  const deleteStorageAreaCount = (index: number) => {
    const updatedStockTakesByStorageArea = [...stockTakesByStorageArea];
    if (updatedStockTakesByStorageArea.length === 1) {
      toast({
        title: "Warning",
        description:
          "At least one storage area count is required - if no area is selected, this will account for overall totals",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    updatedStockTakesByStorageArea.splice(index, 1);
    setStockTakesByStorageArea(updatedStockTakesByStorageArea);
  };

  const handleSave = (status: StockTakeStatus) => {
    if (isLoading || !locationId || !organisationId) {
      return;
    }
    // check for at least one entry in each storage area
    const countOfStockEntries = stockTakesByStorageArea.reduce(
      (acc, stockTakeSet) => acc + stockTakeSet.stockTakes.length,
      0,
    );

    if (stockTakesByStorageArea.length === 0 || countOfStockEntries === 0) {
      toast({
        title: "Warning",
        description: "You must add counts to submit a stock take",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const stockTakes = stockTakesByStorageArea
      .map(
        (stockTakeSet) =>
          stockTakeSet.stockTakes.map((stockTake) => ({
            ...stockTake,
            location_id: Number(locationId),
            organisation_id: Number(organisationId),
            storage_area_id: stockTakeSet.storageAreaId,
            record_time: stockTakeDate,
          })) as StockTake[],
      )
      .flat();
    const payload = {
      id: 0,
      location_id: Number(locationId),
      organisation_id: Number(organisationId),
      status: status,
      event_type: stockTakeType,
      start_time: stockTakeDate,
      stock_takes: stockTakes,
    } as StockTakeEvent;
    postStockTakeEvent(payload)
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Stock take created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Error",
          description: "Failed to create stock take",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (stockTakeEvent) {
      setStockTakeDate(stockTakeEvent.start_time);
      const stockTakesByStorageArea = stockTakeEvent.stock_takes.reduce(
        (acc, stockTake) => {
          const storageAreaId = stockTake.storage_area_id;
          const storageAreaIndex = acc.findIndex(
            (stockTakeSet) => stockTakeSet.storageAreaId === storageAreaId,
          );
          if (storageAreaIndex === -1) {
            acc.push({ storageAreaId, stockTakes: [stockTake] });
          } else {
            acc[storageAreaIndex].stockTakes.push(stockTake);
          }
          return acc;
        },
        [] as { storageAreaId: number | null; stockTakes: StockTake[] }[],
      );
      setStockTakesByStorageArea(stockTakesByStorageArea);
    }
  }, [stockTakeEvent]);

  const updateStockTakeEventType = () => {
    if (stockTakeType === StockTakeEventType.FULL) {
      setStockTakeType(StockTakeEventType.PARTIAL);
    } else {
      setStockTakeType(StockTakeEventType.FULL);
      toast({
        title: "Warning",
        description:
          "Full stock takes will set all uncounted products and unit quantities to 0 - please make sure you count everything!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const {
    isOpen: isAddStorageAreaOpen,
    onOpen: onAddStorageAreaOpen,
    onClose: onAddStorageAreaClose,
  } = useDisclosure();

  const handleOpenAddStorageAreaModal = () => {
    // can add storage area as long as the totals (no storage area) is not counted and if there are remaining areas
    const isTotalsCounted = stockTakesByStorageArea.find(
      (stockTakeSet) => stockTakeSet.storageAreaId === null,
    );
    if (
      !isTotalsCounted &&
      storageAreas.length > stockTakesByStorageArea.length
    ) {
      onAddStorageAreaOpen();
    } else {
      if (isTotalsCounted) {
        toast({
          title: "Warning",
          description:
            "Update existing count's storage area before adding more - " +
            "cannot count totals and storage areas at the same time",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      } else if (storageAreas.length <= stockTakesByStorageArea.length) {
        toast({
          title: "Warning",
          description: "All storage areas have been counted",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isFullscreen ? "full" : "5xl"}
      scrollBehavior={"inside"}
    >
      {isAddStorageAreaOpen && (
        <SelectStorageAreaModal
          isOpen={isAddStorageAreaOpen}
          onClose={onAddStorageAreaClose}
          handleAddStorageAreaCount={(storageAreaId) => {
            addStorageAreaCount(storageAreaId);
          }}
        />
      )}
      <ModalOverlay />
      <ModalContent height="100vh" margin={{ base: 0, md: "auto" }}>
        <ModalCloseButton />
        <ModalHeader padding={{ base: 4, md: 6 }}>
          <Flex alignItems="center">
            <Heading>Stock Take</Heading>
            <Spacer />
            <Button
              onClick={() => setIsFullscreen(!isFullscreen)}
              size="md"
              variant="outline"
              mr={8}
            >
              {isFullscreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody
          flex="1"
          overflow="auto"
          display="flex"
          flexDirection="column"
          px={{ base: 4, md: 6 }}
        >
          <HStack>
            <FormControl>
              <FormLabel>Stock Take Datetime</FormLabel>
              <Input
                type="datetime-local"
                value={stockTakeDate}
                onChange={(e) => setStockTakeDate(e.target.value)}
              />
            </FormControl>
            <FormControl ml={4}>
              <FormLabel>Stock Take Type</FormLabel>
              Partial
              <Switch
                colorScheme="teal"
                size="lg"
                defaultChecked={stockTakeType === StockTakeEventType.FULL}
                mx={2}
                onChange={updateStockTakeEventType}
              />
              Full
            </FormControl>
          </HStack>
          <DataTabs
            showButton={true}
            isOpen={isAddStorageAreaOpen}
            onOpen={handleOpenAddStorageAreaModal}
            onClose={onAddStorageAreaClose}
            data={stockTakesByStorageArea.map(
              (stockTakeSet, storageAreaIndex) => ({
                label: (
                  <StorageAreaTabSelect
                    storageAreaId={stockTakeSet.storageAreaId}
                    setStorageAreaId={(storageAreaId) =>
                      updateStorageAreaId(storageAreaIndex, storageAreaId)
                    }
                  />
                ),
                buttonLabel: "Add Storage Area Count",
                headers: [
                  "Product",
                  "Unit",
                  "Lot Code*",
                  "Expiry Date*",
                  "Quantity",
                  "Actions",
                ],
                entries: (
                  <StorageAreaTabData
                    storageAreaId={stockTakeSet.storageAreaId}
                    stockTakes={stockTakeSet.stockTakes}
                    updateStockTakes={(stockTakes) =>
                      updateStockTakes(storageAreaIndex, stockTakes)
                    }
                  />
                ),
              }),
            )}
          />
        </ModalBody>
        <ModalFooter flexDirection="row" width="100%" px={4} py={6}>
          <Button
            onClick={() => handleSave(StockTakeStatus.IN_PROGRESS)}
            flex={{ base: 1, sm: "0 1 auto" }}
          >
            Save as Draft
          </Button>
          <Spacer />
          <Button
            colorScheme="teal"
            onClick={() => handleSave(StockTakeStatus.COMPLETED)}
            flex={{ base: 1, sm: "0 1 auto" }}
          >
            Save and Finalize
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddStockTakeModal;
