import {
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import * as React from "react";
import { Location } from "../../types";
import { SetStateAction, Dispatch, useEffect } from "react";
import { useGetSupplierLocationsQuery } from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { RiDeleteBack2Line } from "react-icons/ri";
import { getLocationAndOrgName } from "../../utils/invoiceUtils";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "../Autocomplete";

interface SupplierLocationSearchProps {
  setSupplierLocationId?: Dispatch<SetStateAction<number | null>>;
  setSupplierOrganisationId?: Dispatch<SetStateAction<number | null>>;
  setSupplierName?: Dispatch<SetStateAction<string>>;
  includeSearchIcon?: boolean;
  placeholder?: string;
  onLocationSelect?: (location: Location | null) => void;
}

const SupplierLocationSearch = ({
  setSupplierLocationId = () => {},
  setSupplierOrganisationId = () => {},
  setSupplierName = () => {},
  includeSearchIcon = false,
  placeholder = "Search for a supplier",
  onLocationSelect = () => {},
}: SupplierLocationSearchProps): React.ReactElement => {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: supplierLocations = [], isLoading } =
    useGetSupplierLocationsQuery(
      { organisationId, locationId },
      { skip: !organisationId || !locationId },
    );

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [supplierList, setSupplierList] = React.useState<Location[]>([]);

  const [isHoveringDelete, setIsHoveringDelete] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && supplierLocations && supplierLocations.length > 0) {
      setSupplierList(
        supplierLocations
          .slice()
          .sort((a, b) =>
            getLocationAndOrgName(a).localeCompare(getLocationAndOrgName(b)),
          ),
      );
    } else {
      setSupplierList([]);
    }
  }, [supplierLocations, isLoading]);

  const handleLocationSelect = (supplier: Location) => {
    setSearchTerm(getLocationAndOrgName(supplier));
    setSupplierLocationId(Number(supplier.id));
    setSupplierOrganisationId(Number(supplier.organisation_id));
    setSupplierName(getLocationAndOrgName(supplier));
    onLocationSelect(supplier);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setSupplierLocationId(null);
    setSupplierOrganisationId(null);
    setSupplierName("");
    onLocationSelect(null);
  };

  return (
    <AutoComplete openOnFocus>
      <HStack>
        <InputGroup>
          {includeSearchIcon && (
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              <SearchIcon />
            </InputLeftElement>
          )}
          <AutoCompleteInput
            onItemSelect={(value) => {
              const location = supplierList.find(
                (supplier) => getLocationAndOrgName(supplier) === value,
              );
              if (location) {
                handleLocationSelect(location);
              }
            }}
            placeholder={placeholder}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length === 0) {
                clearSearch();
              }
            }}
          />
          <InputRightElement>
            <RiDeleteBack2Line
              style={{ cursor: "pointer", opacity: isHoveringDelete ? 1 : 0.5 }}
              onClick={clearSearch}
              onMouseEnter={() => setIsHoveringDelete(true)}
              onMouseLeave={() => setIsHoveringDelete(false)}
            />
          </InputRightElement>
        </InputGroup>
        <AutoCompleteList>
          {supplierList.map((supplier: Location) => (
            <AutoCompleteItem
              id={`option-${supplier.id}`}
              key={`option-${supplier.id}`}
              value={getLocationAndOrgName(supplier)}
              textTransform="capitalize"
              onClick={() => handleLocationSelect(supplier)}
            >
              {getLocationAndOrgName(supplier)}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </HStack>
    </AutoComplete>
  );
};

export default SupplierLocationSearch;
