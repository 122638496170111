import {
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "../Autocomplete";
import * as React from "react";
import { StorageArea } from "../../types";
import { useGetStorageAreasQuery } from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { RiDeleteBack2Line } from "react-icons/ri";
import { useEffect } from "react";

interface StorageAreaSearchOrCreateProps {
  placeholder?: string;
  selectedStorageAreaId?: number | null;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setStorageAreaId: (id: number | null | undefined) => void;
  setStorageAreaName?: (name: string) => void;
  includeSearchIcon?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const StorageAreaSearchOrCreate = ({
  placeholder,
  selectedStorageAreaId,
  searchTerm,
  setSearchTerm,
  setStorageAreaId,
  setStorageAreaName = () => {},
  includeSearchIcon = false,
  onKeyDown,
}: StorageAreaSearchOrCreateProps): React.ReactElement => {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);
  const {
    data: storageAreas = [],
    error: storageAreasError,
    isLoading: storageAreasLoading,
  } = useGetStorageAreasQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [isHoveringDelete, setIsHoveringDelete] =
    React.useState<boolean>(false);

  const clearSearch = () => {
    setStorageAreaId(undefined);
    setStorageAreaName("");
    setSearchTerm("");
  };

  useEffect(() => {
    if (selectedStorageAreaId) {
      const storageArea = storageAreas.find(
        (storageArea) => storageArea.id === selectedStorageAreaId,
      );
      setSearchTerm(storageArea?.name || "");
      setStorageAreaName(storageArea?.name || "");
    }
  }, [selectedStorageAreaId, storageAreas]);

  return (
    <AutoComplete openOnFocus isLoading={storageAreasLoading}>
      <HStack>
        <InputGroup>
          {includeSearchIcon && (
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              <SearchIcon />
            </InputLeftElement>
          )}
          <AutoCompleteInput
            onKeyDown={onKeyDown}
            onItemSelect={(value) => {
              setSearchTerm(value);
              if (!storageAreas) return;
              const storageArea = storageAreas.find(
                (storageArea) => storageArea.name === value,
              );
              setStorageAreaId(storageArea?.id);
              setStorageAreaName(value);
            }}
            placeholder={
              placeholder
                ? placeholder
                : includeSearchIcon
                ? "Search for storage area"
                : ""
            }
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length === 0) {
                clearSearch();
              }
            }}
            // TODO: improve UI by enabling selection with key presses
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     // @ts-ignore
            //     console.log("filtering", e.target.value);
            //     // @ts-ignore
            //     setProductFilter(Object.keys(e.target.value));
            //   }
            // }}
          />
          <InputRightElement>
            <RiDeleteBack2Line
              style={{ cursor: "pointer", opacity: isHoveringDelete ? 1 : 0.5 }}
              onClick={clearSearch}
              onMouseEnter={() => setIsHoveringDelete(true)}
              onMouseLeave={() => setIsHoveringDelete(false)}
            />
          </InputRightElement>
        </InputGroup>
        <AutoCompleteList>
          <AutoCompleteItem
            id="totals"
            key="totals"
            value="Totals (No Storage Area)"
            textTransform="capitalize"
            onClick={() => {
              setSearchTerm("Totals (No Storage Area)");
              setStorageAreaId(0);
              setStorageAreaName("Totals (No Storage Area)");
            }}
          >
            Totals (No Storage Area)
          </AutoCompleteItem>
          {storageAreas.map((storageArea: StorageArea) => (
            <AutoCompleteItem
              id={`option-${storageArea.id}`}
              key={`option-${storageArea.id}`}
              value={storageArea.name}
              textTransform="capitalize"
              onClick={() => {
                setSearchTerm(storageArea.name);
                setStorageAreaId(storageArea.id);
                setStorageAreaName(storageArea.name);
              }}
            >
              {storageArea.name}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </HStack>
    </AutoComplete>
  );
};

export default StorageAreaSearchOrCreate;
