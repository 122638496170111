export const getBarcodeType = (barcode?: string): string | null => {
  if (!barcode || barcode.length === 0) return null;
  if (barcode.length === 8) {
    return "EAN-8";
  } else if (barcode.length === 12) {
    return "UPC-A";
  } else if (barcode.length === 13) {
    return "EAN-13";
  } else if (barcode.length === 14) {
    return "ITF-14";
  } else {
    // // TODO: show error in toast
    // throw new Error("Unsupported barcode type");
    return "EAN-8";
  }
};
