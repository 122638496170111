import React, { useState } from "react";
import { CiBarcode } from "react-icons/ci";
import {
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { BarcodeScanInput } from "../BarcodeScanInput/BarcodeScanInput.tsx";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";

const BarcodeProductUnitSearch = (): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const [barcode, setBarcode] = useState<string>("");

  const onChange = (barcode: string) => {
    setBarcode(barcode);
  };

  const [isTablet] = useMediaQuery("(max-width: 1024px)");

  return (
    <>
      <IconButton
        aria-label="search by barcode"
        icon={<CiBarcode />}
        onClick={onOpen}
        size={isTablet ? "xs" : "sm"}
      />
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size={isFullscreen ? "full" : "xl"}
        >
          <ModalOverlay />
          <ModalContent height={isFullscreen ? "100vh" : "auto"}>
            <ModalCloseButton />
            <ModalHeader>
              <Flex alignItems="center">
                <Heading size="md">Search by Barcode</Heading>
                <Spacer />
                <Button
                  onClick={() => setIsFullscreen(!isFullscreen)}
                  size="md"
                  variant="outline"
                  mr={8}
                >
                  {isFullscreen ? (
                    <AiOutlineFullscreenExit />
                  ) : (
                    <AiOutlineFullscreen />
                  )}
                </Button>
              </Flex>
            </ModalHeader>
            <ModalBody
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <BarcodeScanInput onChange={onChange} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
              <Spacer />
              <Button colorScheme="teal">Search</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default BarcodeProductUnitSearch;
