import { Harvest, HarvestItem } from "../../types";
import { Td, Tr, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import * as React from "react";
import HarvestDetailModal from "./HarvestDetailModal";
import { getProductUnitLabel } from "../../utils/productUnitUtils";

interface HarvestViewEntryProps {
  harvest?: Harvest;
  harvestItem: HarvestItem;
  itemIndex: number;
}
export default function HarvestViewEntry({
  harvest,
  harvestItem,
  itemIndex,
}: HarvestViewEntryProps): React.ReactElement {
  const [bgColor, setBgColor] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr
      key={itemIndex}
      style={{ cursor: "pointer" }}
      onClick={() => {
        onOpen();
        setBgColor("");
      }}
      bg={bgColor}
      onMouseEnter={() => setBgColor("lightgray")}
      onMouseLeave={() => setBgColor("")}
    >
      <HarvestDetailModal isOpen={isOpen} onClose={onClose} harvest={harvest} />
      <Td fontSize="sm">{harvestItem.harvest_date}</Td>
      <Td fontSize="sm">
        {harvestItem.product_unit?.product_name || "Unknown"}
      </Td>
      <Td fontSize="sm">
        {harvestItem.product_unit
          ? getProductUnitLabel(harvestItem.product_unit)
          : "unit"}
      </Td>
      <Td fontSize="sm">{harvestItem.quantity}</Td>
    </Tr>
  );
}
