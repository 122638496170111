import {
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "../Autocomplete";
import * as React from "react";
import { Product } from "../../types";
import { useEffect } from "react";
import { useGetInventoryProductsQuery } from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { RiDeleteBack2Line } from "react-icons/ri";
import { skipToken } from "@reduxjs/toolkit/query";

interface InventoryProductSearchProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setProductFilterId: (id: number | null | undefined) => void;
  setProductName?: (name: string) => void;
  includeSearchIcon?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const InventoryProductSearch = ({
  searchTerm,
  setSearchTerm,
  setProductFilterId,
  setProductName = () => {},
  includeSearchIcon = false,
  onKeyDown,
}: InventoryProductSearchProps): React.ReactElement => {
  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  // const locationId = useAppSelector(getCurrentLocationId);  // TODO: implement filtering of inventory items by location

  const { data: inventoryProducts, isLoading: productsLoading } =
    useGetInventoryProductsQuery(organisationId ?? skipToken);

  const [productList, setProductList] = React.useState<Product[]>([]);

  const [isHoveringDelete, setIsHoveringDelete] =
    React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    setIsLoading(productsLoading);
  }, [productsLoading]);

  useEffect(() => {
    if (inventoryProducts && inventoryProducts.length > 0) {
      setProductList(
        inventoryProducts.slice().sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [inventoryProducts]);

  const clearSearch = () => {
    setProductFilterId(undefined);
    setProductName("");
    setSearchTerm("");
  };

  return (
    <AutoComplete openOnFocus isLoading={isLoading}>
      <HStack>
        <InputGroup>
          {includeSearchIcon && (
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              <SearchIcon />
            </InputLeftElement>
          )}
          <AutoCompleteInput
            onKeyDown={onKeyDown}
            onItemSelect={(value) => {
              setSearchTerm(value);
              const product = productList.find(
                (product) => product.name === value,
              );
              setProductFilterId(product?.id);
              setProductName(value);
            }}
            placeholder={includeSearchIcon ? "Search for items" : ""}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length === 0) {
                clearSearch();
              }
            }}
            // TODO: improve UI by enabling selection with key presses
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     // @ts-ignore
            //     console.log("filtering", e.target.value);
            //     // @ts-ignore
            //     setProductFilter(Object.keys(e.target.value));
            //   }
            // }}
          />
          <InputRightElement>
            <RiDeleteBack2Line
              style={{ cursor: "pointer", opacity: isHoveringDelete ? 1 : 0.5 }}
              onClick={clearSearch}
              onMouseEnter={() => setIsHoveringDelete(true)}
              onMouseLeave={() => setIsHoveringDelete(false)}
            />
          </InputRightElement>
        </InputGroup>
        <AutoCompleteList>
          {productList.map((product: Product) => (
            <AutoCompleteItem
              id={`option-${product.id}`}
              key={`option-${product.id}`}
              value={product.name}
              textTransform="capitalize"
              onClick={() => {
                setSearchTerm(product.name);
                setProductFilterId(product.id);
                setProductName(product.name);
              }}
            >
              {product.name}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </HStack>
    </AutoComplete>
  );
};

export default InventoryProductSearch;
