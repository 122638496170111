import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Text,
  Tooltip,
  useToast,
  IconButton,
  useMediaQuery,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import {
  useDeleteInventoryItemsFromUnitIdListMutation,
  useGetInventoryItemsQuery,
  usePostBarcodeListMutation,
  usePostInventoryItemListMutation,
  usePostProductMutation,
  usePostProductUnitListMutation,
  usePutBarcodeListMutation,
  useSearchProductsQuery,
} from "../../redux/apiSlice";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import { Product, ProductUnit, ProductUnitBase } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils.ts";
import { capitalizeWords } from "../../utils/stringUtils.ts";
import { ProductUnitMgr } from "../../components/ProductUnitManager/types.ts";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import ProductUnitManager from "../../components/ProductUnitManager/ProductUnitManager.tsx";

interface AddProductModalProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onProductCreated?: (product: Product) => void;
  initialProductName?: string;
}

const AddProductModal = ({
  title,
  isOpen,
  onClose,
  onProductCreated,
  initialProductName = "",
}: AddProductModalProps): React.ReactElement => {
  const toast = useToast();
  const [productName, setProductName] = useState<string>("");
  const [variety, setVariety] = useState<string>("");
  const [fullProductName, setFullProductName] = useState<string>("");
  const [unitLabel, setUnitLabel] = useState<string>("");
  const [unitQuantity, setUnitQuantity] = useState<number>(1);
  const [unitMeasure, setUnitMeasure] = useState<string>("lb");
  const [unitSystem, setUnitSystem] = useState<string>("IMPERIAL_US");

  const [postProduct, { isLoading }] = usePostProductMutation();
  const [postProductUnitList, { isLoading: isPostingProductUnits }] =
    usePostProductUnitListMutation();

  const [postInventoryItemList, { isLoading: isPostingInventoryItems }] =
    usePostInventoryItemListMutation();
  const [
    deleteInventoryItemListFromUnitIdList,
    { isLoading: isDeletingInventoryItems },
  ] = useDeleteInventoryItemsFromUnitIdListMutation();

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productUnitMgr, setProductUnitMgr] = useState<ProductUnitMgr | null>(
    null,
  );
  const [isCreatingProduct, setIsCreatingProduct] = useState<boolean>(false);

  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const [units, setUnits] = useState<(ProductUnit | ProductUnitBase)[]>([]);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [matchInResults, setMatchInResults] = useState<boolean>(false);

  const [inventoryUnitIds, setInventoryUnitIds] = useState<Set<number>>(
    new Set(),
  );

  const { data: inventoryItems } = useGetInventoryItemsQuery(
    {
      organisationId: organisationId,
    },
    {
      skip: !organisationId,
    },
  );

  const { data: searchResults, isFetching: isSearching } =
    useSearchProductsQuery(searchTerm, {
      skip: searchTerm.length < 1,
    });

  const [postBarcodeList, { isLoading: isPostingBarcode }] =
    usePostBarcodeListMutation();
  const [putBarcodeList, { isLoading: isPuttingBarcode }] =
    usePutBarcodeListMutation();

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [editingUnitIndex, setEditingUnitIndex] = useState<number | null>(null);

  useEffect(() => {
    if (inventoryItems && inventoryItems.length > 0) {
      setInventoryUnitIds(
        new Set(inventoryItems.map((item) => item.product_unit_id)),
      );
    }
  }, [inventoryItems]);

  useEffect(() => {
    if (isOpen && selectedProduct) {
      setProductUnitMgr(
        new ProductUnitMgr(
          selectedProduct.product_units,
          inventoryUnitIds || new Set(),
        ),
      );
    }
  }, [selectedProduct, inventoryUnitIds, isOpen]);

  useEffect(() => {
    if (productName.length > 0 && searchResults && searchResults.length > 0) {
      const product = searchResults?.find(
        (p) => p.name.toLowerCase() === fullProductName.toLowerCase(),
      );
      if (product) {
        setMatchInResults(true);
      } else {
        setMatchInResults(false);
      }
    }
  }, [searchResults, fullProductName]);

  useEffect(() => {
    if (isCreatingProduct) {
      setIsFormValid(productName.length > 0 && units.length > 0);
    } else if (selectedProduct) {
      // Form validity for existing products is now handled in the ProductUnitManager onChange callback
    } else {
      setIsFormValid(false);
    }
  }, [isCreatingProduct, productName, units, selectedProduct]);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setShowSearchResults(true);
  };

  const handleMeasureChange = (value: string) => {
    setUnitMeasure(value);
    if (["oz", "lb"].includes(value)) {
      setUnitSystem("IMPERIAL_US");
    } else if (value === "unit") {
      setUnitSystem("QUANTITY");
    } else {
      setUnitSystem("METRIC");
    }
  };

  const updateFullProductName = (name: string, variety: string) => {
    const fullName = variety ? `${name} - ${variety}` : name;
    setFullProductName(fullName);
    handleSearch(fullName);
  };

  const handleSelectExistingProduct = (product: Product) => {
    setShowSearchResults(false);
    setSelectedProduct(product);
    const [name, variety] = product.name.split(" - ");
    setProductName(name);
    setVariety(variety || "");

    updateFullProductName(name, variety);

    // Set the available units for the selected product
    if (product.product_units && product.product_units.length > 0) {
      // Set the first unit as default
      const defaultUnit = product.product_units[0];
      setUnitLabel(defaultUnit.unit_label);
      setUnitQuantity(defaultUnit.unit_quantity);
      setUnitMeasure(defaultUnit.unit_measure);
      setUnitSystem(defaultUnit.unit_system);
    }
  };

  const handleAddProduct = () => {
    if (!isFormValid) return;

    if (!isCreatingProduct) {
      handleProductUnitUpdate();
    } else {
      createNewProduct();
    }
  };

  const handleProductUnitUpdate = () => {
    if (
      isLoading ||
      isPostingProductUnits ||
      isPostingInventoryItems ||
      isDeletingInventoryItems
    ) {
      return;
    }
    if (!productUnitMgr || !selectedProduct) {
      Sentry.captureException(
        "Product unit manager or selected product not set.",
      );
      toast({
        title: "Error occurred in initialising product unit manager.",
        description:
          "Please try again later & contact support if issue persists.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const newProductUnits = productUnitMgr.newProductUnits;
    const newInventoryUnitIds = productUnitMgr.getNewInventoryUnitIds();
    const removedInventoryUnitIds = productUnitMgr.getRemovedInventoryUnitIds();
    const newBarcodes = productUnitMgr.getNewBarcodes();
    const updatedBarcodes = productUnitMgr.getUpdatedBarcodes();
    if (
      !(
        newProductUnits.length ||
        newInventoryUnitIds.length ||
        removedInventoryUnitIds.length ||
        newBarcodes.length ||
        updatedBarcodes.length
      )
    ) {
      toast({
        title: "No units to add or modify.",
        description: "Please add or modify units before saving.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const errors = {
      newProductUnits: null,
      newInventoryUnitIds: null,
      removedInventoryUnitIds: null,
      newBarcodes: null,
      updatedBarcodes: null,
    };

    if (newBarcodes.length) {
      postBarcodeList({
        barcodes: newBarcodes,
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["newBarcodes"] = error;
        });
    }

    if (updatedBarcodes.length) {
      putBarcodeList({
        barcodes: updatedBarcodes,
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["updatedBarcodes"] = error;
        });
    }

    if (newInventoryUnitIds.length) {
      if (!organisationId) {
        toast({
          title: "No organisation ID.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      postInventoryItemList({
        organisationId,
        inventoryItems: newInventoryUnitIds.map((productUnitId) => ({
          product_id: selectedProduct.id,
          product_unit_id: productUnitId,
          organisation_id: organisationId,
        })),
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["newInventoryUnitIds"] = error;
        });
    }

    if (removedInventoryUnitIds.length) {
      if (!organisationId) return;
      deleteInventoryItemListFromUnitIdList({
        organisationId,
        productUnitIds: removedInventoryUnitIds,
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["removedInventoryUnitIds"] = error;
        });
    }

    postProductUnitList({
      productId: selectedProduct.id,
      productUnits: newProductUnits,
    })
      .unwrap()
      .then(() => {})
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        errors["newProductUnits"] = error;
      });
    if (
      errors["newProductUnits"] ||
      errors["newInventoryUnitIds"] ||
      errors["removedInventoryUnitIds"] ||
      errors["newBarcodes"] ||
      errors["updatedBarcodes"]
    ) {
      toast({
        title: "Units failed to create or modify.",
        description:
          "Please check your inputs and try again. If the issue persists please contact support.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Units created/updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  const createNewProduct = () => {
    if (!productName || productName.length === 0) {
      toast({
        title: "Product name is required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!units || units.length === 0) {
      toast({
        title: "Unit details are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    postProduct({
      name: fullProductName,
      category: "PRODUCE",
      product_units:
        units.length > 0
          ? units
          : [
              {
                unit_label: unitLabel,
                unit_quantity: unitQuantity,
                unit_measure: unitMeasure,
                unit_system: unitSystem,
              },
            ],
    } as Product)
      .unwrap()
      .then((newProduct) => {
        toast({
          title: "Product created with specified unit(s).",
          description: "You can view this in the Products list.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        clearForm();
        onProductCreated?.(newProduct);
        onClose();
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Product failed to create.",
          description:
            "Please try again later & contact support if issue persists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const clearForm = () => {
    setProductName("");
    setVariety("");
    setFullProductName("");
    setSelectedProduct(null);
    setIsCreatingProduct(false);
    setUnitLabel("");
    setUnitQuantity(1);
    setUnitMeasure("lb");
    setUnitSystem("IMPERIAL_US");
    setUnits([]);
  };

  useEffect(() => {
    clearForm();
  }, [onClose]);

  const addUnit = () => {
    if (unitQuantity && unitMeasure) {
      setUnits([
        ...units,
        {
          unit_label: unitLabel.trim(),
          unit_quantity: Number(unitQuantity),
          unit_measure: unitMeasure,
          unit_system: unitSystem,
        },
      ]);
      clearUnitForm();
    } else {
      toast({
        title: "Unit quantity and measure are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const editUnit = (index: number) => {
    const unitToEdit = units[index];
    setUnitLabel(unitToEdit.unit_label);
    setUnitQuantity(unitToEdit.unit_quantity);
    setUnitMeasure(unitToEdit.unit_measure);
    setUnitSystem(unitToEdit.unit_system);
    setEditingUnitIndex(index);
  };

  const updateUnit = () => {
    if (editingUnitIndex !== null) {
      const updatedUnits = [...units];
      updatedUnits[editingUnitIndex] = {
        unit_label: unitLabel.trim(),
        unit_quantity: Number(unitQuantity),
        unit_measure: unitMeasure,
        unit_system: unitSystem,
      };
      setUnits(updatedUnits);
      setEditingUnitIndex(null);
      clearUnitForm();
    }
  };

  const cancelUnitEdit = () => {
    setEditingUnitIndex(null);
    clearUnitForm();
  };

  const removeUnit = (index: number) => {
    const updatedUnits = units.filter((_, i) => i !== index);
    setUnits(updatedUnits);
  };

  const clearUnitForm = () => {
    setUnitLabel("");
    setUnitQuantity(1);
    setUnitMeasure("lb");
    setUnitSystem("IMPERIAL_US");
  };

  // Initialize the form when the modal opens with initialProductName
  useEffect(() => {
    if (isOpen && initialProductName) {
      // Split the initial name into product name and variety if it contains a hyphen
      const [name, varietyPart] = initialProductName.split(" - ");
      setProductName(capitalizeWords(name));
      if (varietyPart) {
        setVariety(capitalizeWords(varietyPart));
      }
      // Trigger the search with the full name
      updateFullProductName(name, varietyPart || "");
    }
  }, [isOpen, initialProductName]);

  const [isTablet] = useMediaQuery("(max-width: 1024px)");

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        clearForm();
        onClose();
      }}
      scrollBehavior="inside"
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Add product{title && `: ${title}`}</ModalHeader>
        <ModalBody padding={isTablet ? 6 : "auto"}>
          <Flex flexDirection={["column", "row"]} gap={4}>
            <FormControl>
              <FormLabel>Product</FormLabel>
              <Input
                placeholder={"Product name..."}
                value={productName}
                onChange={(e) => {
                  const nameUpdate = capitalizeWords(e.target.value);
                  setProductName(nameUpdate);
                  updateFullProductName(nameUpdate, variety);
                }}
                isDisabled={Boolean(selectedProduct || isCreatingProduct)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Variety (optional)</FormLabel>
              <Input
                placeholder={"Variety name..."}
                value={variety}
                onChange={(e) => {
                  const varietyUpdate = capitalizeWords(e.target.value);
                  setVariety(varietyUpdate);
                  updateFullProductName(productName, varietyUpdate);
                }}
                isDisabled={Boolean(selectedProduct || isCreatingProduct)}
              />
            </FormControl>
          </Flex>
          {isSearching && <Spinner />}
          {fullProductName.length > 0 &&
            !selectedProduct &&
            !isCreatingProduct &&
            searchResults &&
            searchResults.length > 0 &&
            showSearchResults && (
              <Box mt={4}>
                <Text fontWeight="bold">Select from existing products:</Text>
                <List spacing={3}>
                  {searchResults.map((product) => (
                    <ListItem key={product.id}>
                      <Button
                        onClick={() => handleSelectExistingProduct(product)}
                        variant="outline"
                      >
                        {product.name}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          {productName && !selectedProduct && !isCreatingProduct && (
            <Box mt={4}>
              <Text fontWeight="bold">Create new product:</Text>
              <Tooltip
                label={
                  matchInResults
                    ? "Exact match in suggested products - please select"
                    : ""
                }
                aria-label={
                  matchInResults
                    ? "Exact match in suggested products - please select"
                    : ""
                }
              >
                <Button
                  onClick={() => {
                    setIsCreatingProduct(true);
                  }}
                  variant="outline"
                  isDisabled={matchInResults}
                >
                  {`Create new product: '${fullProductName}'?`}
                </Button>
              </Tooltip>
            </Box>
          )}
          {fullProductName.length > 0 &&
            (selectedProduct || isCreatingProduct) && (
              <>
                <Box mt={4}>
                  <Text fontWeight="bold">Add Units:</Text>
                  {selectedProduct && productUnitMgr && (
                    <ProductUnitManager
                      product={selectedProduct}
                      productUnitMgr={productUnitMgr}
                      onChange={(updatedProductUnitMgr) => {
                        setProductUnitMgr(updatedProductUnitMgr);
                        if (
                          updatedProductUnitMgr.newProductUnits.length > 0 ||
                          updatedProductUnitMgr.getNewInventoryUnitIds()
                            .length > 0 ||
                          updatedProductUnitMgr.getRemovedInventoryUnitIds()
                            .length > 0 ||
                          updatedProductUnitMgr.getNewBarcodes().length > 0 ||
                          updatedProductUnitMgr.getUpdatedBarcodes().length > 0
                        ) {
                          setIsFormValid(true);
                        } else {
                          setIsFormValid(false);
                        }
                      }}
                    />
                  )}
                  {!(selectedProduct && productUnitMgr) && (
                    <>
                      <HStack spacing={4} mt={2}>
                        <FormControl>
                          <FormLabel>Unit Label (optional)</FormLabel>
                          <Input
                            placeholder="Unit label..."
                            value={unitLabel}
                            onChange={(e) => setUnitLabel(e.target.value)}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Unit Quantity</FormLabel>
                          <NumberInput
                            value={unitQuantity}
                            min={0.00001}
                            step={0.1}
                            precision={3}
                            onChange={(valueAsString) =>
                              setUnitQuantity(Number(valueAsString))
                            }
                          >
                            <NumberInputField placeholder="Quantity" />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Unit Measure</FormLabel>
                          <Select
                            value={unitMeasure}
                            onChange={(e) =>
                              handleMeasureChange(e.target.value)
                            }
                          >
                            <option value="lb">lb</option>
                            <option value="oz">oz</option>
                            <option value="kg">kg</option>
                            <option value="g">g</option>
                            <option value="unit">each</option>
                          </Select>
                        </FormControl>
                      </HStack>
                      <HStack mt={2}>
                        <Button
                          onClick={
                            editingUnitIndex !== null ? updateUnit : addUnit
                          }
                          isDisabled={!unitQuantity || !unitMeasure}
                        >
                          {editingUnitIndex !== null
                            ? "Update Unit"
                            : "Add Unit"}
                        </Button>
                        {editingUnitIndex !== null && (
                          <Button onClick={cancelUnitEdit}>Cancel</Button>
                        )}
                      </HStack>
                    </>
                  )}
                </Box>
              </>
            )}
          {units && units.length > 0 && (
            <Box mt={4}>
              <Text fontWeight="bold">Added Units:</Text>
              <List spacing={2}>
                {units.map((unit, index) => (
                  <ListItem key={index}>
                    <Flex alignItems="center">
                      <Text>{`${getProductUnitLabel(unit)}`}</Text>
                      <IconButton
                        aria-label="Edit unit"
                        icon={<EditIcon />}
                        size="sm"
                        ml={2}
                        onClick={() => editUnit(index)}
                      />
                      <IconButton
                        aria-label="Remove unit"
                        icon={<DeleteIcon />}
                        size="sm"
                        ml={2}
                        onClick={() => removeUnit(index)}
                      />
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={handleAddProduct}
            isLoading={isLoading}
            isDisabled={!isFormValid}
          >
            Save
          </Button>
          <Button onClick={clearForm}>Clear</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProductModal;
